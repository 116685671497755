import { DisplayMode, HeadOptionKey, RegionTemplate } from './types';

export const head: { head: RegionTemplate<HeadOptionKey> } = {
  head: {
    tabs: [
      {
        key: 'popular',
        label: 'Most common',
      },
      {
        key: 'head',
        label: 'Head',
      },
      {
        key: 'neck',
        label: 'Neck',
      },
      {
        key: 'throat',
        label: 'Throat',
        display_in_modes: [DisplayMode.WORKERS_COMP],
      },
      {
        key: 'nose',
        label: 'Nose',
        display_in_modes: [DisplayMode.WORKERS_COMP],
      },
      {
        key: 'ears',
        label: 'Ears',
      },
      {
        key: 'eyes',
        label: 'Eyes',
      },
      {
        key: 'skin',
        label: 'Skin',
      },
      {
        key: 'bleeding',
        label: 'Bleeding',
      },
      {
        key: 'jaw',
        label: 'Jaw / Mouth',
      },
      {
        key: 'face',
        label: 'Face',
        display_in_modes: [DisplayMode.WORKERS_COMP],
      },
    ],
    options: [
      {
        key: 'headache',
        label: 'Headache / migraine',
        popular_in_modes: [
          DisplayMode.AUTO,
          DisplayMode.LIABILITY,
          DisplayMode.WORKERS_COMP,
          DisplayMode.PROPERTY,
        ],
        tabs: ['head'],
      },
      {
        key: 'concussion',
        label: 'Concussion',
        tabs: ['head'],
        popular_in_modes: [
          DisplayMode.AUTO,
          DisplayMode.LIABILITY,
          DisplayMode.WORKERS_COMP,
          DisplayMode.PROPERTY,
        ],
        followups: [
          {
            key: 'loss_of_consciousness',
            label: 'Did this result in a loss of consciousness?',
          },
        ],
      },
      {
        key: 'skull',
        label: 'Skull fracture',
        tabs: ['head'],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'vision_loss',
        label: 'Vision loss',
        popular_in_modes: [DisplayMode.WORKERS_COMP],
        tabs: ['eyes'],
        followups: [
          {
            key: 'total_vision_loss',
            label: 'Was 100% of vision lost?',
          },
          {
            key: 'permanent_vision_loss',
            label: 'Was the vision loss permanent?',
          },
        ],
      },
      {
        key: 'eye_pain',
        label: 'Eye pain',
        tabs: ['eyes'],
      },
      {
        key: 'enucleation',
        label: 'Enucleation (eye removal or loss)',
        tabs: ['eyes'],
      },
      {
        key: 'eye_strain',
        label: 'Eye strain',
        tabs: ['eyes'],
      },
      {
        key: 'infection_eyes',
        label: 'Infection',
        tabs: ['eyes'],
        display_in_modes: [DisplayMode.WORKERS_COMP],
      },
      {
        key: 'infection_ears',
        label: 'Infection',
        tabs: ['ears'],
        display_in_modes: [DisplayMode.WORKERS_COMP],
      },
      {
        key: 'infection_jaw',
        label: 'Infection',
        tabs: ['jaw'],
        display_in_modes: [DisplayMode.WORKERS_COMP],
      },
      {
        key: 'foreignObjectInEye',
        label: 'Foreign object in eye',
        tabs: ['eyes'],
        display_in_modes: [DisplayMode.WORKERS_COMP],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'hearing_loss',
        label: 'Hearing loss',
        popular_in_modes: [DisplayMode.WORKERS_COMP],
        tabs: ['ears'],
        followups: [
          {
            key: 'total_hearing_loss',
            label: 'Was 100% of hearing lost?',
          },
          {
            key: 'permanent_hearing_loss',
            label: 'Was the hearing loss permanent?',
          },
        ],
      },
      {
        key: 'bruise_head',
        label: 'Bruise',
        tabs: ['head'],
        followups: [
          {
            key: 'hematoma',
            label: 'Was the bruise a hematoma?',
          },
        ],
      },
      {
        key: 'brain_bleed',
        label: 'Brain bleed',
        tabs: ['head'],
      },
      {
        key: 'whiplash',
        label: 'Whiplash',
        hidden_in_modes: [
          DisplayMode.AUTO,
          DisplayMode.LIABILITY,
          DisplayMode.PROPERTY,
        ],
        tabs: ['neck'],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'vertebrae_fracture',
        label: 'Vertebrae fracture',
        popular_in_modes: [DisplayMode.PROPERTY],
        tabs: ['neck'],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },

      {
        key: 'other_bone_head',
        label: 'Other broken bone',
        tabs: ['neck'],
      },

      {
        key: 'neck_pain',
        label: 'Neck pain',
        tabs: ['neck'],
        popular_in_modes: [DisplayMode.AUTO, DisplayMode.LIABILITY],
      },
      {
        key: 'burn_scald',
        label: 'Burn / scald',
        tabs: ['neck'],
        display_in_modes: [DisplayMode.WORKERS_COMP],
        followups: [
          {
            key: 'majorBurn',
            label: 'Major burn?',
          },
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'cut_scrape',
        label: 'Cut / scrape',
        tabs: ['skin'],
        popular_in_modes: [
          DisplayMode.AUTO,
          DisplayMode.LIABILITY,
          DisplayMode.PROPERTY,
        ],
        followups: [
          {
            key: 'stitches',
            label: 'Were stitches required?',
          },
        ],
      },
      {
        key: 'puncture',
        label: 'Puncture',
        tabs: ['neck'],
        followups: [
          {
            key: 'stitches',
            label: 'Were stitches required?',
          },
        ],
      },
      {
        key: 'airbag_burns',
        label: 'Airbag burns',
        hidden_in_modes: [DisplayMode.WORKERS_COMP, DisplayMode.PROPERTY],
        tabs: ['skin'],
      },

      {
        key: 'light_bleeding',
        label: 'Light bleeding',
        tabs: ['bleeding'],
      },
      {
        key: 'uncontrolled_bleeding',
        label: 'Uncontrolled bleeding',
        tabs: ['bleeding'],
        hide_if_low_severity: [
          DisplayMode.AUTO,
          DisplayMode.LIABILITY,
          DisplayMode.PROPERTY,
        ],
        followups: [
          {
            key: 'transfusion',
            label: 'Was a blood transfusion performed?',
          },
        ],
      },
      {
        key: 'dislocated_jaw',
        label: 'Dislocated jaw',
        tabs: ['jaw'],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'broken_jaw',
        label: 'Broken jaw',
        tabs: ['jaw'],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'broken_teeth',
        label: 'Broken teeth',
        tabs: ['jaw'],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'lacerated_tongue',
        label: 'Lacerated tongue',
        tabs: ['jaw'],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'fracturedFacialBones',
        label: 'Fractured facial bones',
        tabs: ['face'],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
        display_in_modes: [DisplayMode.WORKERS_COMP],
      },
      {
        key: 'bruise',
        label: 'Bruise',
        tabs: ['face'],
        display_in_modes: [DisplayMode.WORKERS_COMP],
      },
      {
        key: 'lipLaceration',
        label: 'Lip laceration',
        tabs: ['jaw'],
        followups: [
          {
            key: 'stitches',
            label: 'Were stitches required?',
          },
        ],
        display_in_modes: [DisplayMode.WORKERS_COMP],
      },
      {
        key: 'burn_scald',
        label: 'Burn / scald',
        tabs: ['skin'],
        display_in_modes: [DisplayMode.WORKERS_COMP],
        followups: [
          {
            key: 'majorBurn',
            label: 'Major burn?',
          },
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'dermatitis',
        label: 'Dermatitis',
        tabs: ['skin'],
        display_in_modes: [DisplayMode.WORKERS_COMP],
      },
      {
        key: 'infection_skin',
        label: 'Infection',
        tabs: ['skin'],
        display_in_modes: [DisplayMode.WORKERS_COMP],
      },
      {
        key: 'throat_infection',
        label: 'Throat infection',
        tabs: ['throat'],
        display_in_modes: [DisplayMode.WORKERS_COMP],
      },
      {
        key: 'lost_voice',
        label: 'Lost voice',
        tabs: ['throat'],
        display_in_modes: [DisplayMode.WORKERS_COMP],
      },
      {
        key: 'asphyxiation',
        label: 'Asphyxiation',
        tabs: ['throat'],
        display_in_modes: [DisplayMode.WORKERS_COMP],
      },
      {
        key: 'nose_bleed',
        label: 'Nose bleed',
        tabs: ['nose'],
        display_in_modes: [DisplayMode.WORKERS_COMP],
      },
      {
        key: 'broken_nose',
        label: 'Broken nose',
        tabs: ['nose'],
        display_in_modes: [DisplayMode.WORKERS_COMP],
      },
      {
        key: 'foreign_object_in_nose',
        label: 'Foreign object in nose',
        tabs: ['nose'],
        display_in_modes: [DisplayMode.WORKERS_COMP],
      },
      {
        key: 'burn_scald_on_nose',
        label: 'Burn / scald on nose',
        tabs: ['nose'],
        display_in_modes: [DisplayMode.WORKERS_COMP],
      },
      {
        key: 'nasal_contusion',
        label: 'Nasal contusion',
        tabs: ['nose'],
        display_in_modes: [DisplayMode.WORKERS_COMP],
      },
      {
        key: 'cut_scrape_on_nose',
        label: 'Cut / scrape on nose',
        tabs: ['nose'],
        display_in_modes: [DisplayMode.WORKERS_COMP],
      },
      {
        key: 'herniated_disk',
        label: 'Herniated disk',
        tabs: ['neck'],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
        display_in_modes: [DisplayMode.WORKERS_COMP],
      },
    ],
  },
};
