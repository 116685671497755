import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | undefined;
export type InputMaybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string | number; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /**
   * A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the
   * `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO
   * 8601 standard for representation of dates and times using the Gregorian calendar.
   */
  DateTime: { input: any; output: any; }
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  Json: { input: any; output: any; }
};

export type Action = {
  __typename?: 'Action';
  canceledAt?: Maybe<Scalars['DateTime']['output']>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  type?: Maybe<ActionTypeGql>;
};

export const ActionTypeGql = {
  ReminderAfterNoCaseConversationMessage: 'REMINDER_AFTER_NO_CASE_CONVERSATION_MESSAGE'
} as const;

export type ActionTypeGql = typeof ActionTypeGql[keyof typeof ActionTypeGql];
export type AddGuidedPhotoCaptureMediaItemInput = {
  categoryData?: InputMaybe<Scalars['String']['input']>;
  claimType: ClaimTypeGql;
  contactId: Scalars['String']['input'];
  details?: InputMaybe<Scalars['String']['input']>;
  feature: MediaFeatureGql;
  lat?: InputMaybe<Scalars['Float']['input']>;
  long?: InputMaybe<Scalars['Float']['input']>;
  source: Scalars['String']['input'];
  token: Scalars['ID']['input'];
  type: MediaTypeGql;
};

export type AddGuidedPhotoCaptureMediaItemResponse = {
  __typename?: 'AddGuidedPhotoCaptureMediaItemResponse';
  data?: Maybe<Media>;
  errors?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** An appointment scheduled for an adjuster */
export type AdjusterAppointment = {
  __typename?: 'AdjusterAppointment';
  /** The case contact the appointment is scheduled with */
  caseContact?: Maybe<CaseContact>;
  /** The ending time of the appointment */
  endAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  /** The reason for the appointment */
  reason?: Maybe<Scalars['String']['output']>;
  /** The starting time of the appointment */
  startAt: Scalars['DateTime']['output'];
};

/** Filter options for querying scheduled adjuster appointments */
export type AdjusterAppointmentsFilter = {
  /** The ID of the case contact to filter by */
  caseContactId?: InputMaybe<Scalars['ID']['input']>;
  /** The end time to filter by */
  endAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** The start time to filter by */
  startAt?: InputMaybe<Scalars['DateTime']['input']>;
};

/** A scheduled availability period that recurs on a weekly basis */
export type AdjusterAvailabilityScheduleTimeslot = {
  __typename?: 'AdjusterAvailabilityScheduleTimeslot';
  /** The day of the week on which the start and end times occur (ISO 8601: 1=Monday, 7=Sunday) */
  dayOfWeek: Scalars['Int']['output'];
  /** The minute offset from 12:00am on the given dayOfWeek at which the time slot starts */
  endMinute: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  /** The minute offset from 12:00am at which the time slot starts */
  startMinute: Scalars['Int']['output'];
  /** IANA time zone on which `dayOfWeek`, `startMinute`, and `endMinute` are based */
  timezone: Scalars['String']['output'];
};

/** A span in time during which an adjuster is available */
export type AdjusterAvailabilityScheduleTimeslotInput = {
  /** The day of the week on which the start and end times occur (ISO 8601: 1=Monday, 7=Sunday) */
  dayOfWeek: Scalars['Int']['input'];
  /** The minute offset from 12:00am, in the adjuster's time zone, at which the time slot starts */
  endMinute: Scalars['Int']['input'];
  /** The minute offset from 12:00am, in the adjuster's time zone, at which the time slot starts */
  startMinute: Scalars['Int']['input'];
};

/** A span in time during which an adjuster is currently available */
export type AdjusterAvailabilityTimeslot = {
  __typename?: 'AdjusterAvailabilityTimeslot';
  endAt: Scalars['DateTime']['output'];
  startAt: Scalars['DateTime']['output'];
};

export type AdminClaimUpdatePayload = {
  data?: InputMaybe<Scalars['Json']['input']>;
};

export type AdminClaimViewUser = {
  __typename?: 'AdminClaimViewUser';
  created_at?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  intelligent_investigation_supervisor?: Maybe<Scalars['Boolean']['output']>;
  last_ip?: Maybe<Scalars['String']['output']>;
  last_login?: Maybe<Scalars['String']['output']>;
  last_password_reset?: Maybe<Scalars['String']['output']>;
  logins_count?: Maybe<Scalars['Int']['output']>;
  multifactor?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  multifactor_last_modified?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  picture?: Maybe<Scalars['String']['output']>;
  role?: Maybe<AdminClaimViewUserRole>;
  updated_at?: Maybe<Scalars['String']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

export type AdminClaimViewUserDeleteResult = {
  __typename?: 'AdminClaimViewUserDeleteResult';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type AdminClaimViewUserEditPayload = {
  role: AdminClaimViewUserRole;
};

export type AdminClaimViewUserInvitePayload = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  role: AdminClaimViewUserRole;
};

export const AdminClaimViewUserRole = {
  Administrator: 'administrator',
  AdministratorReadOnly: 'administrator_read_only',
  DirectAccessOnly: 'direct_access_only',
  ManagerDirectAccessOnly: 'manager_direct_access_only',
  ReadOnly: 'read_only',
  User: 'user'
} as const;

export type AdminClaimViewUserRole = typeof AdminClaimViewUserRole[keyof typeof AdminClaimViewUserRole];
export type AdminDemoCatastropheCreatePayload = {
  name: Scalars['String']['input'];
  type: CatastropheType;
};

export const AdminIntakeClaimsFilter = {
  AssignedOthers: 'assigned_others',
  AssignedSelf: 'assigned_self',
  Draft: 'draft',
  Processed: 'processed',
  Submitted: 'submitted'
} as const;

export type AdminIntakeClaimsFilter = typeof AdminIntakeClaimsFilter[keyof typeof AdminIntakeClaimsFilter];
export const AdminInvestigationCaseFilter = {
  AssignedOthers: 'assigned_others',
  AssignedSelf: 'assigned_self',
  Completed: 'completed',
  InternalNotYetDispatched: 'internal_not_yet_dispatched',
  Processing: 'processing',
  ProcessingSelf: 'processing_self'
} as const;

export type AdminInvestigationCaseFilter = typeof AdminInvestigationCaseFilter[keyof typeof AdminInvestigationCaseFilter];
export const AdminInvestigationCaseFilterMode = {
  All: 'all',
  MyTeamsOnly: 'my_teams_only',
  User: 'user'
} as const;

export type AdminInvestigationCaseFilterMode = typeof AdminInvestigationCaseFilterMode[keyof typeof AdminInvestigationCaseFilterMode];
export type AdminTestBenchClaimCreationResult = {
  __typename?: 'AdminTestBenchClaimCreationResult';
  data?: Maybe<Scalars['Json']['output']>;
};

export type AdminTestBenchQueryParams = {
  insuredType?: InputMaybe<Scalars['String']['input']>;
  lossState?: InputMaybe<Scalars['String']['input']>;
  policyState?: InputMaybe<Scalars['String']['input']>;
  shopState?: InputMaybe<Scalars['String']['input']>;
  vehicleModelYear?: InputMaybe<Scalars['String']['input']>;
};

export type Agent = {
  __typename?: 'Agent';
  id: Scalars['ID']['output'];
  location?: Maybe<Location>;
  name: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
};

/**
 * Generic return type for analytics chart data. Some charts will use multiple
 * series, others will use only one. Some will have only one data point.
 */
export type AnalyticsChartData = {
  __typename?: 'AnalyticsChartData';
  /**
   * A hint for the maximmum value to display on the chart. Used to provide a
   * consistent chart scale across multiple charts, e.g., submission counts and
   * submission averages.
   */
  chartMaxValue?: Maybe<Scalars['Int']['output']>;
  chartType: AnalyticsChartType;
  /** List of data points for the chart. See the description of `AnalyticsChartDataPoint` for details. */
  data: Array<AnalyticsChartSliceData>;
  /** Labels for each series. Each label corresponds to the values at the same index in `data.values`. */
  seriesLabels: Array<Scalars['String']['output']>;
  /** Totals for each series. Each total corresponds to the values at the same index in `data.values`. */
  seriesTotals: Array<Scalars['Float']['output']>;
  /**
   * IANA Time Zone string for the time zone used to partition the chart data. It
   * is important to use this timezone when formatting things like axis labels, so
   * that they will align properly with the data.
   */
  timezone: Scalars['String']['output'];
  /** Label for the units represented by the data points (for use in a tooltip) */
  unitsLabel: Scalars['String']['output'];
};

/** Input object for `analyticsChartData` query */
export type AnalyticsChartDataInput = {
  catId?: InputMaybe<Scalars['String']['input']>;
  chartType: AnalyticsChartType;
  endDate: Scalars['DateTime']['input'];
  startDate: Scalars['DateTime']['input'];
};

/** Data for a single slice of a chart */
export type AnalyticsChartSliceData = {
  __typename?: 'AnalyticsChartSliceData';
  /** Optional date associated with the data point, might be needed for a tooltip. */
  date?: Maybe<Scalars['DateTime']['output']>;
  /** Label that can be used for the main axis */
  label: Scalars['String']['output'];
  /** Label that can be used for a secondary axis */
  subLabel?: Maybe<Scalars['String']['output']>;
  /** List of values corresponding to the series labels. For single-series charts, this will be a single-item list. */
  values: Array<Scalars['Float']['output']>;
};

export const AnalyticsChartType = {
  ActiveTime: 'ACTIVE_TIME',
  CatAffectedHouseholds: 'CAT_AFFECTED_HOUSEHOLDS',
  CatAfter: 'CAT_AFTER',
  CatAlertsProcessed: 'CAT_ALERTS_PROCESSED',
  CatBefore: 'CAT_BEFORE',
  CatDuring: 'CAT_DURING',
  CatEngagement: 'CAT_ENGAGEMENT',
  CatEngagementV2: 'CAT_ENGAGEMENT_V2',
  CatEventsProcessed: 'CAT_EVENTS_PROCESSED',
  CatPolicyHoldersMonitored: 'CAT_POLICY_HOLDERS_MONITORED',
  CatTotal: 'CAT_TOTAL',
  CatTotalProperties: 'CAT_TOTAL_PROPERTIES',
  CatTotalTodos: 'CAT_TOTAL_TODOS',
  CatTotalUsers: 'CAT_TOTAL_USERS',
  ClaimsWithHomeUnlivable: 'CLAIMS_WITH_HOME_UNLIVABLE',
  ClaimsWithUserNotes: 'CLAIMS_WITH_USER_NOTES',
  ClickthroughRate: 'CLICKTHROUGH_RATE',
  ConversionByLob: 'CONVERSION_BY_LOB',
  ConversionFunnel: 'CONVERSION_FUNNEL',
  ConversionRateByBrowser: 'CONVERSION_RATE_BY_BROWSER',
  ConversionRateByState: 'CONVERSION_RATE_BY_STATE',
  CustomerSatisfaction: 'CUSTOMER_SATISFACTION',
  CustomerSatisfactionAutoVsProperty: 'CUSTOMER_SATISFACTION_AUTO_VS_PROPERTY',
  CustomerSatisfactionByLob: 'CUSTOMER_SATISFACTION_BY_LOB',
  DataQuality: 'DATA_QUALITY',
  DigitalSettlementsCount: 'DIGITAL_SETTLEMENTS_COUNT',
  DigitalSettlementsTime: 'DIGITAL_SETTLEMENTS_TIME',
  ExaminerExtraction: 'EXAMINER_EXTRACTION',
  InterviewedParties: 'INTERVIEWED_PARTIES',
  InvestigationTimelines: 'INVESTIGATION_TIMELINES',
  IvrVsWeb: 'IVR_VS_WEB',
  LoggedInVsNotLoggedIn: 'LOGGED_IN_VS_NOT_LOGGED_IN',
  MedianFilingDuration: 'MEDIAN_FILING_DURATION',
  MediaAutoUploads: 'MEDIA_AUTO_UPLOADS',
  MediaPropertyUploads: 'MEDIA_PROPERTY_UPLOADS',
  MediaUploads: 'MEDIA_UPLOADS',
  MobileUsage: 'MOBILE_USAGE',
  PolicyLookups: 'POLICY_LOOKUPS',
  SubmissionsAutoVsProperty: 'SUBMISSIONS_AUTO_VS_PROPERTY',
  SubmissionsByChannel: 'SUBMISSIONS_BY_CHANNEL',
  SubmissionsByLob: 'SUBMISSIONS_BY_LOB',
  SubmissionsByType: 'SUBMISSIONS_BY_TYPE',
  SubmissionsRollingAvg: 'SUBMISSIONS_ROLLING_AVG',
  SubmissionsVsTime: 'SUBMISSIONS_VS_TIME',
  SurrogateBreakdown: 'SURROGATE_BREAKDOWN',
  TotalSubmittedClaims: 'TOTAL_SUBMITTED_CLAIMS',
  UsersByState: 'USERS_BY_STATE',
  UserAbandonment: 'USER_ABANDONMENT',
  UserSubmissionsByBrowserResolution: 'USER_SUBMISSIONS_BY_BROWSER_RESOLUTION',
  UserSubmissionsByDayOfWeek: 'USER_SUBMISSIONS_BY_DAY_OF_WEEK',
  UserSubmissionsByHourOfDay: 'USER_SUBMISSIONS_BY_HOUR_OF_DAY',
  UserTraffic: 'USER_TRAFFIC'
} as const;

export type AnalyticsChartType = typeof AnalyticsChartType[keyof typeof AnalyticsChartType];
export type AssignEmmaTaskForContactInput = {
  caseContactId: Scalars['String']['input'];
  /** JSON representation of the type-specific parameters for the task */
  parameters?: InputMaybe<Scalars['Json']['input']>;
  taskType: EmmaTaskType;
};

export type AssignEmmaTaskForContactResponse = {
  __typename?: 'AssignEmmaTaskForContactResponse';
  errors?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  success: Scalars['Boolean']['output'];
  task?: Maybe<EmmaTaskGql>;
  tasks?: Maybe<Array<EmmaTaskGql>>;
};

export type Auth0User = {
  __typename?: 'Auth0User';
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  picture?: Maybe<Scalars['String']['output']>;
};

export type AuthenticatedDeepLinkData = {
  __typename?: 'AuthenticatedDeepLinkData';
  authenticatedSessionData?: Maybe<AuthenticatedDeepLinkDataSession>;
  policies?: Maybe<Array<Maybe<AuthenticatedDeepLinkDataPolicy>>>;
};

export type AuthenticatedDeepLinkDataContact = {
  __typename?: 'AuthenticatedDeepLinkDataContact';
  dateOfBirth?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
};

export type AuthenticatedDeepLinkDataPolicy = {
  __typename?: 'AuthenticatedDeepLinkDataPolicy';
  contacts?: Maybe<Array<Maybe<AuthenticatedDeepLinkDataContact>>>;
  policyNumber?: Maybe<Scalars['String']['output']>;
  policyType?: Maybe<Scalars['String']['output']>;
};

export type AuthenticatedDeepLinkDataSession = {
  __typename?: 'AuthenticatedDeepLinkDataSession';
  carrierUserId?: Maybe<Scalars['String']['output']>;
};

export type Begin2FAcAuthResponse = {
  __typename?: 'Begin2FAcAuthResponse';
  phoneNumber?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export const BusinessMarketTarget = {
  BusinessOperations: 'BUSINESS_OPERATIONS',
  Contractors: 'CONTRACTORS',
  ForHireSpecialty: 'FOR_HIRE_SPECIALTY',
  ForHireTransportation: 'FOR_HIRE_TRANSPORTATION',
  TowingOperations: 'TOWING_OPERATIONS'
} as const;

export type BusinessMarketTarget = typeof BusinessMarketTarget[keyof typeof BusinessMarketTarget];
/** Create a CAT Feed. */
export type CatCreateFeedInput = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** Update a CAT Feed. */
export type CatUpdateFeedInput = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type CancelEmmaConversationTasksForContactResponse = {
  __typename?: 'CancelEmmaConversationTasksForContactResponse';
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
  tasks?: Maybe<Array<EmmaTaskGql>>;
};

export const CargoRefrigerationFailedPartType = {
  Insulation: 'INSULATION',
  Other: 'OTHER',
  RefrigerationUnit: 'REFRIGERATION_UNIT',
  TemperatureSensor: 'TEMPERATURE_SENSOR'
} as const;

export type CargoRefrigerationFailedPartType = typeof CargoRefrigerationFailedPartType[keyof typeof CargoRefrigerationFailedPartType];
export const CargoRefrigerationPackagingIssue = {
  InsufficientInsulation: 'INSUFFICIENT_INSULATION',
  InsufficientRefrigerant: 'INSUFFICIENT_REFRIGERANT',
  Other: 'OTHER'
} as const;

export type CargoRefrigerationPackagingIssue = typeof CargoRefrigerationPackagingIssue[keyof typeof CargoRefrigerationPackagingIssue];
export const CargoSpoilageReason = {
  InadequatePackaging: 'INADEQUATE_PACKAGING',
  MechanicalFailure: 'MECHANICAL_FAILURE',
  NaturalSpoilage: 'NATURAL_SPOILAGE',
  Other: 'OTHER',
  PowerOutage: 'POWER_OUTAGE',
  TransportationDelays: 'TRANSPORTATION_DELAYS'
} as const;

export type CargoSpoilageReason = typeof CargoSpoilageReason[keyof typeof CargoSpoilageReason];
export const CargoTransportationDelayReason = {
  Accident: 'ACCIDENT',
  MechanicalFailure: 'MECHANICAL_FAILURE',
  Other: 'OTHER',
  Traffic: 'TRAFFIC',
  Weather: 'WEATHER'
} as const;

export type CargoTransportationDelayReason = typeof CargoTransportationDelayReason[keyof typeof CargoTransportationDelayReason];
export type Case = {
  __typename?: 'Case';
  assignedTo?: Maybe<Scalars['String']['output']>;
  carrierNotifiedAt?: Maybe<Scalars['DateTime']['output']>;
  caseActivityFeedItems: Array<Maybe<CaseActivityFeedItem>>;
  claims: Array<Maybe<Claim>>;
  complianceDocumentBase64?: Maybe<Scalars['String']['output']>;
  contactPhoneCalls: Array<Maybe<CaseContactPhoneCall>>;
  contacts: Array<CaseContact>;
  externalDocumentContentBase64?: Maybe<Scalars['String']['output']>;
  externalId: Scalars['String']['output'];
  externalPlainTextDescription?: Maybe<Scalars['String']['output']>;
  externalType?: Maybe<Scalars['String']['output']>;
  extractedCoverageData?: Maybe<Scalars['Json']['output']>;
  generatedFromFnolClaim?: Maybe<Claim>;
  hasConversations: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  imagesDocumentBase64?: Maybe<Scalars['String']['output']>;
  incidentLocation?: Maybe<Location>;
  incidentOccurredAt?: Maybe<Scalars['DateTime']['output']>;
  incidentTimezone?: Maybe<Scalars['String']['output']>;
  investigationBeganAt?: Maybe<Scalars['DateTime']['output']>;
  investigationReadyForViewingAt?: Maybe<Scalars['DateTime']['output']>;
  investigationReadyToDispatchAt?: Maybe<Scalars['DateTime']['output']>;
  investigationScheduledToEndAt?: Maybe<Scalars['DateTime']['output']>;
  isSubscribedToNotifications: Scalars['Boolean']['output'];
  linkAccessUrl?: Maybe<Scalars['String']['output']>;
  policyNumber?: Maybe<Scalars['String']['output']>;
  rentalReservations?: Maybe<Array<RentalReservation>>;
  status: CaseStatus;
  type?: Maybe<CaseType>;
  unifiedClaim?: Maybe<Scalars['Json']['output']>;
  unreadMessageCount: Scalars['Int']['output'];
  vehicles: Array<CaseVehicle>;
};


export type CaseComplianceDocumentBase64Args = {
  contactId?: InputMaybe<Scalars['String']['input']>;
  useRichPdf?: InputMaybe<Scalars['Boolean']['input']>;
  workflowHistoryOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CaseAccessLinkPayload = {
  __typename?: 'CaseAccessLinkPayload';
  link?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  types?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type CaseActivityFeedItem = {
  __typename?: 'CaseActivityFeedItem';
  caseEventType: CaseEventType;
  caseId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  data: Scalars['Json']['output'];
  fullDescription: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  microDescription: Scalars['String']['output'];
};

export type CaseContact = {
  __typename?: 'CaseContact';
  caseId?: Maybe<Scalars['String']['output']>;
  caseVehicle?: Maybe<CaseVehicle>;
  claims: Array<Claim>;
  /** Methods of contacting this contact */
  contactMethods: Array<Maybe<ContactMethod>>;
  description?: Maybe<Scalars['String']['output']>;
  disabledCampaigns: Array<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  firstContactCommunication?: Maybe<Array<Maybe<OutgoingCommunication>>>;
  gpcCompletedAt?: Maybe<Scalars['DateTime']['output']>;
  gpcOptOutByAdjusterUserId?: Maybe<Scalars['String']['output']>;
  gpcOptOutByUser: Scalars['Boolean']['output'];
  gpcServicePreference: CommunicationPreferenceLevel;
  guidedPhotoSession?: Maybe<GuidedPhotoSessionGql>;
  id: Scalars['ID']['output'];
  investigationOptions?: Maybe<CaseContactInvestigationOptions>;
  messagingOptOutByAdjusterUserId?: Maybe<Scalars['String']['output']>;
  messagingOptOutByUser: Scalars['Boolean']['output'];
  messagingServicePreference: CommunicationPreferenceLevel;
  name: Scalars['String']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  reportState?: Maybe<CaseContactReportState>;
  selfServiceMoiMorCompletedAt?: Maybe<Scalars['DateTime']['output']>;
  selfServiceMorMorOptOutByAdjusterUserId?: Maybe<Scalars['String']['output']>;
  selfServiceMorMorOptOutByUser: Scalars['Boolean']['output'];
  selfServiceMorMorServicePreference: CommunicationPreferenceLevel;
  source: CaseContactSource;
  types: Array<CaseContactType>;
};

export type CaseContactAddNewInput = {
  caseVehicleId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  types: Array<Scalars['String']['input']>;
};

export const CaseContactInvestigationDisabledReason = {
  AlreadyInvestigated: 'ALREADY_INVESTIGATED',
  AttorneyRepresented: 'ATTORNEY_REPRESENTED',
  CeaseAndDesistLetter: 'CEASE_AND_DESIST_LETTER',
  Deceased: 'DECEASED',
  Minor: 'MINOR',
  NoContactMethodsEnabled: 'NO_CONTACT_METHODS_ENABLED',
  StatedPreference: 'STATED_PREFERENCE',
  UninvolvedParty: 'UNINVOLVED_PARTY'
} as const;

export type CaseContactInvestigationDisabledReason = typeof CaseContactInvestigationDisabledReason[keyof typeof CaseContactInvestigationDisabledReason];
export type CaseContactInvestigationOptions = {
  __typename?: 'CaseContactInvestigationOptions';
  disabledReason?: Maybe<CaseContactInvestigationDisabledReason>;
  id: Scalars['ID']['output'];
  optOutByUser?: Maybe<Scalars['Boolean']['output']>;
  repairCoordinationEligible: Scalars['Boolean']['output'];
  shouldContact: Scalars['Boolean']['output'];
};

export type CaseContactInvestigationSettingsInput = {
  contactId: Scalars['ID']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  shouldContact: Scalars['Boolean']['input'];
};

export type CaseContactPhoneCall = {
  __typename?: 'CaseContactPhoneCall';
  author?: Maybe<Auth0User>;
  caseContact?: Maybe<CaseContact>;
  caseContactId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  details: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  reason?: Maybe<CasePhoneCallReasonGql>;
  updatedAt: Scalars['DateTime']['output'];
};

export type CaseContactPhoneCallCreationError = {
  __typename?: 'CaseContactPhoneCallCreationError';
  message?: Maybe<Scalars['String']['output']>;
};

export type CaseContactPhoneCallCreationResult = CaseContactPhoneCall | CaseContactPhoneCallCreationError;

export type CaseContactReportState = {
  __typename?: 'CaseContactReportState';
  firstContactedAt?: Maybe<Scalars['DateTime']['output']>;
  firstEngagedAt?: Maybe<Scalars['DateTime']['output']>;
  lastContactedAt?: Maybe<Scalars['DateTime']['output']>;
  lastEngagedAt?: Maybe<Scalars['DateTime']['output']>;
  sentToCarrierAt?: Maybe<Scalars['DateTime']['output']>;
  submittedAt?: Maybe<Scalars['DateTime']['output']>;
};

export const CaseContactSource = {
  Adjuster: 'ADJUSTER',
  Carrier: 'CARRIER',
  Discovered: 'DISCOVERED',
  Emma: 'EMMA',
  External: 'EXTERNAL',
  Messaging: 'MESSAGING'
} as const;

export type CaseContactSource = typeof CaseContactSource[keyof typeof CaseContactSource];
export const CaseContactType = {
  Bicyclist: 'BICYCLIST',
  Driver: 'DRIVER',
  Owner: 'OWNER',
  Passenger: 'PASSENGER',
  Pedestrian: 'PEDESTRIAN',
  PrimaryInsured: 'PRIMARY_INSURED',
  Reporter: 'REPORTER',
  SecondaryInsured: 'SECONDARY_INSURED',
  UninvolvedOther: 'UNINVOLVED_OTHER',
  UninvolvedOwner: 'UNINVOLVED_OWNER',
  Witness: 'WITNESS'
} as const;

export type CaseContactType = typeof CaseContactType[keyof typeof CaseContactType];
/** Same as CaseContactSource but applicable to any case object */
export const CaseDataSource = {
  Adjuster: 'ADJUSTER',
  Carrier: 'CARRIER',
  Discovered: 'DISCOVERED',
  Emma: 'EMMA',
  External: 'EXTERNAL',
  Messaging: 'MESSAGING'
} as const;

export type CaseDataSource = typeof CaseDataSource[keyof typeof CaseDataSource];
export const CaseEventType = {
  CaseContactAdded: 'Case_ContactAdded',
  CaseContactMethodAdded: 'Case_ContactMethodAdded',
  CaseContactMethodsUpdated: 'Case_ContactMethodsUpdated',
  CaseFnolSubmitted: 'Case_FNOLSubmitted',
  EmmaEmmaTaskAssigned: 'Emma_EmmaTaskAssigned',
  EmmaEmmaTaskAttentionNeeded: 'Emma_EmmaTaskAttentionNeeded',
  EmmaEmmaTaskCompleted: 'Emma_EmmaTaskCompleted',
  FollowupFollowupCompleted: 'Followup_FollowupCompleted',
  FollowupFollowupDeclined: 'Followup_FollowupDeclined',
  FollowupFollowupSent: 'Followup_FollowupSent',
  FollowupFollowupStarted: 'Followup_FollowupStarted',
  InternalCaseAssigned: 'Internal_CaseAssigned',
  InternalCaseSubscriptionUpdated: 'Internal_CaseSubscriptionUpdated',
  InternalReminderDue: 'Internal_ReminderDue',
  InvestigationInvestigationCompleted: 'Investigation_InvestigationCompleted',
  InvestigationInvestigationDeclined: 'Investigation_InvestigationDeclined',
  InvestigationInvestigationDisabled: 'Investigation_InvestigationDisabled',
  InvestigationInvestigationGenerated: 'Investigation_InvestigationGenerated',
  InvestigationInvestigationSent: 'Investigation_InvestigationSent',
  InvestigationInvestigationStarted: 'Investigation_InvestigationStarted',
  MessagingConversationEventDetected: 'Messaging_ConversationEventDetected',
  MessagingConversationMessageReceived: 'Messaging_ConversationMessageReceived'
} as const;

export type CaseEventType = typeof CaseEventType[keyof typeof CaseEventType];
export type CaseGodControlsInput = {
  markArchived?: InputMaybe<Scalars['Boolean']['input']>;
  markReady?: InputMaybe<Scalars['Boolean']['input']>;
  markUnarchived?: InputMaybe<Scalars['Boolean']['input']>;
  startDispatch?: InputMaybe<Scalars['Boolean']['input']>;
  submitPerspectiveToCarrier?: InputMaybe<Scalars['String']['input']>;
};

export type CaseIdOrClaimId = {
  __typename?: 'CaseIdOrClaimId';
  caseId?: Maybe<Scalars['String']['output']>;
  claimId?: Maybe<Scalars['String']['output']>;
};

export const CaseOrderByInput = {
  AssignedTo: 'assignedTo',
  ExternalId: 'externalId',
  ExternalType: 'externalType',
  Id: 'id',
  InvestigationBeganAt: 'investigationBeganAt',
  Status: 'status'
} as const;

export type CaseOrderByInput = typeof CaseOrderByInput[keyof typeof CaseOrderByInput];
export const CasePhoneCallReasonGql = {
  CoordinationOfServices: 'COORDINATION_OF_SERVICES',
  CoverageExplanation: 'COVERAGE_EXPLANATION',
  MissingInformation: 'MISSING_INFORMATION',
  Other: 'OTHER',
  ReverifyingInformation: 'REVERIFYING_INFORMATION'
} as const;

export type CasePhoneCallReasonGql = typeof CasePhoneCallReasonGql[keyof typeof CasePhoneCallReasonGql];
export const CaseStatus = {
  Completed: 'COMPLETED',
  Draft: 'DRAFT',
  Processing: 'PROCESSING',
  Ready: 'READY'
} as const;

export type CaseStatus = typeof CaseStatus[keyof typeof CaseStatus];
export const CaseType = {
  FnolPlus: 'FNOL_PLUS',
  Investigation: 'INVESTIGATION'
} as const;

export type CaseType = typeof CaseType[keyof typeof CaseType];
export type CaseVehicle = {
  __typename?: 'CaseVehicle';
  color?: Maybe<Scalars['String']['output']>;
  commercialAutoVehicle?: Maybe<CommercialAutoVehicle>;
  id: Scalars['ID']['output'];
  isCarrierInsuredVehicle?: Maybe<Scalars['Boolean']['output']>;
  licensePlate?: Maybe<Scalars['String']['output']>;
  licensePlateState?: Maybe<Scalars['String']['output']>;
  make?: Maybe<Scalars['String']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  otherColor?: Maybe<Scalars['String']['output']>;
  type?: Maybe<VehicleType>;
  vin?: Maybe<Scalars['String']['output']>;
  year?: Maybe<Scalars['Int']['output']>;
};

export const CatType = {
  Blizzard: 'BLIZZARD',
  DustStorm: 'DUST_STORM',
  Earthquake: 'EARTHQUAKE',
  Flood: 'FLOOD',
  Hailstorm: 'HAILSTORM',
  HardFreeze: 'HARD_FREEZE',
  Hurricane: 'HURRICANE',
  IceStorm: 'ICE_STORM',
  LightningStorm: 'LIGHTNING_STORM',
  Tornado: 'TORNADO',
  Wildfire: 'WILDFIRE',
  Windstorm: 'WINDSTORM',
  WinterStorm: 'WINTER_STORM'
} as const;

export type CatType = typeof CatType[keyof typeof CatType];
export type Catastrophe = {
  __typename?: 'Catastrophe';
  /** Number of users who have been contacted in regards to this catastrophe. */
  afterUsersContacted?: Maybe<Scalars['Int']['output']>;
  archived: Scalars['Boolean']['output'];
  /** The individual areas that make up the catastrophe. */
  areas?: Maybe<Array<Maybe<CatastropheArea>>>;
  /** Number of users who have been contacted in regards to this catastrophe. */
  beforeUsersContacted?: Maybe<Scalars['Int']['output']>;
  claimsFiled?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  /** Number of users who have been contacted in regards to this catastrophe. */
  duringUsersContacted?: Maybe<Scalars['Int']['output']>;
  events?: Maybe<Array<Maybe<CatastropheEventLog>>>;
  /** The overall polygon area of the catastrophe as GeoJSON. */
  geometry?: Maybe<Scalars['Json']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  /** Number of properties with locations within the catastrohe's area */
  propertiesAffected?: Maybe<Scalars['Int']['output']>;
  todos?: Maybe<Array<Maybe<CatastropheTodo>>>;
  /** Number of users who have been contacted in regards to this catastrophe. */
  totalUsersContacted?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type CatastropheArea = {
  __typename?: 'CatastropheArea';
  archived: Scalars['Boolean']['output'];
  catastropheId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  draft: Scalars['Boolean']['output'];
  end: Scalars['DateTime']['output'];
  geometry?: Maybe<Scalars['Json']['output']>;
  id: Scalars['ID']['output'];
  phase: CatastrophePhase;
  propertiesAffected?: Maybe<Scalars['Int']['output']>;
  severity: CatastropheSeverity;
  size?: Maybe<Scalars['Float']['output']>;
  start: Scalars['DateTime']['output'];
  type: CatType;
  updatedAt: Scalars['DateTime']['output'];
};

export type CatastropheDetails = {
  __typename?: 'CatastropheDetails';
  phase: CatastrophePhase;
  severity: CatastropheSeverity;
  type: CatType;
};

export const CatastropheEventField = {
  Archived: 'ARCHIVED',
  Area: 'AREA',
  Name: 'NAME',
  Phase: 'PHASE',
  Severity: 'SEVERITY',
  Type: 'TYPE'
} as const;

export type CatastropheEventField = typeof CatastropheEventField[keyof typeof CatastropheEventField];
export type CatastropheEventLog = {
  __typename?: 'CatastropheEventLog';
  createdAt: Scalars['DateTime']['output'];
  field?: Maybe<CatastropheEventField>;
  fieldValue?: Maybe<Scalars['String']['output']>;
  type: CatastropheEventType;
  users?: Maybe<Scalars['Int']['output']>;
};

export const CatastropheEventType = {
  Create: 'CREATE',
  Dispatch: 'DISPATCH',
  Update: 'UPDATE'
} as const;

export type CatastropheEventType = typeof CatastropheEventType[keyof typeof CatastropheEventType];
/** List of nearby catastrophes */
export type CatastropheFeed = {
  __typename?: 'CatastropheFeed';
  enabled?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type CatastropheIncident = {
  __typename?: 'CatastropheIncident';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type?: Maybe<CatastropheType>;
};

export type CatastropheIncidentClaimInvitation = {
  __typename?: 'CatastropheIncidentClaimInvitation';
  catastropheIncident?: Maybe<CatastropheIncident>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  phoneNumber: Scalars['String']['output'];
};

export const CatastrophePhase = {
  After: 'AFTER',
  Before: 'BEFORE',
  During: 'DURING'
} as const;

export type CatastrophePhase = typeof CatastrophePhase[keyof typeof CatastrophePhase];
/** A catastrophe pipeline. */
export type CatastrophePipeline = {
  __typename?: 'CatastrophePipeline';
  config: Scalars['String']['output'];
  cronTab: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  lastRun?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  version: Scalars['DateTime']['output'];
};

export const CatastropheSeverity = {
  High: 'HIGH',
  Low: 'LOW',
  Medium: 'MEDIUM'
} as const;

export type CatastropheSeverity = typeof CatastropheSeverity[keyof typeof CatastropheSeverity];
export type CatastropheTodo = {
  __typename?: 'CatastropheTodo';
  completed?: Maybe<Scalars['Boolean']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  steps?: Maybe<Array<Maybe<Scalars['Json']['output']>>>;
  title?: Maybe<Scalars['String']['output']>;
  type?: Maybe<CatastropheTodoType>;
};

export const CatastropheTodoType = {
  Action: 'action',
  Tip: 'tip'
} as const;

export type CatastropheTodoType = typeof CatastropheTodoType[keyof typeof CatastropheTodoType];
export const CatastropheType = {
  Hail: 'HAIL',
  Tornado: 'TORNADO',
  Wildfire: 'WILDFIRE',
  WinterStorm: 'WINTER_STORM'
} as const;

export type CatastropheType = typeof CatastropheType[keyof typeof CatastropheType];
export type CatastropheUserExperience = {
  __typename?: 'CatastropheUserExperience';
  currentlyInProgress?: Maybe<Scalars['Boolean']['output']>;
  details?: Maybe<Scalars['Json']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  todos?: Maybe<Array<Maybe<CatastropheTodo>>>;
  type?: Maybe<FullCatastropheType>;
};

export type Claim = {
  __typename?: 'Claim';
  caseContactId?: Maybe<Scalars['String']['output']>;
  claimMedia?: Maybe<Array<Maybe<Media>>>;
  claimVehicle?: Maybe<Vehicle>;
  claimantDeepPersonData?: Maybe<DeepPersonData>;
  commercialAutoFilerParty?: Maybe<CommercialAutoParty>;
  commercialAutoFilerPartyId?: Maybe<Scalars['String']['output']>;
  commercialAutoIncident?: Maybe<CommercialAutoIncident>;
  commercialAutoParties: Array<CommercialAutoParty>;
  compliancePdf?: Maybe<ClaimCompliancePdf>;
  draftNumber?: Maybe<Scalars['String']['output']>;
  /** Tasks assigned to Emma for a specific claim */
  emmaTasks?: Maybe<EmmaTasksAssignedForClaimResponse>;
  externalId?: Maybe<Scalars['String']['output']>;
  externalTypes: Array<Scalars['String']['output']>;
  filerInvolvedParty?: Maybe<InvolvedParty>;
  followupStatuses?: Maybe<Array<Maybe<ClaimFollowupStatus>>>;
  generatedInvestigationCase?: Maybe<Case>;
  generatedInvestigationCaseId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  incidentLocation?: Maybe<Location>;
  incidentOccurredAt?: Maybe<Scalars['DateTime']['output']>;
  intakeAssignedTo?: Maybe<Scalars['String']['output']>;
  intakeComments?: Maybe<Array<Maybe<ClaimIntakeComment>>>;
  intakeFilingDate?: Maybe<Scalars['DateTime']['output']>;
  intakeFilingParty?: Maybe<Scalars['String']['output']>;
  intakeIdentifier?: Maybe<Scalars['String']['output']>;
  intakeStatus?: Maybe<ClaimIntakeStatus>;
  intakeSummary?: Maybe<Scalars['String']['output']>;
  intakeSummaryShort?: Maybe<Scalars['String']['output']>;
  intakeTags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  intakeType?: Maybe<ClaimIntakeType>;
  involvedParties?: Maybe<Array<Maybe<InvolvedParty>>>;
  localeData?: Maybe<LocaleData>;
  originalReportingMethod?: Maybe<Scalars['String']['output']>;
  pdsOutput?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  policyCarrier?: Maybe<Scalars['String']['output']>;
  policyNumber?: Maybe<Scalars['String']['output']>;
  raw?: Maybe<Scalars['Json']['output']>;
  rentalReservations?: Maybe<Array<RentalReservation>>;
  sidekickAssignedTo?: Maybe<Scalars['String']['output']>;
  status: ClaimStatus;
  submittedAt?: Maybe<Scalars['DateTime']['output']>;
  surrogateForFilingParty?: Maybe<SurrogateForFilingParty>;
  surrogatedClaimant?: Maybe<Claimant>;
  totalLossScores?: Maybe<Array<Maybe<TotalLossScore>>>;
  user?: Maybe<User>;
  workflows: Array<Workflow>;
};


export type ClaimCompliancePdfArgs = {
  useRichPdf?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ClaimCompliancePdf = {
  __typename?: 'ClaimCompliancePdf';
  base64EncodedPdf?: Maybe<Scalars['String']['output']>;
};

export type ClaimExternalIdValidationResponse = {
  __typename?: 'ClaimExternalIdValidationResponse';
  valid: Scalars['Boolean']['output'];
};

export const ClaimFollowupChoice = {
  Accepted: 'ACCEPTED',
  Declined: 'DECLINED'
} as const;

export type ClaimFollowupChoice = typeof ClaimFollowupChoice[keyof typeof ClaimFollowupChoice];
export const ClaimFollowupEligibility = {
  Eligible: 'ELIGIBLE',
  Ineligible: 'INELIGIBLE'
} as const;

export type ClaimFollowupEligibility = typeof ClaimFollowupEligibility[keyof typeof ClaimFollowupEligibility];
export const ClaimFollowupIneligibilityReason = {
  AlreadyOfferedViaReferralHub: 'ALREADY_OFFERED_VIA_REFERRAL_HUB',
  CarrierRejectedFromPostFnolWorkflows: 'CARRIER_REJECTED_FROM_POST_FNOL_WORKFLOWS',
  ClaimAirbagsDeployed: 'CLAIM_AIRBAGS_DEPLOYED',
  ClaimContactDidNotConsentToSms: 'CLAIM_CONTACT_DID_NOT_CONSENT_TO_SMS',
  ClaimExcludedDriverDriving: 'CLAIM_EXCLUDED_DRIVER_DRIVING',
  ClaimIsActuallyACase: 'CLAIM_IS_ACTUALLY_A_CASE',
  ClaimNotFirstParty: 'CLAIM_NOT_FIRST_PARTY',
  ClaimNotPersonalAuto: 'CLAIM_NOT_PERSONAL_AUTO',
  ClaimNotUsingCarrierApi: 'CLAIM_NOT_USING_CARRIER_API',
  ClaimNoListedDriverInvolved: 'CLAIM_NO_LISTED_DRIVER_INVOLVED',
  ClaimNoRoomsOrStructures: 'CLAIM_NO_ROOMS_OR_STRUCTURES',
  ClaimRecoveredTheft: 'CLAIM_RECOVERED_THEFT',
  ClaimReporterRelationship: 'CLAIM_REPORTER_RELATIONSHIP',
  ClaimTheft: 'CLAIM_THEFT',
  ClaimTnc: 'CLAIM_TNC',
  ClaimTypeUnsupported: 'CLAIM_TYPE_UNSUPPORTED',
  ClaimUnlistedDriver: 'CLAIM_UNLISTED_DRIVER',
  ClaimWeather: 'CLAIM_WEATHER',
  DamageFire: 'DAMAGE_FIRE',
  DamageFlood: 'DAMAGE_FLOOD',
  DamageFluidsLeaking: 'DAMAGE_FLUIDS_LEAKING',
  DamageGlass: 'DAMAGE_GLASS',
  DamageHail: 'DAMAGE_HAIL',
  DamageTotalLoss: 'DAMAGE_TOTAL_LOSS',
  DamageUndercarriage: 'DAMAGE_UNDERCARRIAGE',
  DamageVandalism: 'DAMAGE_VANDALISM',
  FeatureFlagIsOff: 'FEATURE_FLAG_IS_OFF',
  GuidedPhotoSessionAlreadyInProgress: 'GUIDED_PHOTO_SESSION_ALREADY_IN_PROGRESS',
  NoValidTriggerPresent: 'NO_VALID_TRIGGER_PRESENT',
  OtherCarrierReason: 'OTHER_CARRIER_REASON',
  OtherClaimReason: 'OTHER_CLAIM_REASON',
  OtherDamageReason: 'OTHER_DAMAGE_REASON',
  OtherPolicyReason: 'OTHER_POLICY_REASON',
  OtherTenantReason: 'OTHER_TENANT_REASON',
  OtherVehicleReason: 'OTHER_VEHICLE_REASON',
  PolicyHasNotYetRenewed: 'POLICY_HAS_NOT_YET_RENEWED',
  PolicyMissingCoverage: 'POLICY_MISSING_COVERAGE',
  PolicyMissingParty: 'POLICY_MISSING_PARTY',
  PolicyMissingVehicle: 'POLICY_MISSING_VEHICLE',
  PolicyNotInEffect: 'POLICY_NOT_IN_EFFECT',
  PropertyBelongingsOnly: 'PROPERTY_BELONGINGS_ONLY',
  PropertyEntirelyDestroyed: 'PROPERTY_ENTIRELY_DESTROYED',
  PropertyNotWithinWalkingDistance: 'PROPERTY_NOT_WITHIN_WALKING_DISTANCE',
  RepairIntent: 'REPAIR_INTENT',
  TenantFeatureDisabled: 'TENANT_FEATURE_DISABLED',
  VehicleBodyType: 'VEHICLE_BODY_TYPE',
  VehicleLocation: 'VEHICLE_LOCATION',
  VehicleNotDamaged: 'VEHICLE_NOT_DAMAGED',
  VehicleNotDrivable: 'VEHICLE_NOT_DRIVABLE',
  VehicleNotOnPolicy: 'VEHICLE_NOT_ON_POLICY',
  VehicleNotPlannedToBeNearby: 'VEHICLE_NOT_PLANNED_TO_BE_NEARBY',
  VehicleVintageOrExotic: 'VEHICLE_VINTAGE_OR_EXOTIC',
  VehicleWentOffroad: 'VEHICLE_WENT_OFFROAD'
} as const;

export type ClaimFollowupIneligibilityReason = typeof ClaimFollowupIneligibilityReason[keyof typeof ClaimFollowupIneligibilityReason];
export const ClaimFollowupProgress = {
  Canceled: 'CANCELED',
  Completed: 'COMPLETED',
  Expired: 'EXPIRED',
  InProgress: 'IN_PROGRESS',
  NotSent: 'NOT_SENT',
  NotStarted: 'NOT_STARTED'
} as const;

export type ClaimFollowupProgress = typeof ClaimFollowupProgress[keyof typeof ClaimFollowupProgress];
export type ClaimFollowupStatus = {
  __typename?: 'ClaimFollowupStatus';
  choice?: Maybe<ClaimFollowupChoice>;
  choiceUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  claimId: Scalars['String']['output'];
  eligibility?: Maybe<ClaimFollowupEligibility>;
  eligibilityOverriddenBy?: Maybe<Scalars['String']['output']>;
  eligibilityUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  emmaTask?: Maybe<EmmaTaskGql>;
  followupType: ClaimFollowupType;
  id: Scalars['ID']['output'];
  ineligibilityReason?: Maybe<ClaimFollowupIneligibilityReason>;
  lastMessagingRun?: Maybe<Scalars['DateTime']['output']>;
  progress?: Maybe<ClaimFollowupProgress>;
  progressUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type ClaimFollowupStatusUpdateGql = {
  choice?: InputMaybe<ClaimFollowupChoice>;
  eligibility?: InputMaybe<ClaimFollowupEligibility>;
  id: Scalars['String']['input'];
  progress?: InputMaybe<ClaimFollowupProgress>;
};

export const ClaimFollowupType = {
  GuidedPhotoCapture: 'GUIDED_PHOTO_CAPTURE',
  IsaRepairIntent: 'ISA_REPAIR_INTENT',
  Messaging: 'MESSAGING',
  PhotoBasedEstimate: 'PHOTO_BASED_ESTIMATE',
  RequestAdditionalVehicleImages: 'REQUEST_ADDITIONAL_VEHICLE_IMAGES'
} as const;

export type ClaimFollowupType = typeof ClaimFollowupType[keyof typeof ClaimFollowupType];
export type ClaimIntakeComment = {
  __typename?: 'ClaimIntakeComment';
  adminUserId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export const ClaimIntakeStatus = {
  Pending: 'PENDING',
  Processed: 'PROCESSED'
} as const;

export type ClaimIntakeStatus = typeof ClaimIntakeStatus[keyof typeof ClaimIntakeStatus];
export const ClaimIntakeType = {
  Auto: 'auto',
  Cyber: 'cyber',
  Financial: 'financial',
  Liability: 'liability',
  Property: 'property',
  WorkersComp: 'workers_comp'
} as const;

export type ClaimIntakeType = typeof ClaimIntakeType[keyof typeof ClaimIntakeType];
export type ClaimNote = {
  __typename?: 'ClaimNote';
  authorId?: Maybe<Scalars['String']['output']>;
  category: ClaimNoteCategory;
  claimId: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  responses: Array<ClaimNoteResponse>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  workflowId?: Maybe<Scalars['String']['output']>;
  workflowStepKey: Scalars['String']['output'];
};

export const ClaimNoteCategory = {
  AdditionalCallerReporting: 'ADDITIONAL_CALLER_REPORTING',
  AlternateAddress: 'ALTERNATE_ADDRESS',
  CatNotScheduledReason: 'CAT_NOT_SCHEDULED_REASON',
  DeductibleDollars: 'DEDUCTIBLE_DOLLARS',
  DualLoss: 'DUAL_LOSS',
  ImmediateConcerns: 'IMMEDIATE_CONCERNS',
  PetInjury: 'PET_INJURY',
  RentalFsn: 'RENTAL_FSN',
  TrailerOnPolicy: 'TRAILER_ON_POLICY',
  UmbrellaPolicyClaim: 'UMBRELLA_POLICY_CLAIM',
  UnderwritingMemo: 'UNDERWRITING_MEMO',
  UnlistedDriverInfo: 'UNLISTED_DRIVER_INFO',
  VehicleUndrivableReason: 'VEHICLE_UNDRIVABLE_REASON'
} as const;

export type ClaimNoteCategory = typeof ClaimNoteCategory[keyof typeof ClaimNoteCategory];
export const ClaimNoteCategoryGql = {
  AdditionalCallerReporting: 'ADDITIONAL_CALLER_REPORTING',
  AlternateAddress: 'ALTERNATE_ADDRESS',
  CatNotScheduledReason: 'CAT_NOT_SCHEDULED_REASON',
  DeductibleDollars: 'DEDUCTIBLE_DOLLARS',
  DualLoss: 'DUAL_LOSS',
  ImmediateConcerns: 'IMMEDIATE_CONCERNS',
  PetInjury: 'PET_INJURY',
  RentalFsn: 'RENTAL_FSN',
  TrailerOnPolicy: 'TRAILER_ON_POLICY',
  UmbrellaPolicyClaim: 'UMBRELLA_POLICY_CLAIM',
  UnderwritingMemo: 'UNDERWRITING_MEMO',
  UnlistedDriverInfo: 'UNLISTED_DRIVER_INFO',
  VehicleUndrivableReason: 'VEHICLE_UNDRIVABLE_REASON'
} as const;

export type ClaimNoteCategoryGql = typeof ClaimNoteCategoryGql[keyof typeof ClaimNoteCategoryGql];
export type ClaimNoteCategorySpecificationGql = {
  __typename?: 'ClaimNoteCategorySpecificationGQL';
  fieldSpecifications?: Maybe<Array<Maybe<ClaimNoteFieldSpecificationGql>>>;
  noteCategory: ClaimNoteCategoryGql;
  noteCategoryLabel: Scalars['String']['output'];
};

export type ClaimNoteFieldSpecificationGql = {
  __typename?: 'ClaimNoteFieldSpecificationGQL';
  entityType?: Maybe<ClaimWorkflowSearchEntityTypeGql>;
  fieldKey?: Maybe<Scalars['String']['output']>;
  labelText?: Maybe<Scalars['String']['output']>;
  placeholderText?: Maybe<Scalars['String']['output']>;
  required?: Maybe<Scalars['Boolean']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type ClaimNoteResponse = {
  __typename?: 'ClaimNoteResponse';
  claimNoteId: Scalars['String']['output'];
  fieldKey: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  responseText: Scalars['String']['output'];
};

export type ClaimNoteResponseInput = {
  fieldKey: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  responseText: Scalars['String']['input'];
};

export const ClaimStatus = {
  Draft: 'DRAFT',
  PendingAssuredApproval: 'PENDING_ASSURED_APPROVAL',
  PendingAssuredReview: 'PENDING_ASSURED_REVIEW',
  SentToCarrier: 'SENT_TO_CARRIER',
  Submitted: 'SUBMITTED',
  Terminated: 'TERMINATED'
} as const;

export type ClaimStatus = typeof ClaimStatus[keyof typeof ClaimStatus];
export const ClaimTypeGql = {
  Auto: 'auto',
  Cyber: 'cyber',
  Financial: 'financial',
  Liability: 'liability',
  Property: 'property',
  WorkersComp: 'workers_comp'
} as const;

export type ClaimTypeGql = typeof ClaimTypeGql[keyof typeof ClaimTypeGql];
export type ClaimViewFeedbackInput = {
  channel: Scalars['String']['input'];
  currentScreen?: InputMaybe<Scalars['String']['input']>;
  feedback: Scalars['String']['input'];
  fromUrl: Scalars['String']['input'];
  fullstoryUrl?: InputMaybe<Scalars['String']['input']>;
  sentiment?: InputMaybe<Scalars['String']['input']>;
};

export type ClaimViewFeedbackResponseGql = {
  __typename?: 'ClaimViewFeedbackResponseGQL';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export const ClaimWorkflowSearchEntityTypeGql = {
  Contact: 'CONTACT',
  Incident: 'INCIDENT',
  Party: 'PARTY',
  Policy: 'POLICY',
  Vehicle: 'VEHICLE'
} as const;

export type ClaimWorkflowSearchEntityTypeGql = typeof ClaimWorkflowSearchEntityTypeGql[keyof typeof ClaimWorkflowSearchEntityTypeGql];
export type ClaimWorkflowSearchResultGql = {
  __typename?: 'ClaimWorkflowSearchResultGQL';
  entityFields?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  entityId?: Maybe<Scalars['String']['output']>;
  entitySummary?: Maybe<Scalars['String']['output']>;
  entityType?: Maybe<ClaimWorkflowSearchEntityTypeGql>;
  id: Scalars['String']['output'];
  indexedText: Scalars['String']['output'];
  resultType: ClaimWorkflowSearchResultTypeGql;
  stepAliases: Array<Maybe<Scalars['String']['output']>>;
  stepKey: Scalars['String']['output'];
};

export const ClaimWorkflowSearchResultTypeGql = {
  Action: 'ACTION',
  Entity: 'ENTITY',
  Question: 'QUESTION',
  Suggestion: 'SUGGESTION'
} as const;

export type ClaimWorkflowSearchResultTypeGql = typeof ClaimWorkflowSearchResultTypeGql[keyof typeof ClaimWorkflowSearchResultTypeGql];
export type Claimant = {
  __typename?: 'Claimant';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type CommercialAutoCargo = {
  __typename?: 'CommercialAutoCargo';
  cargoSpoilageReason?: Maybe<CargoSpoilageReason>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  damageDescription?: Maybe<Scalars['String']['output']>;
  damaged?: Maybe<Scalars['Boolean']['output']>;
  estimatedValueUSCents?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  loadedOnCommercialAutoVehicle?: Maybe<CommercialAutoVehicle>;
  ownerCommercialAutoParty?: Maybe<CommercialAutoParty>;
  ownerCommercialAutoPartyId?: Maybe<Scalars['String']['output']>;
  ownership?: Maybe<CommercialAutoCargoOwnership>;
  powerOutageDurationHours?: Maybe<Scalars['Int']['output']>;
  refrigerationFailedPartType?: Maybe<CargoRefrigerationFailedPartType>;
  refrigerationPackagingIssue?: Maybe<CargoRefrigerationPackagingIssue>;
  spoiled?: Maybe<Scalars['Boolean']['output']>;
  transportationDelayDurationHours?: Maybe<Scalars['Int']['output']>;
  transportationDelayReason?: Maybe<CargoTransportationDelayReason>;
  type?: Maybe<CommercialAutoCargoType>;
  typeOther?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  weight?: Maybe<CommercialAutoCargoWeight>;
  whoPackagedCargo?: Maybe<WhoPackagedCargo>;
};

export const CommercialAutoCargoOwnership = {
  ConsigneeOwned: 'CONSIGNEE_OWNED',
  DontKnow: 'DONT_KNOW',
  InsuredOwned: 'INSURED_OWNED',
  ShipperOwned: 'SHIPPER_OWNED',
  SomeoneElseOwned: 'SOMEONE_ELSE_OWNED'
} as const;

export type CommercialAutoCargoOwnership = typeof CommercialAutoCargoOwnership[keyof typeof CommercialAutoCargoOwnership];
export const CommercialAutoCargoType = {
  AgriculturalGoods: 'AGRICULTURAL_GOODS',
  AircraftAerospaceParts: 'AIRCRAFT_AEROSPACE_PARTS',
  Appliances: 'APPLIANCES',
  Asphalt: 'ASPHALT',
  BakedGoods: 'BAKED_GOODS',
  Bitumen: 'BITUMEN',
  Breakbulk: 'BREAKBULK',
  Cement: 'CEMENT',
  Chemicals: 'CHEMICALS',
  ClothingTextiles: 'CLOTHING_TEXTILES',
  Coal: 'COAL',
  ConstructionMaterials: 'CONSTRUCTION_MATERIALS',
  Cosmetics: 'COSMETICS',
  CryogenicLiquids: 'CRYOGENIC_LIQUIDS',
  DairyProducts: 'DAIRY_PRODUCTS',
  DemolitionDebris: 'DEMOLITION_DEBRIS',
  DontKnow: 'DONT_KNOW',
  DryBulk: 'DRY_BULK',
  Electronics: 'ELECTRONICS',
  FarmEquipment: 'FARM_EQUIPMENT',
  FlowersPlants: 'FLOWERS_PLANTS',
  FoodBeverages: 'FOOD_BEVERAGES',
  FoodGradeLiquids: 'FOOD_GRADE_LIQUIDS',
  FreshProduce: 'FRESH_PRODUCE',
  FrozenFoods: 'FROZEN_FOODS',
  FuelPetroleumProducts: 'FUEL_PETROLEUM_PRODUCTS',
  Furniture: 'FURNITURE',
  Garbage: 'GARBAGE',
  Gas: 'GAS',
  HazardousMaterials: 'HAZARDOUS_MATERIALS',
  HeavyPallets: 'HEAVY_PALLETS',
  HouseholdGoods: 'HOUSEHOLD_GOODS',
  IndustrialEquipment: 'INDUSTRIAL_EQUIPMENT',
  IntermodalContainers: 'INTERMODAL_CONTAINERS',
  JunkDebris: 'JUNK_DEBRIS',
  LiquidBulk: 'LIQUID_BULK',
  Livestock: 'LIVESTOCK',
  Lumber: 'LUMBER',
  MachineryEquipment: 'MACHINERY_EQUIPMENT',
  MedicalSupplies: 'MEDICAL_SUPPLIES',
  Minerals: 'MINERALS',
  Mulch: 'MULCH',
  OfficeEquipment: 'OFFICE_EQUIPMENT',
  PalletizedGoods: 'PALLETIZED_GOODS',
  PerishableFoods: 'PERISHABLE_FOODS',
  Pharmaceuticals: 'PHARMACEUTICALS',
  PrefabricatedStructures: 'PREFABRICATED_STRUCTURES',
  RawMaterials: 'RAW_MATERIALS',
  Recycling: 'RECYCLING',
  RefrigeratedCargo: 'REFRIGERATED_CARGO',
  RetailMerchandise: 'RETAIL_MERCHANDISE',
  RocksBoulders: 'ROCKS_BOULDERS',
  RoRo: 'RO_RO',
  SoilSandGravel: 'SOIL_SAND_GRAVEL',
  SomethingElse: 'SOMETHING_ELSE',
  SteelBeamsPipes: 'STEEL_BEAMS_PIPES',
  Vehicles: 'VEHICLES',
  Waste: 'WASTE',
  Water: 'WATER',
  WindTurbine: 'WIND_TURBINE'
} as const;

export type CommercialAutoCargoType = typeof CommercialAutoCargoType[keyof typeof CommercialAutoCargoType];
export const CommercialAutoCargoWeight = {
  FiftyFiveThousandPlus: 'FIFTY_FIVE_THOUSAND_PLUS',
  LessThan_1000Lbs: 'LESS_THAN_1000_LBS',
  OneToTenThousandLbs: 'ONE_TO_TEN_THOUSAND_LBS',
  TenToFiftyFiveThousandLb: 'TEN_TO_FIFTY_FIVE_THOUSAND_LB'
} as const;

export type CommercialAutoCargoWeight = typeof CommercialAutoCargoWeight[keyof typeof CommercialAutoCargoWeight];
export const CommercialAutoGaragekeeperVehicleDamageContexts = {
  Loading: 'LOADING',
  Storing: 'STORING',
  Towing: 'TOWING',
  Unloading: 'UNLOADING'
} as const;

export type CommercialAutoGaragekeeperVehicleDamageContexts = typeof CommercialAutoGaragekeeperVehicleDamageContexts[keyof typeof CommercialAutoGaragekeeperVehicleDamageContexts];
export type CommercialAutoIncident = {
  __typename?: 'CommercialAutoIncident';
  cargoDamage?: Maybe<Scalars['Boolean']['output']>;
  cargoSpill?: Maybe<Scalars['Boolean']['output']>;
  cargoSpoilage?: Maybe<Scalars['Boolean']['output']>;
  claim: Claim;
  claimId: Scalars['String']['output'];
  commercialEquipmentDamage?: Maybe<Scalars['Boolean']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  estimatedPowerLineHeightFeet?: Maybe<Scalars['Int']['output']>;
  estimatedPowerLineHeightInches?: Maybe<Scalars['Int']['output']>;
  estimatedScrapedObjectHeightFeet?: Maybe<Scalars['Int']['output']>;
  estimatedScrapedObjectHeightInches?: Maybe<Scalars['Int']['output']>;
  estimatedVehicleHeightFeet?: Maybe<Scalars['Int']['output']>;
  estimatedVehicleHeightInches?: Maybe<Scalars['Int']['output']>;
  hazardousMaterialSpill?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  jackknifeMovingForward?: Maybe<Scalars['Boolean']['output']>;
  jackknifeReversing?: Maybe<Scalars['Boolean']['output']>;
  pgrInsuredBusinessMarketTarget?: Maybe<BusinessMarketTarget>;
  postedScrapedObjectHeightFeet?: Maybe<Scalars['Int']['output']>;
  postedScrapedObjectHeightInches?: Maybe<Scalars['Int']['output']>;
  runawayTruck?: Maybe<Scalars['Boolean']['output']>;
  scrapedOverheadObject?: Maybe<Scalars['Boolean']['output']>;
  siteCleanupTypes?: Maybe<Array<Maybe<SiteCleanupType>>>;
  snaggedPowerlines?: Maybe<Scalars['Boolean']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CommercialAutoParty = {
  __typename?: 'CommercialAutoParty';
  MCNumber?: Maybe<Scalars['String']['output']>;
  USDOTNumber?: Maybe<Scalars['String']['output']>;
  claim: Claim;
  claimId: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  driverLicenseClass?: Maybe<Scalars['String']['output']>;
  driverLicenseNumber?: Maybe<Scalars['String']['output']>;
  driverLicenseState?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  filerOfClaim?: Maybe<Claim>;
  hasUsualRoute?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  isOrganization?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organizationContactPersonName?: Maybe<Scalars['String']['output']>;
  ownedCommercialAutoCargo: Array<CommercialAutoCargo>;
  ownedCommercialAutoVehicles: Array<CommercialAutoVehicle>;
  partiesWorkingForCommercialAutoParty: Array<CommercialAutoParty>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  startedWorkAtOnDayOfIncident?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  vehicleOccupant?: Maybe<VehicleOccupant>;
  vehicleOccupantId?: Maybe<Scalars['String']['output']>;
  wasHeadedToOrFromJobsiteAtTimeOfIncident?: Maybe<Scalars['Boolean']['output']>;
  wasOnUsualRouteAtTimeOfIncident?: Maybe<Scalars['Boolean']['output']>;
  workingAtTimeOfIncident?: Maybe<Scalars['Boolean']['output']>;
  workingForCommercialAutoParty?: Maybe<CommercialAutoParty>;
  workingForCommercialAutoPartyId?: Maybe<Scalars['String']['output']>;
  workingForCommercialAutoPartyRelationshipType?: Maybe<CommercialAutoPartyInvolvedPersonRelationshipType>;
};

export const CommercialAutoPartyInvolvedPersonRelationshipType = {
  Contractor: 'CONTRACTOR',
  Employee: 'EMPLOYEE',
  Executive: 'EXECUTIVE',
  Other: 'OTHER',
  Vendor: 'VENDOR',
  Volunteer: 'VOLUNTEER'
} as const;

export type CommercialAutoPartyInvolvedPersonRelationshipType = typeof CommercialAutoPartyInvolvedPersonRelationshipType[keyof typeof CommercialAutoPartyInvolvedPersonRelationshipType];
export type CommercialAutoVehicle = {
  __typename?: 'CommercialAutoVehicle';
  billOfLading?: Maybe<Media>;
  cargo?: Maybe<CommercialAutoCargo>;
  cargoId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  garagekeeperVehicleDamageContexts: Array<CommercialAutoGaragekeeperVehicleDamageContexts>;
  id: Scalars['ID']['output'];
  involvedParty?: Maybe<InvolvedParty>;
  involvedPartyId?: Maybe<Scalars['String']['output']>;
  ownerCommercialAutoParty?: Maybe<CommercialAutoParty>;
  ownerCommercialAutoPartyId?: Maybe<Scalars['String']['output']>;
  ownership?: Maybe<CommercialAutoVehicleOwnership>;
  permanentLeaseAgreement?: Maybe<Media>;
  securementTypes: Array<CommercialAutoVehicleSecurementType>;
  trailerInterchangeAgreement?: Maybe<Media>;
  type?: Maybe<CommercialAutoVehicleType>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export const CommercialAutoVehicleOwnership = {
  DontKnow: 'DONT_KNOW',
  InsuredHired: 'INSURED_HIRED',
  InsuredOwned: 'INSURED_OWNED',
  InsuredWorkerOwnedOrHired: 'INSURED_WORKER_OWNED_OR_HIRED',
  SomeoneElseOwned: 'SOMEONE_ELSE_OWNED'
} as const;

export type CommercialAutoVehicleOwnership = typeof CommercialAutoVehicleOwnership[keyof typeof CommercialAutoVehicleOwnership];
export const CommercialAutoVehicleSecurementType = {
  Chains: 'CHAINS',
  Other: 'OTHER',
  RatchetStraps: 'RATCHET_STRAPS',
  TireStraps: 'TIRE_STRAPS',
  TowHitch: 'TOW_HITCH',
  TowHook: 'TOW_HOOK',
  WeightBalancedAcrossAxles: 'WEIGHT_BALANCED_ACROSS_AXLES',
  WheelChocks: 'WHEEL_CHOCKS'
} as const;

export type CommercialAutoVehicleSecurementType = typeof CommercialAutoVehicleSecurementType[keyof typeof CommercialAutoVehicleSecurementType];
export const CommercialAutoVehicleType = {
  AgriculturalHopperTruck: 'AGRICULTURAL_HOPPER_TRUCK',
  AutoHaulingTrailer: 'AUTO_HAULING_TRAILER',
  BoomCraneGvw_0_16K: 'BOOM_CRANE_GVW_0_16K',
  BoomCraneGvw_16K_26K: 'BOOM_CRANE_GVW_16K_26K',
  BoomCraneGvw_26KPlus: 'BOOM_CRANE_GVW_26K_PLUS',
  BottomDumpGrainTrailer: 'BOTTOM_DUMP_GRAIN_TRAILER',
  BottomDumpTrailer: 'BOTTOM_DUMP_TRAILER',
  BucketTruckGvw_0_16K: 'BUCKET_TRUCK_GVW_0_16K',
  BucketTruckGvw_16K_26K: 'BUCKET_TRUCK_GVW_16K_26K',
  BucketTruckGvw_26KPlus: 'BUCKET_TRUCK_GVW_26K_PLUS',
  BulkCommodityTrailer: 'BULK_COMMODITY_TRAILER',
  Bus_9_15Passengers: 'BUS_9_15_PASSENGERS',
  Bus_16_60Passengers: 'BUS_16_60_PASSENGERS',
  Bus_61PlusPassengers: 'BUS_61_PLUS_PASSENGERS',
  CargoPassengerVan: 'CARGO_PASSENGER_VAN',
  CarCarrierRollbackGvw_0_16K: 'CAR_CARRIER_ROLLBACK_GVW_0_16K',
  CarCarrierRollbackGvw_16KPlus: 'CAR_CARRIER_ROLLBACK_GVW_16K_PLUS',
  CateringTruck: 'CATERING_TRUCK',
  CementMixer: 'CEMENT_MIXER',
  ConcessionTrailer: 'CONCESSION_TRAILER',
  DeliveryVanGvw_10KPlus: 'DELIVERY_VAN_GVW_10K_PLUS',
  DryFreightTrailer: 'DRY_FREIGHT_TRAILER',
  DumpBodyTrailer: 'DUMP_BODY_TRAILER',
  DumpTruckGvw_0_16K: 'DUMP_TRUCK_GVW_0_16K',
  DumpTruckGvw_16K_33K: 'DUMP_TRUCK_GVW_16K_33K',
  DumpTruckGvw_33K_45K: 'DUMP_TRUCK_GVW_33K_45K',
  DumpTruckGvw_45KPlus: 'DUMP_TRUCK_GVW_45K_PLUS',
  EnclosedUtilityTrailer_0_12Ft: 'ENCLOSED_UTILITY_TRAILER_0_12_FT',
  EnclosedUtilityTrailer_13PlusFt: 'ENCLOSED_UTILITY_TRAILER_13_PLUS_FT',
  FlatbedTrailer: 'FLATBED_TRAILER',
  FlatbedTruckGvw_0_16K: 'FLATBED_TRUCK_GVW_0_16K',
  FlatbedTruckGvw_16K_26K: 'FLATBED_TRUCK_GVW_16K_26K',
  FlatbedTruckGvw_26KPlus: 'FLATBED_TRUCK_GVW_26K_PLUS',
  FrontLoaderGvw_0_45K: 'FRONT_LOADER_GVW_0_45K',
  FrontLoaderGvw_45KPlus: 'FRONT_LOADER_GVW_45K_PLUS',
  GarbageTruckGvw_0_45K: 'GARBAGE_TRUCK_GVW_0_45K',
  GarbageTruckGvw_45KPlus: 'GARBAGE_TRUCK_GVW_45K_PLUS',
  GooseneckTrailer: 'GOOSENECK_TRAILER',
  Hearse: 'HEARSE',
  HorseTrailer_1_2Stalls: 'HORSE_TRAILER_1_2_STALLS',
  IceCreamTruck: 'ICE_CREAM_TRUCK',
  LargeHorseTrailer: 'LARGE_HORSE_TRAILER',
  Limousine_0_120Inches: 'LIMOUSINE_0_120_INCHES',
  Limousine_121_180Inches: 'LIMOUSINE_121_180_INCHES',
  Limousine_181PlusInches: 'LIMOUSINE_181_PLUS_INCHES',
  LivestockTrailer: 'LIVESTOCK_TRAILER',
  LoggingTrailer: 'LOGGING_TRAILER',
  LowboyTrailer: 'LOWBOY_TRAILER',
  LuxuryAuto: 'LUXURY_AUTO',
  LuxurySuv: 'LUXURY_SUV',
  Minivan: 'MINIVAN',
  MotorHome: 'MOTOR_HOME',
  PickupTruck_0HalfTon_4X2: 'PICKUP_TRUCK_0_HALF_TON_4X2',
  PickupTruck_0HalfTon_4X4: 'PICKUP_TRUCK_0_HALF_TON_4X4',
  PickupTruckHalfTonPlus_4X2: 'PICKUP_TRUCK_HALF_TON_PLUS_4X2',
  PickupTruckHalfTonPlus_4X4: 'PICKUP_TRUCK_HALF_TON_PLUS_4X4',
  PickupWithFifthWheelHitch: 'PICKUP_WITH_FIFTH_WHEEL_HITCH',
  PoleTrailer: 'POLE_TRAILER',
  PrivatePassengerAuto: 'PRIVATE_PASSENGER_AUTO',
  PumpTruckGvw_0_16K: 'PUMP_TRUCK_GVW_0_16K',
  PumpTruckGvw_16K_26K: 'PUMP_TRUCK_GVW_16K_26K',
  PumpTruckGvw_26KPlus: 'PUMP_TRUCK_GVW_26K_PLUS',
  RefrigeratedDryFreightTrailer: 'REFRIGERATED_DRY_FREIGHT_TRAILER',
  RefrigeratedTruckGvw_0_16K: 'REFRIGERATED_TRUCK_GVW_0_16K',
  RefrigeratedTruckGvw_16K_26K: 'REFRIGERATED_TRUCK_GVW_16K_26K',
  RefrigeratedTruckGvw_26KPlus: 'REFRIGERATED_TRUCK_GVW_26K_PLUS',
  RollOnVehicleGvw_0_45K: 'ROLL_ON_VEHICLE_GVW_0_45K',
  RollOnVehicleGvw_45KPlus: 'ROLL_ON_VEHICLE_GVW_45K_PLUS',
  SportAuto: 'SPORT_AUTO',
  StakeTruckGvw_0_16K: 'STAKE_TRUCK_GVW_0_16K',
  StakeTruckGvw_16K_26K: 'STAKE_TRUCK_GVW_16K_26K',
  StakeTruckGvw_26KPlus: 'STAKE_TRUCK_GVW_26K_PLUS',
  StepVanGvw_0_10K: 'STEP_VAN_GVW_0_10K',
  StraightTruckGvw_0_16K: 'STRAIGHT_TRUCK_GVW_0_16K',
  StraightTruckGvw_16K_26K: 'STRAIGHT_TRUCK_GVW_16K_26K',
  StraightTruckGvw_26KPlus: 'STRAIGHT_TRUCK_GVW_26K_PLUS',
  StreetSweeper: 'STREET_SWEEPER',
  TankTrailer: 'TANK_TRAILER',
  TankTruck_1401GallonsPlus: 'TANK_TRUCK_1401_GALLONS_PLUS',
  TankTruckBelow_1401Gallons: 'TANK_TRUCK_BELOW_1401_GALLONS',
  TiltTrailer: 'TILT_TRAILER',
  TowTruck_2Axle: 'TOW_TRUCK_2_AXLE',
  TowTruckSingleAxleGvw_0_26K: 'TOW_TRUCK_SINGLE_AXLE_GVW_0_26K',
  TowTruckSingleAxleGvw_26KPlus: 'TOW_TRUCK_SINGLE_AXLE_GVW_26K_PLUS',
  Tractor: 'TRACTOR',
  TransferDumpBodyTrailer: 'TRANSFER_DUMP_BODY_TRAILER',
  TravelTrailer: 'TRAVEL_TRAILER',
  UnenclosedUtilityTrailer_0_12Ft: 'UNENCLOSED_UTILITY_TRAILER_0_12_FT',
  UnenclosedUtilityTrailer_13PlusFt: 'UNENCLOSED_UTILITY_TRAILER_13_PLUS_FT',
  UnidentifiedTrailer: 'UNIDENTIFIED_TRAILER',
  UtilityVehicle: 'UTILITY_VEHICLE',
  WedgeTrailer: 'WEDGE_TRAILER',
  WheelchairBus_9_15Passengers: 'WHEELCHAIR_BUS_9_15_PASSENGERS',
  WheelchairBus_16_60Passengers: 'WHEELCHAIR_BUS_16_60_PASSENGERS',
  WheelchairBus_61PlusPassengers: 'WHEELCHAIR_BUS_61_PLUS_PASSENGERS',
  WheelchairVan: 'WHEELCHAIR_VAN'
} as const;

export type CommercialAutoVehicleType = typeof CommercialAutoVehicleType[keyof typeof CommercialAutoVehicleType];
export const CommunicationPreferenceLevel = {
  Accepted: 'ACCEPTED',
  Declined: 'DECLINED'
} as const;

export type CommunicationPreferenceLevel = typeof CommunicationPreferenceLevel[keyof typeof CommunicationPreferenceLevel];
/**
 * A method of contacting a case contact, with opt-out\npreferences. If a user opts
 * out of a particular type, then no one\nelse can opt back in to that type on
 * behalf of the contact.
 */
export type ContactMethod = {
  __typename?: 'ContactMethod';
  /** The contact this method belongs to */
  caseContact?: Maybe<CaseContact>;
  /** The actual phone number or email address for this contact method */
  destination: Scalars['String']['output'];
  /** Whether user has opted out of email for this method */
  emailOptOutByUser?: Maybe<Scalars['Boolean']['output']>;
  /** Comm level for email (if type is EMAIL_ADDRESS) */
  emailPreference: CommunicationPreferenceLevel;
  /** Adjuster who most recently modified the email preference */
  emailPreferenceModifiedByAdjusterId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** If type is PHONE_NUMBER, whether we have detected SMS capability */
  likelySMSCapable: Scalars['Boolean']['output'];
  /** Whether user has opted out of robocalls for this method. */
  robocallOptOutByUser?: Maybe<Scalars['Boolean']['output']>;
  /** Comm level for robocalls (if type is PHONE_NUMBER) */
  robocallPreference: CommunicationPreferenceLevel;
  /** Adjuster who most recently modified the robocall preference */
  robocallPreferenceModifiedByAdjusterId?: Maybe<Scalars['String']['output']>;
  /**
   * Whether user has opted out of SMS messages for this method.\nIf the user has
   * opted out of a particular communication type, we will\nset this to true. if
   * they have opted back in, we will set it to false.\nWe cannot opt in a user if
   * they opted themselves out.
   */
  smsOptOutByUser?: Maybe<Scalars['Boolean']['output']>;
  /** Comm level for SMS messages (if type is PHONE_NUMBER) */
  smsPreference: CommunicationPreferenceLevel;
  /** Adjuster who most recently modified the SMS preference */
  smsPreferenceModifiedByAdjusterId?: Maybe<Scalars['String']['output']>;
  /** The source from which this contact method was created. */
  source?: Maybe<CaseDataSource>;
  /**
   * Type of contact method. Determines which \`*Preference\`,\n\`*OptOutByUser\`,
   * and \`*PreferenceModifiedByAdjusterId\` fields are\nrelevant.
   */
  type: ContactMethodType;
};

export const ContactMethodType = {
  EmailAddress: 'EMAIL_ADDRESS',
  PhoneNumber: 'PHONE_NUMBER'
} as const;

export type ContactMethodType = typeof ContactMethodType[keyof typeof ContactMethodType];
/** Input object for adding a contact method to a contact. */
export type ContactMethodUpsertionInput = {
  /** Actual phone number or email address of this contact method */
  destination: Scalars['String']['input'];
  /** Email communication level, applies only if `type` is `EMAIL_ADDRESS` */
  emailPreference: CommunicationPreferenceLevel;
  /** Whether this contact method is likely to be capable of receiving texts */
  likelySMSCapable: Scalars['Boolean']['input'];
  /** Robocall communication level, applies only if `type` is `PHONE_NUMBER` */
  robocallPreference: CommunicationPreferenceLevel;
  /** SMS communication level, applies only if `type` is `PHONE_NUMBER` */
  smsPreference: CommunicationPreferenceLevel;
  /** Type of contact method. Determines which preferences are relevant. */
  type: ContactMethodType;
};

export type Conversation = {
  __typename?: 'Conversation';
  /** The conversation-controlling Emma task that is currently active for this conversation. Null if no task is active. */
  activeControllingEmmaTask?: Maybe<EmmaTaskGql>;
  adjusters: Array<ConversationAdjuster>;
  attachments: ConversationAttachmentConnection;
  autoTranslate: Scalars['Boolean']['output'];
  case: Case;
  caseId: Scalars['String']['output'];
  channel: ConversationChannelGql;
  createdAt: Scalars['DateTime']['output'];
  deactivatedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  /** The outbound message config for this conversation */
  outboundMessageConfig: ConversationOutboundMessageConfig;
  participants: Array<ConversationParticipant>;
  timeline?: Maybe<ConversationEventConnection>;
  translationDisclaimerSentAt?: Maybe<Scalars['DateTime']['output']>;
  translationLanguageCode?: Maybe<LanguageCode>;
  unreadCount: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};


export type ConversationAttachmentsArgs = {
  input?: InputMaybe<ConversationAttachmentInput>;
};


export type ConversationTimelineArgs = {
  input?: InputMaybe<ConversationTimelineInput>;
};

export type ConversationAdjuster = {
  __typename?: 'ConversationAdjuster';
  adjusterUserId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  outOfOfficeSettings?: Maybe<OutOfOfficeSettings>;
  subscribedAt?: Maybe<Scalars['DateTime']['output']>;
  user: Auth0User;
};

export type ConversationAttachmentConnection = {
  __typename?: 'ConversationAttachmentConnection';
  edges?: Maybe<Array<Maybe<ConversationAttachmentEdge>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ConversationAttachmentEdge = {
  __typename?: 'ConversationAttachmentEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<ConversationMessageAttachment>;
};

export type ConversationAttachmentInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export const ConversationChannelGql = {
  Email: 'EMAIL',
  Omni: 'OMNI',
  Sms: 'SMS'
} as const;

export type ConversationChannelGql = typeof ConversationChannelGql[keyof typeof ConversationChannelGql];
export type ConversationEdge = {
  __typename?: 'ConversationEdge';
  cursor: Scalars['String']['output'];
  node: Conversation;
};

export type ConversationEmailPreference = {
  __typename?: 'ConversationEmailPreference';
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isSubscribed: Scalars['Boolean']['output'];
};

export type ConversationEmailPreferenceError = {
  __typename?: 'ConversationEmailPreferenceError';
  message: Scalars['String']['output'];
};

export type ConversationEmailPreferenceResult = ConversationEmailPreference | ConversationEmailPreferenceError;

export type ConversationEvent = {
  __typename?: 'ConversationEvent';
  actorAdjuster?: Maybe<ConversationAdjuster>;
  actorContactMethod?: Maybe<ContactMethod>;
  body?: Maybe<Scalars['String']['output']>;
  conversationId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  targetCaseContact?: Maybe<CaseContact>;
  targetContactMethod?: Maybe<ContactMethod>;
};

export type ConversationEventConnection = {
  __typename?: 'ConversationEventConnection';
  edges: Array<ConversationEventEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ConversationEventEdge = {
  __typename?: 'ConversationEventEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<ConversationEventNode>;
};

export type ConversationEventNode = ConversationEvent | ConversationMessage | OutgoingCommunication;

export type ConversationLanguage = {
  __typename?: 'ConversationLanguage';
  code?: Maybe<LanguageCode>;
  name: Scalars['String']['output'];
};

export type ConversationMessage = {
  __typename?: 'ConversationMessage';
  actions?: Maybe<Array<Action>>;
  adjusterMessageReadAt?: Maybe<Scalars['DateTime']['output']>;
  attachments: Array<ConversationMessageAttachment>;
  conversation?: Maybe<Conversation>;
  conversationId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  direction: ConversationMessageDirectionGql;
  emailMessages: Array<EmailMessage>;
  id: Scalars['ID']['output'];
  isOptOut: Scalars['Boolean']['output'];
  plainTextEn?: Maybe<Scalars['String']['output']>;
  recipientContactMethods: Array<ContactMethod>;
  richText?: Maybe<Scalars['String']['output']>;
  richTextTranslation?: Maybe<Scalars['String']['output']>;
  senderAdjuster?: Maybe<ConversationAdjuster>;
  senderContactMethod?: Maybe<ContactMethod>;
  /** EmmaTask that sent the message */
  senderEmmaTask?: Maybe<EmmaTaskGql>;
  senderSystem: Scalars['Boolean']['output'];
  smsMessages: Array<SmsMessage>;
  sourceLanguageCode?: Maybe<LanguageCode>;
  targetLanguageCode?: Maybe<LanguageCode>;
};

export type ConversationMessageAttachment = {
  __typename?: 'ConversationMessageAttachment';
  contentType: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  direction: ConversationMessageDirectionGql;
  downloadUrl?: Maybe<Scalars['String']['output']>;
  filename: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  senderAdjuster?: Maybe<ConversationAdjuster>;
  senderContactMethod?: Maybe<ContactMethod>;
  size: Scalars['Float']['output'];
  thumbnailUrl?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export const ConversationMessageDirectionGql = {
  Inbound: 'INBOUND',
  Outbound: 'OUTBOUND'
} as const;

export type ConversationMessageDirectionGql = typeof ConversationMessageDirectionGql[keyof typeof ConversationMessageDirectionGql];
export type ConversationMessageMacro = {
  __typename?: 'ConversationMessageMacro';
  actions: Array<ActionTypeGql>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  message: Scalars['String']['output'];
  ownerAdjusterUserId?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  visibilityLevel: ConversationMessageMacroVisibilityLevel;
};

export type ConversationMessageMacroGlobalVariable = {
  __typename?: 'ConversationMessageMacroGlobalVariable';
  id?: Maybe<Scalars['ID']['output']>;
  label: Scalars['String']['output'];
  type: ConversationMessageMacroVariableType;
};

export type ConversationMessageMacroHydratedGlobalVariable = {
  __typename?: 'ConversationMessageMacroHydratedGlobalVariable';
  id?: Maybe<Scalars['ID']['output']>;
  label: Scalars['String']['output'];
  type: ConversationMessageMacroVariableType;
  value?: Maybe<Scalars['String']['output']>;
};

export const ConversationMessageMacroVariableType = {
  Text: 'TEXT'
} as const;

export type ConversationMessageMacroVariableType = typeof ConversationMessageMacroVariableType[keyof typeof ConversationMessageMacroVariableType];
export const ConversationMessageMacroVisibilityLevel = {
  AllAdjusters: 'ALL_ADJUSTERS',
  Owner: 'OWNER'
} as const;

export type ConversationMessageMacroVisibilityLevel = typeof ConversationMessageMacroVisibilityLevel[keyof typeof ConversationMessageMacroVisibilityLevel];
export type ConversationOutboundMessageConfig = {
  __typename?: 'ConversationOutboundMessageConfig';
  defaultLanguage?: Maybe<Scalars['String']['output']>;
  disabled?: Maybe<Scalars['Boolean']['output']>;
  disabledReason?: Maybe<Scalars['String']['output']>;
};

export type ConversationParticipant = {
  __typename?: 'ConversationParticipant';
  contact?: Maybe<CaseContact>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type ConversationResult = Conversation | NotFoundError;

export type ConversationTimelineInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  inclusive?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type ConversationTimelineSearchInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  caseId: Scalars['String']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  search: Scalars['String']['input'];
};

export type ConversationsConnection = {
  __typename?: 'ConversationsConnection';
  edges: Array<ConversationEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type ConversationsInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  caseId: Scalars['ID']['input'];
  channel?: InputMaybe<ConversationChannelGql>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  participantCaseContactIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type Coordinate = {
  __typename?: 'Coordinate';
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
};

export const Country = {
  Ad: 'AD',
  Ae: 'AE',
  Af: 'AF',
  Ag: 'AG',
  Ai: 'AI',
  Al: 'AL',
  Am: 'AM',
  Ao: 'AO',
  Aq: 'AQ',
  Ar: 'AR',
  As: 'AS',
  At: 'AT',
  Au: 'AU',
  Aw: 'AW',
  Ax: 'AX',
  Az: 'AZ',
  Ba: 'BA',
  Bb: 'BB',
  Bd: 'BD',
  Be: 'BE',
  Bf: 'BF',
  Bg: 'BG',
  Bh: 'BH',
  Bi: 'BI',
  Bj: 'BJ',
  Bl: 'BL',
  Bm: 'BM',
  Bn: 'BN',
  Bo: 'BO',
  Bq: 'BQ',
  Br: 'BR',
  Bs: 'BS',
  Bt: 'BT',
  Bv: 'BV',
  Bw: 'BW',
  By: 'BY',
  Bz: 'BZ',
  Ca: 'CA',
  Cc: 'CC',
  Cd: 'CD',
  Cf: 'CF',
  Cg: 'CG',
  Ch: 'CH',
  Ci: 'CI',
  Ck: 'CK',
  Cl: 'CL',
  Cm: 'CM',
  Cn: 'CN',
  Co: 'CO',
  Cr: 'CR',
  Cu: 'CU',
  Cv: 'CV',
  Cw: 'CW',
  Cx: 'CX',
  Cy: 'CY',
  Cz: 'CZ',
  De: 'DE',
  Dj: 'DJ',
  Dk: 'DK',
  Dm: 'DM',
  Do: 'DO',
  Dz: 'DZ',
  Ec: 'EC',
  Ee: 'EE',
  Eg: 'EG',
  Eh: 'EH',
  Er: 'ER',
  Es: 'ES',
  Et: 'ET',
  Fi: 'FI',
  Fj: 'FJ',
  Fk: 'FK',
  Fm: 'FM',
  Fo: 'FO',
  Fr: 'FR',
  Ga: 'GA',
  Gb: 'GB',
  Gd: 'GD',
  Ge: 'GE',
  Gf: 'GF',
  Gg: 'GG',
  Gh: 'GH',
  Gi: 'GI',
  Gl: 'GL',
  Gm: 'GM',
  Gn: 'GN',
  Gp: 'GP',
  Gq: 'GQ',
  Gr: 'GR',
  Gs: 'GS',
  Gt: 'GT',
  Gu: 'GU',
  Gw: 'GW',
  Gy: 'GY',
  Hk: 'HK',
  Hm: 'HM',
  Hn: 'HN',
  Hr: 'HR',
  Ht: 'HT',
  Hu: 'HU',
  Id: 'ID',
  Ie: 'IE',
  Il: 'IL',
  Im: 'IM',
  In: 'IN',
  Io: 'IO',
  Iq: 'IQ',
  Ir: 'IR',
  Is: 'IS',
  It: 'IT',
  Je: 'JE',
  Jm: 'JM',
  Jo: 'JO',
  Jp: 'JP',
  Ke: 'KE',
  Kg: 'KG',
  Kh: 'KH',
  Ki: 'KI',
  Km: 'KM',
  Kn: 'KN',
  Kp: 'KP',
  Kr: 'KR',
  Kw: 'KW',
  Ky: 'KY',
  Kz: 'KZ',
  La: 'LA',
  Lb: 'LB',
  Lc: 'LC',
  Li: 'LI',
  Lk: 'LK',
  Lr: 'LR',
  Ls: 'LS',
  Lt: 'LT',
  Lu: 'LU',
  Lv: 'LV',
  Ly: 'LY',
  Ma: 'MA',
  Mc: 'MC',
  Md: 'MD',
  Me: 'ME',
  Mf: 'MF',
  Mg: 'MG',
  Mh: 'MH',
  Mk: 'MK',
  Ml: 'ML',
  Mm: 'MM',
  Mn: 'MN',
  Mo: 'MO',
  Mp: 'MP',
  Mq: 'MQ',
  Mr: 'MR',
  Ms: 'MS',
  Mt: 'MT',
  Mu: 'MU',
  Mv: 'MV',
  Mw: 'MW',
  Mx: 'MX',
  My: 'MY',
  Mz: 'MZ',
  Na: 'NA',
  Nc: 'NC',
  Ne: 'NE',
  Nf: 'NF',
  Ng: 'NG',
  Ni: 'NI',
  Nl: 'NL',
  No: 'NO',
  Np: 'NP',
  Nr: 'NR',
  Nu: 'NU',
  Nz: 'NZ',
  Om: 'OM',
  Other: 'OTHER',
  Pa: 'PA',
  Pe: 'PE',
  Pf: 'PF',
  Pg: 'PG',
  Ph: 'PH',
  Pk: 'PK',
  Pl: 'PL',
  Pm: 'PM',
  Pn: 'PN',
  Pr: 'PR',
  Ps: 'PS',
  Pt: 'PT',
  Pw: 'PW',
  Py: 'PY',
  Qa: 'QA',
  Re: 'RE',
  Ro: 'RO',
  Rs: 'RS',
  Ru: 'RU',
  Rw: 'RW',
  Sa: 'SA',
  Sb: 'SB',
  Sc: 'SC',
  Sd: 'SD',
  Se: 'SE',
  Sg: 'SG',
  Sh: 'SH',
  Si: 'SI',
  Sj: 'SJ',
  Sk: 'SK',
  Sl: 'SL',
  Sm: 'SM',
  Sn: 'SN',
  So: 'SO',
  Sr: 'SR',
  Ss: 'SS',
  St: 'ST',
  Sv: 'SV',
  Sx: 'SX',
  Sy: 'SY',
  Sz: 'SZ',
  Tc: 'TC',
  Td: 'TD',
  Tf: 'TF',
  Tg: 'TG',
  Th: 'TH',
  Tj: 'TJ',
  Tk: 'TK',
  Tl: 'TL',
  Tm: 'TM',
  Tn: 'TN',
  To: 'TO',
  Tr: 'TR',
  Tt: 'TT',
  Tv: 'TV',
  Tw: 'TW',
  Tz: 'TZ',
  Ua: 'UA',
  Ug: 'UG',
  Um: 'UM',
  Us: 'US',
  Uy: 'UY',
  Uz: 'UZ',
  Va: 'VA',
  Vc: 'VC',
  Ve: 'VE',
  Vg: 'VG',
  Vi: 'VI',
  Vn: 'VN',
  Vu: 'VU',
  Wf: 'WF',
  Ws: 'WS',
  Ye: 'YE',
  Yt: 'YT',
  Za: 'ZA',
  Zm: 'ZM',
  Zw: 'ZW'
} as const;

export type Country = typeof Country[keyof typeof Country];
/** Create a new catastrophe area. */
export type CreateCatastropheAreaInput = {
  catastropheId: Scalars['String']['input'];
  end: Scalars['DateTime']['input'];
  /** The polygon area of the catastrophe as GeoJSON. */
  geoJSON: Scalars['Json']['input'];
  phase?: CatastrophePhase;
  severity?: CatastropheSeverity;
  start: Scalars['DateTime']['input'];
  type: CatType;
};

/** Create a new catastrophe. */
export type CreateCatastropheInput = {
  name: Scalars['String']['input'];
};

export type CreateClaimNoteResponseInput = {
  fieldKey: Scalars['String']['input'];
  responseText: Scalars['String']['input'];
};

export type CreateClaimNoteResultGql = {
  __typename?: 'CreateClaimNoteResultGQL';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type CreateEmmaConversationMessageResult = {
  __typename?: 'CreateEmmaConversationMessageResult';
  errors?: Maybe<Array<Scalars['String']['output']>>;
  messages: Array<ConversationMessage>;
  success: Scalars['Boolean']['output'];
};

export type CrimeHistory = {
  __typename?: 'CrimeHistory';
  caseDetails?: Maybe<Scalars['Json']['output']>;
  chargeDetails?: Maybe<Scalars['Json']['output']>;
};

export const DamageRegionGql = {
  DriverSideFront: 'DRIVER_SIDE_FRONT',
  DriverSideMiddle: 'DRIVER_SIDE_MIDDLE',
  DriverSideRear: 'DRIVER_SIDE_REAR',
  Front: 'FRONT',
  Hood: 'HOOD',
  Interior: 'INTERIOR',
  PassengerSideFront: 'PASSENGER_SIDE_FRONT',
  PassengerSideMiddle: 'PASSENGER_SIDE_MIDDLE',
  PassengerSideRear: 'PASSENGER_SIDE_REAR',
  Rear: 'REAR',
  Roof: 'ROOF',
  TrunkLid: 'TRUNK_LID',
  Undercarriage: 'UNDERCARRIAGE'
} as const;

export type DamageRegionGql = typeof DamageRegionGql[keyof typeof DamageRegionGql];
export type DeepPersonData = {
  __typename?: 'DeepPersonData';
  city?: Maybe<Scalars['String']['output']>;
  crimeHistory?: Maybe<Array<Maybe<CrimeHistory>>>;
  currentOccupation?: Maybe<Scalars['String']['output']>;
  dateOfBirth?: Maybe<Scalars['String']['output']>;
  facebookScrapedData?: Maybe<Scalars['Json']['output']>;
  facebookUrl?: Maybe<Scalars['String']['output']>;
  finalEducation?: Maybe<Scalars['String']['output']>;
  fullAddress?: Maybe<Scalars['String']['output']>;
  interests?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  peopleDataLabsBlob?: Maybe<Scalars['Json']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  socialProfiles?: Maybe<Array<Maybe<SocialProfiles>>>;
  state?: Maybe<Scalars['String']['output']>;
  streetAddress?: Maybe<Scalars['String']['output']>;
};

export type Demo = {
  __typename?: 'Demo';
  accessLink?: Maybe<Scalars['String']['output']>;
  expiry: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  jumpIntoWorkflow?: Maybe<WorkflowType>;
  label: Scalars['String']['output'];
  prefill: Scalars['Boolean']['output'];
  tenant: Tenant;
  type: DemoType;
};

export const DemoOrchestrationActionType = {
  After: 'AFTER',
  Before: 'BEFORE',
  During: 'DURING'
} as const;

export type DemoOrchestrationActionType = typeof DemoOrchestrationActionType[keyof typeof DemoOrchestrationActionType];
export type DemoRecipientInputType = {
  name: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
};

export const DemoType = {
  BulkSms: 'BULK_SMS',
  Link: 'LINK',
  QrCode: 'QR_CODE'
} as const;

export type DemoType = typeof DemoType[keyof typeof DemoType];
export const Direction = {
  Back: 'Back',
  Current: 'Current',
  Forward: 'Forward'
} as const;

export type Direction = typeof Direction[keyof typeof Direction];
export type Dispatch = {
  __typename?: 'Dispatch';
  id: Scalars['ID']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
};

export type Document = {
  __typename?: 'Document';
  id: Scalars['ID']['output'];
  parsedContent?: Maybe<Scalars['Json']['output']>;
  source: Scalars['String']['output'];
};

export const DocumentFeature = {
  DriverLicense: 'DRIVER_LICENSE',
  HealthInsuranceCard: 'HEALTH_INSURANCE_CARD',
  InsuranceCard: 'INSURANCE_CARD'
} as const;

export type DocumentFeature = typeof DocumentFeature[keyof typeof DocumentFeature];
export const DocumentType = {
  Image: 'IMAGE',
  Pdf: 'PDF'
} as const;

export type DocumentType = typeof DocumentType[keyof typeof DocumentType];
export type DocumentUpload = {
  __typename?: 'DocumentUpload';
  document?: Maybe<Document>;
  extractionConfidence?: Maybe<Scalars['Int']['output']>;
};

export type DomainContact = {
  __typename?: 'DomainContact';
  attorney?: Maybe<DomainContact>;
  bodilyInjuryData?: Maybe<Scalars['Json']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  currentLocation?: Maybe<Location>;
  emailAddress?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  injurySeverity?: Maybe<InjurySeverity>;
  isChild?: Maybe<Scalars['Boolean']['output']>;
  isFiler?: Maybe<Scalars['Boolean']['output']>;
  isInitialReporter?: Maybe<Scalars['Boolean']['output']>;
  isRepresentedByAttorney?: Maybe<Scalars['Boolean']['output']>;
  /** /////////////////////////////////////////////////\n///////////////////////////////////////////////// */
  isVehicleOwner?: Maybe<Scalars['Boolean']['output']>;
  isVerifiedCaller?: Maybe<Scalars['Boolean']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  methodOfTransportFromScene?: Maybe<MethodOfTransportFromScene>;
  name?: Maybe<Scalars['String']['output']>;
  nightsHospitalized?: Maybe<Scalars['Int']['output']>;
  partyId?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  relationshipToParty?: Maybe<RelationshipToParty>;
  seat?: Maybe<VehicleOccupantSeat>;
  userSignature?: Maybe<Scalars['String']['output']>;
  wasInjured?: Maybe<Scalars['Boolean']['output']>;
};

export type DomainIncident = {
  __typename?: 'DomainIncident';
  ambulanceArrivedAtScene?: Maybe<Scalars['Boolean']['output']>;
  arrestMadeAtScene?: Maybe<Scalars['Boolean']['output']>;
  claim: Claim;
  claimId: Scalars['String']['output'];
  classification?: Maybe<GeneralClassification>;
  collisionReconstructionData?: Maybe<Scalars['Json']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  emergencyResponseProviderContacted?: Maybe<Scalars['Boolean']['output']>;
  emergencyResponseProviderName?: Maybe<Scalars['String']['output']>;
  emergencyResponseProviderReference?: Maybe<Scalars['String']['output']>;
  fireDepartmentArrivedAtScene?: Maybe<Scalars['Boolean']['output']>;
  floodWaterType?: Maybe<FloodWaterType>;
  guidedCollisionReconstructionData?: Maybe<Scalars['Json']['output']>;
  hailSize?: Maybe<HailSize>;
  hasPropertyDamage?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  incidentSummary?: Maybe<Scalars['String']['output']>;
  isAutoClaim?: Maybe<Scalars['Boolean']['output']>;
  isHitAndRun?: Maybe<Scalars['Boolean']['output']>;
  linkableMedia?: Maybe<Array<MediaWithLinkGql>>;
  location?: Maybe<Location>;
  nonVehicleAccidentType?: Maybe<Scalars['String']['output']>;
  occurredAt?: Maybe<Scalars['DateTime']['output']>;
  otherClassification?: Maybe<OtherClassification>;
  parties: Array<DomainParty>;
  policeArrivedAtScene?: Maybe<Scalars['Boolean']['output']>;
  rawOccurredAtWithoutTimezone?: Maybe<Scalars['String']['output']>;
  singlePartyCollisionType?: Maybe<SinglePartyCollisionType>;
  singlePartyCollisionTypeOther?: Maybe<Scalars['String']['output']>;
  theftType?: Maybe<TheftType>;
  tripWasCarshare?: Maybe<Scalars['Boolean']['output']>;
  tripWasDelivery?: Maybe<Scalars['Boolean']['output']>;
  tripWasRideshare?: Maybe<Scalars['Boolean']['output']>;
  tripWasWorkRelated?: Maybe<Scalars['Boolean']['output']>;
  vandalismTypeOther?: Maybe<Scalars['String']['output']>;
  vandalismTypes?: Maybe<Array<Maybe<VandalismType>>>;
  weatherIncidentOther?: Maybe<Scalars['String']['output']>;
  windIncidentType?: Maybe<WindIncidentType>;
  windIncidentTypeOther?: Maybe<Scalars['String']['output']>;
  witnesses: Array<DomainContact>;
};

export type DomainParty = {
  __typename?: 'DomainParty';
  carrier?: Maybe<Scalars['String']['output']>;
  carrierPolicyJSON?: Maybe<Scalars['Json']['output']>;
  contacts: Array<DomainContact>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  didHaveObjectFallFromVehicle?: Maybe<Scalars['Boolean']['output']>;
  didNotMakeContact?: Maybe<Scalars['Boolean']['output']>;
  didTheftOrVandalism?: Maybe<Scalars['Boolean']['output']>;
  externalClaimNumber?: Maybe<Scalars['String']['output']>;
  fallenObjectContributedToIncident?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  incidentId?: Maybe<Scalars['String']['output']>;
  insuredType?: Maybe<InsuredType>;
  isInsuredByCarrier?: Maybe<Scalars['Boolean']['output']>;
  isReportingParty?: Maybe<Scalars['Boolean']['output']>;
  objectIncidentInteractionType?: Maybe<ObjectIncidentInteractionType>;
  partyType?: Maybe<Scalars['String']['output']>;
  policyNumber?: Maybe<Scalars['String']['output']>;
  trailerContentsType?: Maybe<TrailerContentsType>;
  trailerContentsTypeOther?: Maybe<Scalars['String']['output']>;
  trailerDamageTypes: Array<TrailerDamageType>;
  trailerTowingPartyId?: Maybe<Scalars['String']['output']>;
  userDescription?: Maybe<Scalars['String']['output']>;
  /** /////////////////////////////////////////////////\n///////////////////////////////////////////////// */
  vehicle?: Maybe<DomainVehicle>;
  wasInvolvedInAnimalInteraction?: Maybe<Scalars['Boolean']['output']>;
  wasStruckInAnimalInteraction?: Maybe<Scalars['Boolean']['output']>;
};

export type DomainVehicle = {
  __typename?: 'DomainVehicle';
  airbagsDeployed?: Maybe<Scalars['Boolean']['output']>;
  businessOwned?: Maybe<Scalars['Boolean']['output']>;
  color?: Maybe<Scalars['String']['output']>;
  colorPlainText?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  damageMesh?: Maybe<Scalars['String']['output']>;
  detailedDamageRegions: Array<Scalars['String']['output']>;
  deviceUsedToFreeOccupants?: Maybe<Scalars['Boolean']['output']>;
  drivableAfterIncident?: Maybe<Scalars['Boolean']['output']>;
  engineFailsToStartAndRun?: Maybe<Scalars['Boolean']['output']>;
  hasPriorDamage?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  incurringStorageFees?: Maybe<Scalars['Boolean']['output']>;
  interiorDamages: Array<Scalars['String']['output']>;
  licensePlate?: Maybe<Scalars['String']['output']>;
  licensePlateState?: Maybe<Scalars['String']['output']>;
  make?: Maybe<Scalars['String']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  movementState?: Maybe<VehicleMovementState>;
  otherColor?: Maybe<Scalars['String']['output']>;
  partyId?: Maybe<Scalars['String']['output']>;
  priorDamageMesh?: Maybe<Scalars['String']['output']>;
  rolledOver?: Maybe<Scalars['Boolean']['output']>;
  vin?: Maybe<Scalars['String']['output']>;
  year?: Maybe<Scalars['Int']['output']>;
};

export type EmailMessage = {
  __typename?: 'EmailMessage';
  attachments?: Maybe<Array<ConversationMessageAttachment>>;
  bcc?: Maybe<Array<Scalars['String']['output']>>;
  cc?: Maybe<Array<Scalars['String']['output']>>;
  createdAt: Scalars['DateTime']['output'];
  events: Array<EmailMessageEvent>;
  from: Scalars['String']['output'];
  html?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  plainText?: Maybe<Scalars['String']['output']>;
  status: EmailMessageStatusGql;
  subject?: Maybe<Scalars['String']['output']>;
  to?: Maybe<Array<Scalars['String']['output']>>;
};

export type EmailMessageEvent = {
  __typename?: 'EmailMessageEvent';
  createdAt: Scalars['DateTime']['output'];
  errorCode?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  status: EmailMessageStatusGql;
};

export const EmailMessageStatusGql = {
  Delivered: 'DELIVERED',
  Failed: 'FAILED',
  Pending: 'PENDING'
} as const;

export type EmailMessageStatusGql = typeof EmailMessageStatusGql[keyof typeof EmailMessageStatusGql];
/** Contact method gathered by Emma task `Additional Contact Methods` */
export type EmmaGatheredContactMethod = {
  __typename?: 'EmmaGatheredContactMethod';
  destination: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  likelySMSCapable: Scalars['Boolean']['output'];
  type: ContactMethodType;
};

/** Contact methods gathered by Emma task `Additional Contact Methods` */
export type EmmaGatheredContactMethods = {
  __typename?: 'EmmaGatheredContactMethods';
  /** Contact methods gathered by Emma */
  contactMethods: Array<EmmaGatheredContactMethod>;
  emmaTask: EmmaTaskGql;
};

/** Data gathered by Emma tasks */
export type EmmaGatheredData = {
  __typename?: 'EmmaGatheredData';
  additionalContactMethods: Array<EmmaGatheredContactMethods>;
  additionalVehicleImages: Array<EmmaGatheredVehicleImages>;
  additionalWitnesses: Array<EmmaGatheredWitnesses>;
  appointments: Array<EmmaGatheredFollowupAppointment>;
  gpc: Array<EmmaGatheredGpcData>;
  isa: Array<EmmaGatheredGuidedIsaData>;
  vehicleDetails: Array<EmmaGatheredVehicleDetails>;
};

/** Follow-up appointment scheduled by Emma task `Schedule Follow-up Call` */
export type EmmaGatheredFollowupAppointment = {
  __typename?: 'EmmaGatheredFollowupAppointment';
  /** Follow-up appointment scheduled by Emma */
  appointment?: Maybe<AdjusterAppointment>;
  emmaTask: EmmaTaskGql;
};

/** Guided Photo Capture data gathered by Emma task `Guided Photo Capture` */
export type EmmaGatheredGpcData = {
  __typename?: 'EmmaGatheredGPCData';
  emmaTask: EmmaTaskGql;
};

/** Intelligent Service Assignment data gathered by Emma task `Self Service MOI/MOR` */
export type EmmaGatheredGuidedIsaData = {
  __typename?: 'EmmaGatheredGuidedISAData';
  emmaTask: EmmaTaskGql;
};

export const EmmaGatheredVehicleCollectionMethod = {
  Collect: 'COLLECT',
  Confirm: 'CONFIRM'
} as const;

export type EmmaGatheredVehicleCollectionMethod = typeof EmmaGatheredVehicleCollectionMethod[keyof typeof EmmaGatheredVehicleCollectionMethod];
/** Vehicle detail gathered by Emma task `Vehicle Details` */
export type EmmaGatheredVehicleDetail = {
  __typename?: 'EmmaGatheredVehicleDetail';
  /** Whether the detail was to be collected or confirmed */
  collectionMethod: EmmaGatheredVehicleCollectionMethod;
  /** Type of vehicle detail */
  detail: EmmaGatheredVehicleDetailType;
  id: Scalars['ID']['output'];
  /** User-friendly label for the vehicle detail */
  label: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export const EmmaGatheredVehicleDetailType = {
  Color: 'COLOR',
  LicensePlate: 'LICENSE_PLATE',
  LicensePlateState: 'LICENSE_PLATE_STATE',
  Make: 'MAKE',
  Model: 'MODEL',
  Year: 'YEAR'
} as const;

export type EmmaGatheredVehicleDetailType = typeof EmmaGatheredVehicleDetailType[keyof typeof EmmaGatheredVehicleDetailType];
/** Vehicle details gathered by Emma task `Vehicle Details` */
export type EmmaGatheredVehicleDetails = {
  __typename?: 'EmmaGatheredVehicleDetails';
  /** Vehicle details as a list */
  detailItems: Array<EmmaGatheredVehicleDetail>;
  emmaTask: EmmaTaskGql;
  /** Vehicle details as a single DomainVehicle object */
  vehicle: DomainVehicle;
};


/** Vehicle details gathered by Emma task `Vehicle Details` */
export type EmmaGatheredVehicleDetailsDetailItemsArgs = {
  collectionMethod?: InputMaybe<EmmaGatheredVehicleCollectionMethod>;
};

/** Images gathered by Emma task `Additional Vehicle Images` */
export type EmmaGatheredVehicleImages = {
  __typename?: 'EmmaGatheredVehicleImages';
  emmaTask: EmmaTaskGql;
  /** Vehicle images gathered by Emma */
  media: Array<Media>;
  /** Number of images requested */
  requestedImagesCount: Scalars['Int']['output'];
};

/** Contacts gathered by Emma task `Additional Witnesses` */
export type EmmaGatheredWitnesses = {
  __typename?: 'EmmaGatheredWitnesses';
  emmaTask: EmmaTaskGql;
  /** Witnesses gathered by Emma */
  witnesses: Array<DomainContact>;
};

export type EmmaGeneratedTaskSearchError = {
  __typename?: 'EmmaGeneratedTaskSearchError';
  message: Scalars['String']['output'];
};

export type EmmaGeneratedTaskSearchResult = EmmaGeneratedTaskSearchError | EmmaGeneratedTaskSearchResultSuccess;

export type EmmaGeneratedTaskSearchResultSuccess = {
  __typename?: 'EmmaGeneratedTaskSearchResultSuccess';
  tasks?: Maybe<Array<EmmaGeneratedTaskSearchTask>>;
};

export type EmmaGeneratedTaskSearchTask = {
  __typename?: 'EmmaGeneratedTaskSearchTask';
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export const EmmaSupportedEvent = {
  AdditionalWitnessesGatheredByPythonEmma: 'ADDITIONAL_WITNESSES_GATHERED_BY_PYTHON_EMMA',
  AdditionalWitnessesTaskCompletedByPythonEmma: 'ADDITIONAL_WITNESSES_TASK_COMPLETED_BY_PYTHON_EMMA',
  AdditionalWitnessesTaskFailureInPythonEmma: 'ADDITIONAL_WITNESSES_TASK_FAILURE_IN_PYTHON_EMMA',
  AdditionalWitnessesTaskStartedByPythonEmma: 'ADDITIONAL_WITNESSES_TASK_STARTED_BY_PYTHON_EMMA',
  GatherContactMethodsCompleted: 'GATHER_CONTACT_METHODS_COMPLETED',
  GatherContactMethodsFailed: 'GATHER_CONTACT_METHODS_FAILED',
  GatherContactMethodsNewContactInfo: 'GATHER_CONTACT_METHODS_NEW_CONTACT_INFO',
  GatherContactMethodsStarted: 'GATHER_CONTACT_METHODS_STARTED',
  GatherImagesCompleted: 'GATHER_IMAGES_COMPLETED',
  GatherImagesFailed: 'GATHER_IMAGES_FAILED',
  GatherImagesNewImage: 'GATHER_IMAGES_NEW_IMAGE',
  GatherImagesStarted: 'GATHER_IMAGES_STARTED',
  GeneratedTaskCanceled: 'GENERATED_TASK_CANCELED',
  GeneratedTaskCompleted: 'GENERATED_TASK_COMPLETED',
  GeneratedTaskFailed: 'GENERATED_TASK_FAILED',
  GpcCanceled: 'GPC_CANCELED',
  GpcCompleted: 'GPC_COMPLETED',
  GpcCompletionHandlingFailed: 'GPC_COMPLETION_HANDLING_FAILED',
  GpcDeclined: 'GPC_DECLINED',
  GpcExpired: 'GPC_EXPIRED',
  GpcStarted: 'GPC_STARTED',
  IsaRepairIntentCompleted: 'ISA_REPAIR_INTENT_COMPLETED',
  IsaRepairIntentDeclined: 'ISA_REPAIR_INTENT_DECLINED',
  IsaRepairIntentExpired: 'ISA_REPAIR_INTENT_EXPIRED',
  IsaRepairIntentStarted: 'ISA_REPAIR_INTENT_STARTED',
  ScheduleFollowupCallTaskCompletedByPythonEmma: 'SCHEDULE_FOLLOWUP_CALL_TASK_COMPLETED_BY_PYTHON_EMMA',
  ScheduleFollowupCallTaskFailedInPythonEmma: 'SCHEDULE_FOLLOWUP_CALL_TASK_FAILED_IN_PYTHON_EMMA',
  ScheduleFollowupCallTaskStartedByPythonEmma: 'SCHEDULE_FOLLOWUP_CALL_TASK_STARTED_BY_PYTHON_EMMA',
  VehicleDetailsGatheredByPythonEmma: 'VEHICLE_DETAILS_GATHERED_BY_PYTHON_EMMA',
  VehicleDetailsTaskCompletedByPythonEmma: 'VEHICLE_DETAILS_TASK_COMPLETED_BY_PYTHON_EMMA',
  VehicleDetailsTaskFailureInPythonEmma: 'VEHICLE_DETAILS_TASK_FAILURE_IN_PYTHON_EMMA',
  VehicleDetailsTaskStartedByPythonEmma: 'VEHICLE_DETAILS_TASK_STARTED_BY_PYTHON_EMMA'
} as const;

export type EmmaSupportedEvent = typeof EmmaSupportedEvent[keyof typeof EmmaSupportedEvent];
/**
 * A task that has been assigned to Emma for a specific contact (this is an
 * enrichment of the backend `EmmaTask` type, adding `statusDetails`)
 */
export type EmmaTaskGql = {
  __typename?: 'EmmaTaskGQL';
  activityFeedItems: Array<CaseActivityFeedItem>;
  caseContact?: Maybe<CaseContact>;
  /** An ID of the conversation that created the task if applicable */
  conversationId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  /** The adjuster who assigned the task to Emma (will be null if the task was assigned by the system) */
  createdByAdjuster?: Maybe<Auth0User>;
  /** Error message from Emma task */
  error?: Maybe<Scalars['String']['output']>;
  /** State of the associated claim followup */
  followupStateChoice?: Maybe<Scalars['String']['output']>;
  /** Progress of the associated claim followup */
  followupStateProgress?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /**
   * Whether the task is in an active state. (A task is in an active state if its
   * status is not IN_QUEUE and not one of its final statuses, as defined by its
   * handler.) This field can be used in combination with
   * `takesConversationControl` to determine if the task is currently controlling
   * the messaging conversation with the contact.
   */
  isActive: Scalars['Boolean']['output'];
  /** Task-type-specific parameters used to start the task */
  parameters?: Maybe<Scalars['Json']['output']>;
  /** Raw task record data */
  raw?: Maybe<Scalars['Json']['output']>;
  /**
   * (optional) result data viewable in God mode, for troubleshooting purposes
   * @deprecated This field is for logging/troubleshooting, and its shape is subject to change. Using it for purposes other than logging should be avoided.
   */
  resultData?: Maybe<Scalars['Json']['output']>;
  status: EmmaTaskStatus;
  /** Current and task-specific details of the task's status — e.g., 'Sent 34 hours ago. No response.' */
  statusDetails?: Maybe<Scalars['String']['output']>;
  /**
   * Whether the task takes control of the single-contact conversation. Only one
   * conversation-controlling task can be active at a time for a given contact.
   */
  takesConversationControl: Scalars['Boolean']['output'];
  /** Field used to pair the task with frontend components and backend\nhandlers. */
  type: EmmaTaskType;
  updatedAt: Scalars['DateTime']['output'];
};

export type EmmaTaskStats = {
  __typename?: 'EmmaTaskStats';
  alert?: Maybe<Scalars['Int']['output']>;
  canceled?: Maybe<Scalars['Int']['output']>;
  completed?: Maybe<Scalars['Int']['output']>;
  ongoing?: Maybe<Scalars['Int']['output']>;
  upcoming?: Maybe<Scalars['Int']['output']>;
};

/** Status of an Emma task */
export const EmmaTaskStatus = {
  Canceled: 'CANCELED',
  Completed: 'COMPLETED',
  InProgress: 'IN_PROGRESS',
  InQueue: 'IN_QUEUE',
  NeedsAttention: 'NEEDS_ATTENTION',
  Pending: 'PENDING'
} as const;

export type EmmaTaskStatus = typeof EmmaTaskStatus[keyof typeof EmmaTaskStatus];
export const EmmaTaskType = {
  AdditionalWitnesses: 'ADDITIONAL_WITNESSES',
  GatherContactMethods: 'GATHER_CONTACT_METHODS',
  Generated: 'GENERATED',
  GpcDispatch: 'GPC_DISPATCH',
  IsaDispatch: 'ISA_DISPATCH',
  RequestAdditionalVehicleImages: 'REQUEST_ADDITIONAL_VEHICLE_IMAGES',
  ScheduleFollowupCall: 'SCHEDULE_FOLLOWUP_CALL',
  VehicleDetails: 'VEHICLE_DETAILS'
} as const;

export type EmmaTaskType = typeof EmmaTaskType[keyof typeof EmmaTaskType];
export type EmmaTaskTypesAvailableForContactResponse = {
  __typename?: 'EmmaTaskTypesAvailableForContactResponse';
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
  taskTypes?: Maybe<Array<EmmaTaskType>>;
};

export type EmmaTasksAssignedForClaimResponse = {
  __typename?: 'EmmaTasksAssignedForClaimResponse';
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
  tasks?: Maybe<Array<EmmaTaskGql>>;
};

export type EmmaTasksAssignedForContactResponse = {
  __typename?: 'EmmaTasksAssignedForContactResponse';
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
  tasks?: Maybe<Array<EmmaTaskGql>>;
};

/** Create a new catastrophe by importing an NWS feed. */
export type FeedImportInput = {
  feedURL: Scalars['String']['input'];
  filter: Scalars['Boolean']['input'];
};

export type FindExistingWorkflowDraftGqlResponse = {
  __typename?: 'FindExistingWorkflowDraftGQLResponse';
  data?: Maybe<WorkflowId>;
  errors?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export const FloodWaterType = {
  FreshWater: 'FRESH_WATER',
  FreshWaterAndSaltWater: 'FRESH_WATER_AND_SALT_WATER',
  SaltWater: 'SALT_WATER'
} as const;

export type FloodWaterType = typeof FloodWaterType[keyof typeof FloodWaterType];
export const FullCatastropheType = {
  Blizzard: 'BLIZZARD',
  DustStorm: 'DUST_STORM',
  Earthquake: 'EARTHQUAKE',
  Flood: 'FLOOD',
  Hail: 'HAIL',
  Hailstorm: 'HAILSTORM',
  HardFreeze: 'HARD_FREEZE',
  Hurricane: 'HURRICANE',
  IceStorm: 'ICE_STORM',
  LightningStorm: 'LIGHTNING_STORM',
  Tornado: 'TORNADO',
  Wildfire: 'WILDFIRE',
  Windstorm: 'WINDSTORM',
  WinterStorm: 'WINTER_STORM'
} as const;

export type FullCatastropheType = typeof FullCatastropheType[keyof typeof FullCatastropheType];
export const GeneralClassification = {
  Accident: 'ACCIDENT',
  Glass: 'GLASS',
  Other: 'OTHER',
  Theft: 'THEFT'
} as const;

export type GeneralClassification = typeof GeneralClassification[keyof typeof GeneralClassification];
export type GuidedPhotoCaptureSessionDeclinedResponse = {
  __typename?: 'GuidedPhotoCaptureSessionDeclinedResponse';
  declinedAt: Scalars['DateTime']['output'];
  sessionId: Scalars['String']['output'];
};

export type GuidedPhotoCategoryData = {
  __typename?: 'GuidedPhotoCategoryData';
  damageRegion?: Maybe<DamageRegionGql>;
  homeDamagedHouseRoom?: Maybe<HomeHouseRoom>;
  homeDamagedMiscellaneousStructureSubtype?: Maybe<HomeMiscellaneousStructureSubtype>;
  homeDamagedNonHouseStructureSubtype?: Maybe<HomeNonHouseStructureSubtype>;
};

export type GuidedPhotoSessionGql = {
  __typename?: 'GuidedPhotoSessionGQL';
  claimId?: Maybe<Scalars['String']['output']>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  contactId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  invitationStatus?: Maybe<GuidedPhotoSessionInvitationStatusGql>;
  modifiedAt?: Maybe<Scalars['DateTime']['output']>;
  partyId?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  referringWorkflowLink?: Maybe<Scalars['String']['output']>;
  resumeWorkflowOnCompletion?: Maybe<Scalars['Boolean']['output']>;
  token?: Maybe<Scalars['String']['output']>;
};

export const GuidedPhotoSessionInvitationStatusGql = {
  Actv: 'ACTV',
  Canc: 'CANC',
  Cmpl: 'CMPL',
  Expr: 'EXPR'
} as const;

export type GuidedPhotoSessionInvitationStatusGql = typeof GuidedPhotoSessionInvitationStatusGql[keyof typeof GuidedPhotoSessionInvitationStatusGql];
export type GuidedPhotosSessionGql = {
  __typename?: 'GuidedPhotosSessionGQL';
  allowNonstandardVin?: Maybe<Scalars['Boolean']['output']>;
  claimType?: Maybe<ClaimTypeGql>;
  damageRegions?: Maybe<Array<Maybe<DamageRegionGql>>>;
  homeDamagedHouseRooms?: Maybe<Array<Maybe<HomeHouseRoom>>>;
  homeDamagedMiscellaneousStructureSubtypes?: Maybe<Array<Maybe<HomeMiscellaneousStructureSubtype>>>;
  homeDamagedNonHouseStructureSubtypes?: Maybe<Array<Maybe<HomeNonHouseStructureSubtype>>>;
  homeDamagedStructureTypes?: Maybe<Array<Maybe<HomeStructureType>>>;
  id?: Maybe<Scalars['ID']['output']>;
  media?: Maybe<Array<Maybe<Media>>>;
  needsInteriorPhoto?: Maybe<Scalars['Boolean']['output']>;
  nextStepsExpectations?: Maybe<Scalars['String']['output']>;
  session?: Maybe<GuidedPhotoSessionGql>;
  vehicleLabel?: Maybe<Scalars['String']['output']>;
  vehicleType?: Maybe<Scalars['String']['output']>;
};

export const HailSize = {
  Baseball: 'BASEBALL',
  GolfBall: 'GOLF_BALL',
  Grapefruit: 'GRAPEFRUIT',
  Marble: 'MARBLE',
  Pea: 'PEA'
} as const;

export type HailSize = typeof HailSize[keyof typeof HailSize];
export type HealthSearchLocation = {
  __typename?: 'HealthSearchLocation';
  address?: Maybe<Scalars['String']['output']>;
  addressDetails?: Maybe<HealthSearchLocationAddressDetails>;
  locationTypes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  name?: Maybe<Scalars['String']['output']>;
  phoneNumbers?: Maybe<Array<Maybe<HealthSearchLocationPhoneNumber>>>;
  uuid?: Maybe<Scalars['String']['output']>;
};

export type HealthSearchLocationAddressDetails = {
  __typename?: 'HealthSearchLocationAddressDetails';
  city?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type HealthSearchLocationPhoneNumber = {
  __typename?: 'HealthSearchLocationPhoneNumber';
  detail?: Maybe<Scalars['String']['output']>;
  details?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
};

export type HealthSearchProvider = {
  __typename?: 'HealthSearchProvider';
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  locations?: Maybe<Array<Maybe<HealthSearchLocation>>>;
  npi?: Maybe<Scalars['String']['output']>;
  providerTypes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type HighwayDirections = {
  __typename?: 'HighwayDirections';
  directions?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export const HomeHouseRoom = {
  Attic: 'ATTIC',
  Basement: 'BASEMENT',
  Bathroom: 'BATHROOM',
  Bedroom: 'BEDROOM',
  FamilyRoom: 'FAMILY_ROOM',
  Hallway: 'HALLWAY',
  Kitchen: 'KITCHEN',
  LaundryRoom: 'LAUNDRY_ROOM',
  Office: 'OFFICE',
  Other: 'OTHER',
  ReceptionArea: 'RECEPTION_AREA',
  StorageRoom: 'STORAGE_ROOM'
} as const;

export type HomeHouseRoom = typeof HomeHouseRoom[keyof typeof HomeHouseRoom];
export const HomeMiscellaneousStructureSubtype = {
  Deck: 'DECK',
  Garage: 'GARAGE',
  Patio: 'PATIO',
  Porch: 'PORCH'
} as const;

export type HomeMiscellaneousStructureSubtype = typeof HomeMiscellaneousStructureSubtype[keyof typeof HomeMiscellaneousStructureSubtype];
export const HomeNonHouseStructureSubtype = {
  Driveway: 'DRIVEWAY',
  Fence: 'FENCE',
  Gate: 'GATE',
  Guesthouse: 'GUESTHOUSE',
  Other: 'OTHER',
  Pool: 'POOL',
  Shed: 'SHED',
  Wall: 'WALL'
} as const;

export type HomeNonHouseStructureSubtype = typeof HomeNonHouseStructureSubtype[keyof typeof HomeNonHouseStructureSubtype];
export const HomeStructureType = {
  House: 'HOUSE',
  OtherStructure: 'OTHER_STRUCTURE'
} as const;

export type HomeStructureType = typeof HomeStructureType[keyof typeof HomeStructureType];
export type IcdCodePredictionInjuryPayload = {
  data?: InputMaybe<Scalars['Json']['input']>;
  key: Scalars['String']['input'];
};

export const InjurySeverity = {
  Deceased: 'DECEASED',
  EmergencyRoom: 'EMERGENCY_ROOM',
  EmployerTreatedOnsite: 'EMPLOYER_TREATED_ONSITE',
  Hospitalized: 'HOSPITALIZED',
  Mild: 'MILD'
} as const;

export type InjurySeverity = typeof InjurySeverity[keyof typeof InjurySeverity];
export const InsuredType = {
  FirstPartyInsured: 'FIRST_PARTY_INSURED',
  ThirdPartyInsured: 'THIRD_PARTY_INSURED',
  Uninsured: 'UNINSURED'
} as const;

export type InsuredType = typeof InsuredType[keyof typeof InsuredType];
/** Input object for adding a new contact to a case */
export type InvestigationContactInsertionInput = {
  /** ID of a vehicle in the case that the contact is associated with */
  caseVehicleId?: InputMaybe<Scalars['ID']['input']>;
  /** Contact methods for the new contact */
  contactMethodUpsertions?: InputMaybe<Array<ContactMethodUpsertionInput>>;
  /** Free-text annotation for this contact, detailing (for example) their relationship to the claimant. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Investigation options for this contact */
  investigationOptions?: InputMaybe<InvestigationContactInvestigationOptionsInput>;
  messagingServiceEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  /** Ways in which the contact is associated with a vehicle in the case. */
  types: Array<CaseContactType>;
};

/** Input object for creating or updating investigation options for a contact */
export type InvestigationContactInvestigationOptionsInput = {
  /** Reason why the adjuster disabled the investigation for this contact */
  disabledReason?: InputMaybe<InvestigationDisabledReason>;
  /** Whether the case is disabled for this contact. Overrides any contact methods that may be enabled. */
  shouldContact?: InputMaybe<Scalars['Boolean']['input']>;
};

export type InvestigationContactUpdateInput = {
  /** Existing contact methods to update */
  contactMethodUpsertions?: InputMaybe<Array<ContactMethodUpsertionInput>>;
  /** ID of CaseContact to be updated */
  id: Scalars['ID']['input'];
  /** Investigation options for this contact */
  investigationOptions?: InputMaybe<InvestigationContactInvestigationOptionsInput>;
  messagingServiceEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export const InvestigationDisabledReason = {
  AlreadyInvestigated: 'ALREADY_INVESTIGATED',
  AttorneyRepresented: 'ATTORNEY_REPRESENTED',
  CeaseAndDesistLetter: 'CEASE_AND_DESIST_LETTER',
  Deceased: 'DECEASED',
  Minor: 'MINOR',
  NoContactMethodsEnabled: 'NO_CONTACT_METHODS_ENABLED',
  StatedPreference: 'STATED_PREFERENCE',
  UninvolvedParty: 'UNINVOLVED_PARTY'
} as const;

export type InvestigationDisabledReason = typeof InvestigationDisabledReason[keyof typeof InvestigationDisabledReason];
export type InvolvedParty = {
  __typename?: 'InvolvedParty';
  caseVehicleId?: Maybe<Scalars['String']['output']>;
  claimId: Scalars['String']['output'];
  commercialAutoVehicle?: Maybe<CommercialAutoVehicle>;
  id?: Maybe<Scalars['ID']['output']>;
  insuredVehicle?: Maybe<Vehicle>;
  passengers?: Maybe<Array<Maybe<VehicleOccupant>>>;
  rentalIntent?: Maybe<RentalIntent>;
  repairIntent?: Maybe<RepairIntent>;
  transportedConnections: Array<PartyVehicleConnection>;
  transportingConnections: Array<PartyVehicleConnection>;
  vehicleBodyShopLocation?: Maybe<Location>;
  vehicleBodyShopState?: Maybe<Scalars['String']['output']>;
  vehicleRequestedBodyShop?: Maybe<Scalars['Json']['output']>;
};

export type JobSubmissionResponse = {
  __typename?: 'JobSubmissionResponse';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export const Language = {
  Albanian: 'ALBANIAN',
  Arabic: 'ARABIC',
  Armenian: 'ARMENIAN',
  Bengali: 'BENGALI',
  Bosnian: 'BOSNIAN',
  Bulgarian: 'BULGARIAN',
  Cantonese: 'CANTONESE',
  Croation: 'CROATION',
  Czech: 'CZECH',
  Danish: 'DANISH',
  Dutch: 'DUTCH',
  English: 'ENGLISH',
  Finnish: 'FINNISH',
  French: 'FRENCH',
  Gaelic: 'GAELIC',
  German: 'GERMAN',
  Greek: 'GREEK',
  Gujarati: 'GUJARATI',
  Hawaiian: 'HAWAIIAN',
  Hebrew: 'HEBREW',
  Hindi: 'HINDI',
  Hungarian: 'HUNGARIAN',
  Icelandic: 'ICELANDIC',
  Irish: 'IRISH',
  Italian: 'ITALIAN',
  Kannada: 'KANNADA',
  Korean: 'KOREAN',
  Macedonian: 'MACEDONIAN',
  Malayalam: 'MALAYALAM',
  Mandarin: 'MANDARIN',
  Marathi: 'MARATHI',
  Navajo: 'NAVAJO',
  Norwegian: 'NORWEGIAN',
  Other: 'OTHER',
  Persian: 'PERSIAN',
  Polish: 'POLISH',
  Portuguese: 'PORTUGUESE',
  Punjabi: 'PUNJABI',
  Romanian: 'ROMANIAN',
  Russian: 'RUSSIAN',
  Slovak: 'SLOVAK',
  Spanish: 'SPANISH',
  Swedish: 'SWEDISH',
  Tagalog: 'TAGALOG',
  Tamil: 'TAMIL',
  Telugu: 'TELUGU',
  Turkish: 'TURKISH',
  Urdu: 'URDU',
  Vietnamese: 'VIETNAMESE',
  Welsh: 'WELSH',
  Yiddish: 'YIDDISH'
} as const;

export type Language = typeof Language[keyof typeof Language];
export const LanguageCode = {
  Af: 'af',
  Ak: 'ak',
  Am: 'am',
  Ar: 'ar',
  As: 'as',
  Ay: 'ay',
  Az: 'az',
  Be: 'be',
  Bg: 'bg',
  Bho: 'bho',
  Bm: 'bm',
  Bn: 'bn',
  Bs: 'bs',
  Ca: 'ca',
  Ceb: 'ceb',
  Ckb: 'ckb',
  Co: 'co',
  Cs: 'cs',
  Cy: 'cy',
  Da: 'da',
  De: 'de',
  Doi: 'doi',
  Dv: 'dv',
  Ee: 'ee',
  El: 'el',
  En: 'en',
  Eo: 'eo',
  Es: 'es',
  Et: 'et',
  Eu: 'eu',
  Fa: 'fa',
  Fi: 'fi',
  Fil: 'fil',
  Fr: 'fr',
  Fy: 'fy',
  Ga: 'ga',
  Gd: 'gd',
  Gl: 'gl',
  Gn: 'gn',
  Gom: 'gom',
  Gu: 'gu',
  Ha: 'ha',
  Haw: 'haw',
  He: 'he',
  Hi: 'hi',
  Hmn: 'hmn',
  Hr: 'hr',
  Ht: 'ht',
  Hu: 'hu',
  Hy: 'hy',
  Id: 'id',
  Ig: 'ig',
  Ilo: 'ilo',
  Is: 'is',
  It: 'it',
  Iw: 'iw',
  Ja: 'ja',
  Jv: 'jv',
  Jw: 'jw',
  Ka: 'ka',
  Kk: 'kk',
  Km: 'km',
  Kn: 'kn',
  Ko: 'ko',
  Kri: 'kri',
  Ku: 'ku',
  Ky: 'ky',
  La: 'la',
  Lb: 'lb',
  Lg: 'lg',
  Ln: 'ln',
  Lo: 'lo',
  Lt: 'lt',
  Lus: 'lus',
  Lv: 'lv',
  Mai: 'mai',
  Mg: 'mg',
  Mi: 'mi',
  Mk: 'mk',
  Ml: 'ml',
  Mn: 'mn',
  MniMtei: 'mni_Mtei',
  Mr: 'mr',
  Ms: 'ms',
  Mt: 'mt',
  My: 'my',
  Ne: 'ne',
  Nl: 'nl',
  No: 'no',
  Nso: 'nso',
  Ny: 'ny',
  Om: 'om',
  Or: 'or',
  Pa: 'pa',
  Pl: 'pl',
  Ps: 'ps',
  Pt: 'pt',
  Qu: 'qu',
  Ro: 'ro',
  Ru: 'ru',
  Rw: 'rw',
  Sa: 'sa',
  Sd: 'sd',
  Si: 'si',
  Sk: 'sk',
  Sl: 'sl',
  Sm: 'sm',
  Sn: 'sn',
  So: 'so',
  Sq: 'sq',
  Sr: 'sr',
  St: 'st',
  Su: 'su',
  Sv: 'sv',
  Sw: 'sw',
  Ta: 'ta',
  Te: 'te',
  Tg: 'tg',
  Th: 'th',
  Ti: 'ti',
  Tk: 'tk',
  Tl: 'tl',
  Tr: 'tr',
  Ts: 'ts',
  Tt: 'tt',
  Ug: 'ug',
  Uk: 'uk',
  Ur: 'ur',
  Uz: 'uz',
  Vi: 'vi',
  Xh: 'xh',
  Yi: 'yi',
  Yo: 'yo',
  ZhCn: 'zh_CN',
  ZhTw: 'zh_TW',
  Zu: 'zu'
} as const;

export type LanguageCode = typeof LanguageCode[keyof typeof LanguageCode];
export type LinkInvestigationStartResponseData = {
  __typename?: 'LinkInvestigationStartResponseData';
  user?: Maybe<User>;
  workflow?: Maybe<Workflow>;
};

export type LocalShop = {
  __typename?: 'LocalShop';
  availableDateTimes: Array<Scalars['String']['output']>;
  businessName?: Maybe<Scalars['String']['output']>;
  carrierId?: Maybe<Scalars['String']['output']>;
  carrierRepName?: Maybe<Scalars['String']['output']>;
  drivingDistance?: Maybe<ShopDistanceValue>;
  drivingDuration?: Maybe<ShopDistanceValue>;
  id?: Maybe<Scalars['String']['output']>;
  isSWE?: Maybe<Scalars['Boolean']['output']>;
  location?: Maybe<Location>;
  milesAway?: Maybe<Scalars['Float']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
};

export type LocaleData = {
  __typename?: 'LocaleData';
  cityDemographics?: Maybe<Scalars['Json']['output']>;
  crimeRates?: Maybe<Scalars['Json']['output']>;
  location?: Maybe<Scalars['Json']['output']>;
};

export type Location = {
  __typename?: 'Location';
  addressText?: Maybe<Scalars['String']['output']>;
  apartmentNumber?: Maybe<Scalars['String']['output']>;
  businessName?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country: Country;
  latitude?: Maybe<Scalars['Float']['output']>;
  line1?: Maybe<Scalars['String']['output']>;
  line2?: Maybe<Scalars['String']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
};

export type LocationFragmentInput = {
  addressText?: InputMaybe<Scalars['String']['input']>;
  apartmentNumber?: InputMaybe<Scalars['String']['input']>;
  businessName?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  line1?: InputMaybe<Scalars['String']['input']>;
  line2?: InputMaybe<Scalars['String']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
};

export type LocationInput = {
  addressText: Scalars['String']['input'];
  apartmentNumber?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  latitude: Scalars['Float']['input'];
  line1: Scalars['String']['input'];
  line2?: InputMaybe<Scalars['String']['input']>;
  longitude: Scalars['Float']['input'];
  postalCode: Scalars['String']['input'];
  state: Scalars['String']['input'];
};

export type LocationWithPolicy = {
  __typename?: 'LocationWithPolicy';
  isCommercial?: Maybe<Scalars['Boolean']['output']>;
  location?: Maybe<Location>;
  policyNumber?: Maybe<Scalars['String']['output']>;
};

export type Media = {
  __typename?: 'Media';
  categoryData?: Maybe<GuidedPhotoCategoryData>;
  details?: Maybe<Scalars['String']['output']>;
  exifData?: Maybe<Scalars['String']['output']>;
  extractedLocation?: Maybe<Location>;
  feature?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  lastModifiedAt?: Maybe<Scalars['DateTime']['output']>;
  ofVehicleWindow?: Maybe<Scalars['String']['output']>;
  orientation?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export const MediaCategoryGql = {
  Document: 'document',
  Home: 'home',
  Investigation: 'investigation',
  PropertyDamage: 'propertyDamage',
  VehicleDamage: 'vehicleDamage',
  WorkersComp: 'workersComp'
} as const;

export type MediaCategoryGql = typeof MediaCategoryGql[keyof typeof MediaCategoryGql];
export const MediaFeatureGql = {
  AllSides: 'ALL_SIDES',
  AllSidesRaw: 'ALL_SIDES_RAW',
  CloseUpDamage: 'CLOSE_UP_DAMAGE',
  DamageDetailAdditional: 'DAMAGE_DETAIL_ADDITIONAL',
  DamageDetailPrimary: 'DAMAGE_DETAIL_PRIMARY',
  DamageDetailSecondary: 'DAMAGE_DETAIL_SECONDARY',
  DriverLicense: 'DRIVER_LICENSE',
  DriverSide: 'DRIVER_SIDE',
  EmergencyResponseProviderReport: 'EMERGENCY_RESPONSE_PROVIDER_REPORT',
  Equipment: 'EQUIPMENT',
  Front: 'FRONT',
  FrontDriverSide: 'FRONT_DRIVER_SIDE',
  FrontPassengerSide: 'FRONT_PASSENGER_SIDE',
  HealthInsuranceCard: 'HEALTH_INSURANCE_CARD',
  HomeDamage: 'HOME_DAMAGE',
  Injury: 'INJURY',
  InsuranceCard: 'INSURANCE_CARD',
  InteriorFront: 'INTERIOR_FRONT',
  LawsuitDocument: 'LAWSUIT_DOCUMENT',
  LicensePlate: 'LICENSE_PLATE',
  MedicalBill: 'MEDICAL_BILL',
  MediumDamage: 'MEDIUM_DAMAGE',
  Odometer: 'ODOMETER',
  OtherPartyLicensePlate: 'OTHER_PARTY_LICENSE_PLATE',
  PassengerSide: 'PASSENGER_SIDE',
  PropertyAnglePrimary: 'PROPERTY_ANGLE_PRIMARY',
  PropertyAngleSecondary: 'PROPERTY_ANGLE_SECONDARY',
  PropertyCloseUp: 'PROPERTY_CLOSE_UP',
  Rear: 'REAR',
  RearDriverSide: 'REAR_DRIVER_SIDE',
  RearPassengerSide: 'REAR_PASSENGER_SIDE',
  RepairDocument: 'REPAIR_DOCUMENT',
  RunnerInformation: 'RUNNER_INFORMATION',
  Scene: 'SCENE',
  UnspecifiedVehicleFeature: 'UNSPECIFIED_VEHICLE_FEATURE',
  Vin: 'VIN',
  VinWindshield: 'VIN_WINDSHIELD'
} as const;

export type MediaFeatureGql = typeof MediaFeatureGql[keyof typeof MediaFeatureGql];
export const MediaFileNameExtensionGql = {
  FromDisplay: 'fromDisplay',
  FromKey: 'fromKey',
  FromValue: 'fromValue',
  Jpg: 'jpg',
  Mp4: 'mp4',
  Png: 'png',
  ToCustomFieldValues: 'toCustomFieldValues',
  ToDisplays: 'toDisplays',
  ToEnumItems: 'toEnumItems',
  ToExtendableObject: 'toExtendableObject',
  ToKeys: 'toKeys',
  ToOptions: 'toOptions',
  ToValues: 'toValues',
  TryFromCustomField: 'tryFromCustomField',
  TryFromDisplay: 'tryFromDisplay',
  TryFromKey: 'tryFromKey',
  TryFromValue: 'tryFromValue',
  Webm: 'webm'
} as const;

export type MediaFileNameExtensionGql = typeof MediaFileNameExtensionGql[keyof typeof MediaFileNameExtensionGql];
export type MediaId = {
  __typename?: 'MediaId';
  id?: Maybe<Scalars['String']['output']>;
};

export const MediaTypeGql = {
  Image: 'IMAGE',
  Video: 'VIDEO'
} as const;

export type MediaTypeGql = typeof MediaTypeGql[keyof typeof MediaTypeGql];
export type MediaWithLinkGql = {
  __typename?: 'MediaWithLinkGQL';
  category?: Maybe<Scalars['String']['output']>;
  contentLength?: Maybe<Scalars['Int']['output']>;
  details?: Maybe<Scalars['String']['output']>;
  feature?: Maybe<Scalars['String']['output']>;
  filenameExtension?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  link: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
};

export type MessageAttachmentInput = {
  contentType: Scalars['String']['input'];
  filename: Scalars['String']['input'];
  size: Scalars['Float']['input'];
  source: Scalars['String']['input'];
};

export const MethodOfTransportFromScene = {
  Airlift: 'AIRLIFT',
  Ambulance: 'AMBULANCE',
  FriendRelativeVehicle: 'FRIEND_RELATIVE_VEHICLE',
  Other: 'OTHER',
  PersonalVehicle: 'PERSONAL_VEHICLE'
} as const;

export type MethodOfTransportFromScene = typeof MethodOfTransportFromScene[keyof typeof MethodOfTransportFromScene];
export type MunicipalityConfig = {
  __typename?: 'MunicipalityConfig';
  aftermarketParts_showAuthorizeAftermarketParts?: Maybe<MunicipalityConfig_AftermarketParts_ShowAuthorizeAftermarketParts>;
  bodyPartsApproval_showBodyWorkRepairPartsSelection?: Maybe<MunicipalityConfig_BodyPartsApproval_ShowBodyWorkRepairPartsSelection>;
  repairCertification_showRepairPaymentRoutingSelection?: Maybe<MunicipalityConfig_RepairCertification_ShowRepairPaymentRoutingSelection>;
  shopChoice_showShopChoicePreferenceCopyInClaimView?: Maybe<MunicipalityConfig_ShopChoice_ShowShopChoicePreferenceCopyInClaimView>;
};

export type MunicipalityConfig_AftermarketParts_ShowAuthorizeAftermarketParts = {
  __typename?: 'MunicipalityConfig_aftermarketParts_showAuthorizeAftermarketParts';
  state?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Boolean']['output']>;
};

export type MunicipalityConfig_BodyPartsApproval_ShowBodyWorkRepairPartsSelection = {
  __typename?: 'MunicipalityConfig_bodyPartsApproval_showBodyWorkRepairPartsSelection';
  state?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Boolean']['output']>;
};

export type MunicipalityConfig_RepairCertification_ShowRepairPaymentRoutingSelection = {
  __typename?: 'MunicipalityConfig_repairCertification_showRepairPaymentRoutingSelection';
  state?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Boolean']['output']>;
};

export type MunicipalityConfig_ShopChoice_ShowShopChoicePreferenceCopyInClaimView = {
  __typename?: 'MunicipalityConfig_shopChoice_showShopChoicePreferenceCopyInClaimView';
  state?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Boolean']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  activateDemo?: Maybe<UserInvite>;
  addClaimIntakeComment?: Maybe<Claim>;
  addGuidedPhotoCaptureMediaItem?: Maybe<AddGuidedPhotoCaptureMediaItemResponse>;
  addModulesToOutOfBandWorkflowRequest?: Maybe<OutOfBandWorkflowRequestGqlResult>;
  adminUpdateClaim?: Maybe<Claim>;
  archiveSidekickClaim?: Maybe<Claim>;
  /** Assign a task to Emma for a specific case contact */
  assignEmmaTaskForContact?: Maybe<AssignEmmaTaskForContactResponse>;
  /** Assign a list of tasks to Emma for a specific case contact */
  assignEmmaTasksForContact: Array<AssignEmmaTaskForContactResponse>;
  authCodeOauthSession?: Maybe<OAuthSession>;
  authenticateAndStartNewLinkInvestigationResponse?: Maybe<LinkInvestigationStartResponseData>;
  authenticateUserByPhone?: Maybe<User>;
  begin2FacAuth?: Maybe<Begin2FAcAuthResponse>;
  beginSidekickResume2facAuth?: Maybe<Begin2FAcAuthResponse>;
  /** Cancel any active and pending conversation tasks for a specific case contact */
  cancelEmmaConversationTasksForContact?: Maybe<CancelEmmaConversationTasksForContactResponse>;
  changeCatastropheTodo?: Maybe<Catastrophe>;
  continueUserExperienceForCatastrophe?: Maybe<CatastropheUserExperience>;
  continueWorkflow?: Maybe<WorkflowStep>;
  createAdjusterMacro?: Maybe<ConversationMessageMacro>;
  createCATFeed?: Maybe<CatastropheFeed>;
  createCaseContactPhoneCall?: Maybe<CaseContactPhoneCallCreationResult>;
  createCatastrophe?: Maybe<Catastrophe>;
  createCatastropheArea?: Maybe<CatastropheArea>;
  createClaimNote?: Maybe<CreateClaimNoteResultGql>;
  createConversationMessage?: Maybe<Array<ConversationMessage>>;
  createDemo?: Maybe<Demo>;
  /**
   * Create a conversation message by an Emma task. The message can be provided as
   * `plainText` or `richText` (HTML). (`richText` takes precedence.)
   */
  createEmmaConversationMessage?: Maybe<CreateEmmaConversationMessageResult>;
  createInvestigationCase?: Maybe<Case>;
  /** Creates a request for a new out-of-band workflow, associating modules and users */
  createOutOfBandWorkflowRequest?: Maybe<OutOfBandWorkflowRequestGql>;
  declineGuidedPhotoCaptureSession?: Maybe<GuidedPhotoCaptureSessionDeclinedResponse>;
  deleteAdjusterMacro?: Maybe<Scalars['Boolean']['output']>;
  deleteAdminClaimViewUser?: Maybe<AdminClaimViewUserDeleteResult>;
  deleteCATFeed?: Maybe<Scalars['Boolean']['output']>;
  deleteCATPipeline?: Maybe<Scalars['Boolean']['output']>;
  deleteClaimNote?: Maybe<PartialClaimNote>;
  dispatchAdminAlertEmail?: Maybe<SingleClaimDispatchResult>;
  dispatchClaimInProgressReminder?: Maybe<SingleClaimDispatchResult>;
  editAdminClaimViewUser?: Maybe<AdminClaimViewUser>;
  findOrCreateInvestigationCaseForFnolClaim?: Maybe<Case>;
  findWorkflowDraft?: Maybe<FindExistingWorkflowDraftGqlResponse>;
  generateAccessLinks?: Maybe<Array<Maybe<CaseAccessLinkPayload>>>;
  generateConversationPdf?: Maybe<Scalars['String']['output']>;
  generateSingleClaimAdmin?: Maybe<SingleClaimAdminInvite>;
  goBackWorkflow?: Maybe<WorkflowStep>;
  importCATNWSFeed?: Maybe<Scalars['Boolean']['output']>;
  importCATPolicyFile?: Maybe<Scalars['Boolean']['output']>;
  inviteAdminClaimViewUser?: Maybe<AdminClaimViewUser>;
  inviteUser?: Maybe<UserInvite>;
  markConversationMessagesAsRead?: Maybe<Array<ConversationMessage>>;
  navigateWorkflow?: Maybe<WorkflowStep>;
  /** Notify Emma of an event. The event and optional data will be dispatched to task handlers associated with the task's claim. */
  notifyEmma: NotifyEmmaResult;
  orchestrateDemo?: Maybe<Demo>;
  /** Perform an action on a task. Available actions are defined by the task's EmmaTaskProvider */
  performEmmaTaskAction?: Maybe<PerformEmmaTaskActionResponse>;
  /** Submit a job to regenerate a Claim PDF. If the claim belongs to Case, will also regenerate the Case PDF. */
  regenerateClaimPdf?: Maybe<JobSubmissionResponse>;
  resendAdminClaimViewUserInvite?: Maybe<AdminClaimViewUser>;
  resumeSidekickClaimWorkflow?: Maybe<ResumeWorkflowGqlResponse>;
  resumeSidekickClaimWorkflowWith2facAuth?: Maybe<ResumeWorkflowGqlResponse>;
  resumeWorkflowDraft?: Maybe<ResumeWorkflowGqlResponse>;
  /** Re-creates a request for a new out-of-band workflow, associating modules and users */
  retryOutOfBandWorkflowRequest?: Maybe<OutOfBandWorkflowRequestGql>;
  runCATPipeline?: Maybe<Scalars['Boolean']['output']>;
  saveCATTelemetry?: Maybe<Scalars['Json']['output']>;
  saveWorkflowTelemetry?: Maybe<Scalars['Json']['output']>;
  /** Schedule an appointment for an adjuster */
  scheduleAdjusterAppointment?: Maybe<ScheduleAdjusterAppointmentResponse>;
  smsGuidedPhotoCaptureLink?: Maybe<SmsGuidedPhotoCaptureLinkResponse>;
  startCatastropheTodos?: Maybe<CatastropheUserExperience>;
  startNewClaimWorkflow?: Maybe<WorkflowGqlResponse>;
  startNewSidekickClaimWorkflow?: Maybe<WorkflowGqlResponse>;
  submitClaimViewFeedback?: Maybe<ClaimViewFeedbackResponseGql>;
  submitDamageMediaItems?: Maybe<SubmitDamageMediaItemsResponse>;
  /** Replace the adjuster's availability with the provided time slots. Adjacent or overlapping time slots will be merged. */
  updateAdjusterAvailabilitySchedule?: Maybe<UpdateAdjusterAvailabilityResponse>;
  updateAdjusterMacro?: Maybe<ConversationMessageMacro>;
  updateCATFeed?: Maybe<Scalars['Boolean']['output']>;
  updateCatastrophe?: Maybe<Catastrophe>;
  updateCatastropheArea?: Maybe<CatastropheArea>;
  updateClaimExternalDetails?: Maybe<Claim>;
  updateClaimFollowupStatuses?: Maybe<Array<Maybe<ClaimFollowupStatus>>>;
  updateClaimIntakeStatus?: Maybe<Claim>;
  updateClaimNote?: Maybe<ClaimNote>;
  updateConversationEmailPreference?: Maybe<ConversationEmailPreferenceResult>;
  updateConversationTranslation?: Maybe<ConversationResult>;
  updateGodControlsForCase?: Maybe<Case>;
  updateGuidedPhotoCaptureMediaItem?: Maybe<AddGuidedPhotoCaptureMediaItemResponse>;
  updateInvestigation?: Maybe<Case>;
  updateInvestigationCaseStatus?: Maybe<Case>;
  /** @deprecated Use `updateInvestigation` */
  updateInvestigationSettingsForCase?: Maybe<Case>;
  updateOAuthSession?: Maybe<OAuthSession>;
  updateSidekickDigitalRequestsForClaim?: Maybe<Claim>;
  updateSubscriptionToCase?: Maybe<Case>;
  updateUser?: Maybe<User>;
  uploadDocument?: Maybe<DocumentUpload>;
  upsertAdjusterOutOfOfficeSettings?: Maybe<OutOfOfficeSettings>;
  upsertAdjusterOutOfOfficeSettingsByRepId?: Maybe<OutOfOfficeSettings>;
  upsertCATPipeline?: Maybe<UpsertCatastrophePipelineResponse>;
  upsertGlobalMacros?: Maybe<Array<ConversationMessageMacro>>;
};


export type MutationActivateDemoArgs = {
  demoToken: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
};


export type MutationAddClaimIntakeCommentArgs = {
  comment: Scalars['String']['input'];
  id: Scalars['ID']['input'];
};


export type MutationAddGuidedPhotoCaptureMediaItemArgs = {
  input?: InputMaybe<AddGuidedPhotoCaptureMediaItemInput>;
};


export type MutationAddModulesToOutOfBandWorkflowRequestArgs = {
  outOfBandWorkflowRequestId: Scalars['ID']['input'];
  workflowModules: Array<OutOfBandWorkflowRequestModuleInputGql>;
};


export type MutationAdminUpdateClaimArgs = {
  id: Scalars['ID']['input'];
  payload: AdminClaimUpdatePayload;
};


export type MutationArchiveSidekickClaimArgs = {
  id: Scalars['ID']['input'];
};


export type MutationAssignEmmaTaskForContactArgs = {
  input: AssignEmmaTaskForContactInput;
};


export type MutationAssignEmmaTasksForContactArgs = {
  input: Array<AssignEmmaTaskForContactInput>;
};


export type MutationAuthCodeOauthSessionArgs = {
  code: Scalars['String']['input'];
  policyNumber: Scalars['String']['input'];
};


export type MutationAuthenticateAndStartNewLinkInvestigationResponseArgs = {
  investigationToken: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
};


export type MutationAuthenticateUserByPhoneArgs = {
  carrierSessionId?: InputMaybe<Scalars['String']['input']>;
  carrierUserId?: InputMaybe<Scalars['String']['input']>;
  dispatchId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
};


export type MutationBeginSidekickResume2facAuthArgs = {
  claimId: Scalars['ID']['input'];
  phoneNumber: Scalars['String']['input'];
};


export type MutationCancelEmmaConversationTasksForContactArgs = {
  caseContactId: Scalars['ID']['input'];
};


export type MutationChangeCatastropheTodoArgs = {
  catastropheId: Scalars['ID']['input'];
  completedSteps: Array<Scalars['String']['input']>;
};


export type MutationContinueUserExperienceForCatastropheArgs = {
  catastropheId: Scalars['ID']['input'];
  completedSteps: Array<Scalars['String']['input']>;
};


export type MutationContinueWorkflowArgs = {
  assumedCurrentStepKey?: InputMaybe<Scalars['String']['input']>;
  response?: InputMaybe<WorkflowStepResponse>;
  workflow: Scalars['ID']['input'];
};


export type MutationCreateAdjusterMacroArgs = {
  message: Scalars['String']['input'];
  title: Scalars['String']['input'];
};


export type MutationCreateCatFeedArgs = {
  input: CatCreateFeedInput;
};


export type MutationCreateCaseContactPhoneCallArgs = {
  caseContactId: Scalars['ID']['input'];
  details: Scalars['String']['input'];
  reason: CasePhoneCallReasonGql;
};


export type MutationCreateCatastropheArgs = {
  input: CreateCatastropheInput;
};


export type MutationCreateCatastropheAreaArgs = {
  input: CreateCatastropheAreaInput;
};


export type MutationCreateClaimNoteArgs = {
  category: ClaimNoteCategory;
  claimId: Scalars['ID']['input'];
  responses: Array<CreateClaimNoteResponseInput>;
  workflowId: Scalars['ID']['input'];
  workflowStepKey: Scalars['String']['input'];
};


export type MutationCreateConversationMessageArgs = {
  actions?: InputMaybe<Array<ActionTypeGql>>;
  attachments?: InputMaybe<Array<MessageAttachmentInput>>;
  caseContactIds: Array<Scalars['String']['input']>;
  caseId: Scalars['String']['input'];
  channel: ConversationChannelGql;
  richText: Scalars['String']['input'];
  richTextTranslation?: InputMaybe<Scalars['String']['input']>;
  shouldSubscribeSender: Scalars['Boolean']['input'];
  sourceLanguageCode?: InputMaybe<LanguageCode>;
  targetLanguageCode?: InputMaybe<LanguageCode>;
};


export type MutationCreateDemoArgs = {
  createAndJumpIntoCatastrophe?: InputMaybe<AdminDemoCatastropheCreatePayload>;
  expiry?: InputMaybe<Scalars['DateTime']['input']>;
  jumpIntoWorkflow?: InputMaybe<WorkflowType>;
  label: Scalars['String']['input'];
  manuallyOrchestrated?: InputMaybe<Scalars['Boolean']['input']>;
  prefill?: InputMaybe<Scalars['Boolean']['input']>;
  recipients: Array<DemoRecipientInputType>;
  tenant: Tenant;
  type: DemoType;
};


export type MutationCreateEmmaConversationMessageArgs = {
  actions?: InputMaybe<Array<ActionTypeGql>>;
  attachments?: InputMaybe<Array<MessageAttachmentInput>>;
  channel: ConversationChannelGql;
  plainText?: InputMaybe<Scalars['String']['input']>;
  richText?: InputMaybe<Scalars['String']['input']>;
  richTextTranslation?: InputMaybe<Scalars['String']['input']>;
  sourceLanguageCode?: InputMaybe<LanguageCode>;
  targetLanguageCode?: InputMaybe<LanguageCode>;
  taskId: Scalars['ID']['input'];
};


export type MutationCreateInvestigationCaseArgs = {
  externalDocumentBase64?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  subscribers?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type MutationCreateOutOfBandWorkflowRequestArgs = {
  claimId: Scalars['ID']['input'];
  currentStep: Scalars['String']['input'];
  workflowModules: Array<OutOfBandWorkflowRequestModuleInputGql>;
};


export type MutationDeclineGuidedPhotoCaptureSessionArgs = {
  guidedPhotoSessionToken: Scalars['String']['input'];
};


export type MutationDeleteAdjusterMacroArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteAdminClaimViewUserArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteCatFeedArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteCatPipelineArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteClaimNoteArgs = {
  claimNoteId: Scalars['ID']['input'];
};


export type MutationDispatchAdminAlertEmailArgs = {
  claim: Scalars['ID']['input'];
  fromExpiredDraft?: InputMaybe<Scalars['String']['input']>;
};


export type MutationDispatchClaimInProgressReminderArgs = {
  claim: Scalars['ID']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
};


export type MutationEditAdminClaimViewUserArgs = {
  id: Scalars['String']['input'];
  payload: AdminClaimViewUserEditPayload;
};


export type MutationFindOrCreateInvestigationCaseForFnolClaimArgs = {
  assignedTo: Scalars['String']['input'];
  claimId: Scalars['ID']['input'];
  fnolPlus?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationFindWorkflowDraftArgs = {
  type?: WorkflowType;
};


export type MutationGenerateAccessLinksArgs = {
  caseId: Scalars['ID']['input'];
};


export type MutationGenerateConversationPdfArgs = {
  conversationId: Scalars['String']['input'];
  timezone?: InputMaybe<Scalars['String']['input']>;
};


export type MutationGenerateSingleClaimAdminArgs = {
  claim: Scalars['ID']['input'];
};


export type MutationGoBackWorkflowArgs = {
  workflow: Scalars['ID']['input'];
};


export type MutationImportCatnwsFeedArgs = {
  input: FeedImportInput;
};


export type MutationImportCatPolicyFileArgs = {
  input: PolicyFileImportInput;
};


export type MutationInviteAdminClaimViewUserArgs = {
  payload: AdminClaimViewUserInvitePayload;
};


export type MutationInviteUserArgs = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  tenant: Tenant;
};


export type MutationMarkConversationMessagesAsReadArgs = {
  conversationId: Scalars['ID']['input'];
  messageIds: Array<Scalars['String']['input']>;
};


export type MutationNavigateWorkflowArgs = {
  direction?: InputMaybe<Direction>;
  forceStep?: InputMaybe<Scalars['String']['input']>;
  workflow: Scalars['ID']['input'];
};


export type MutationNotifyEmmaArgs = {
  data?: InputMaybe<Scalars['Json']['input']>;
  event: EmmaSupportedEvent;
  taskId: Scalars['ID']['input'];
};


export type MutationOrchestrateDemoArgs = {
  action: DemoOrchestrationActionType;
  id: Scalars['ID']['input'];
};


export type MutationPerformEmmaTaskActionArgs = {
  input: PerformEmmaTaskActionInput;
};


export type MutationRegenerateClaimPdfArgs = {
  claimId: Scalars['ID']['input'];
};


export type MutationResendAdminClaimViewUserInviteArgs = {
  id: Scalars['String']['input'];
};


export type MutationResumeSidekickClaimWorkflowArgs = {
  archiveClaimId?: InputMaybe<Scalars['ID']['input']>;
  draftNumber: Scalars['String']['input'];
};


export type MutationResumeSidekickClaimWorkflowWith2facAuthArgs = {
  authenticationCode: Scalars['String']['input'];
  claimId: Scalars['ID']['input'];
  phoneNumber: Scalars['String']['input'];
};


export type MutationResumeWorkflowDraftArgs = {
  authenticationCode?: InputMaybe<Scalars['String']['input']>;
  draftClaimId: Scalars['ID']['input'];
};


export type MutationRetryOutOfBandWorkflowRequestArgs = {
  outOfBandWorkflowRequestId: Scalars['ID']['input'];
};


export type MutationRunCatPipelineArgs = {
  id: Scalars['ID']['input'];
};


export type MutationSaveCatTelemetryArgs = {
  catastrophe: Scalars['ID']['input'];
  payload: WorkflowTelemetryPayload;
};


export type MutationSaveWorkflowTelemetryArgs = {
  payload: WorkflowTelemetryPayload;
  workflow: Scalars['ID']['input'];
};


export type MutationScheduleAdjusterAppointmentArgs = {
  caseContactId: Scalars['ID']['input'];
  endAt: Scalars['DateTime']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
  startAt: Scalars['DateTime']['input'];
};


export type MutationSmsGuidedPhotoCaptureLinkArgs = {
  guidedPhotoSessionToken: Scalars['String']['input'];
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  sendNow?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationStartCatastropheTodosArgs = {
  catastropheId: Scalars['ID']['input'];
};


export type MutationStartNewClaimWorkflowArgs = {
  authenticatedDeepLinkToken?: InputMaybe<Scalars['String']['input']>;
  carrierDeviceId?: InputMaybe<Scalars['String']['input']>;
  carrierSessionId?: InputMaybe<Scalars['String']['input']>;
  catastropheId?: InputMaybe<Scalars['ID']['input']>;
  catastropheIncidentClaimInvitationToken?: InputMaybe<Scalars['String']['input']>;
  dispatchId?: InputMaybe<Scalars['String']['input']>;
  isCommercialClaim?: InputMaybe<Scalars['Boolean']['input']>;
  isDeepLink?: InputMaybe<Scalars['Boolean']['input']>;
  isDeepLinkNLI?: InputMaybe<Scalars['Boolean']['input']>;
  policyLocation?: InputMaybe<LocationInput>;
  policyNumber?: InputMaybe<Scalars['String']['input']>;
  terminateDraftClaimId?: InputMaybe<Scalars['ID']['input']>;
  type?: WorkflowType;
};


export type MutationStartNewSidekickClaimWorkflowArgs = {
  callerName?: InputMaybe<Scalars['String']['input']>;
  callerPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  policyNumber?: InputMaybe<Scalars['String']['input']>;
  repCode?: InputMaybe<Scalars['String']['input']>;
  routerCallKeyCallDay?: InputMaybe<Scalars['String']['input']>;
  routerCallKeyCallId?: InputMaybe<Scalars['String']['input']>;
  type?: WorkflowType;
};


export type MutationSubmitClaimViewFeedbackArgs = {
  input: ClaimViewFeedbackInput;
};


export type MutationSubmitDamageMediaItemsArgs = {
  category?: InputMaybe<MediaCategoryGql>;
  token: Scalars['ID']['input'];
};


export type MutationUpdateAdjusterAvailabilityScheduleArgs = {
  availability: Array<AdjusterAvailabilityScheduleTimeslotInput>;
  timezone: Scalars['String']['input'];
};


export type MutationUpdateAdjusterMacroArgs = {
  id: Scalars['ID']['input'];
  message: Scalars['String']['input'];
  title: Scalars['String']['input'];
};


export type MutationUpdateCatFeedArgs = {
  input: CatUpdateFeedInput;
};


export type MutationUpdateCatastropheArgs = {
  input: UpdateCatastropheInput;
};


export type MutationUpdateCatastropheAreaArgs = {
  input: UpdateCatastropheAreaInput;
};


export type MutationUpdateClaimExternalDetailsArgs = {
  claimId: Scalars['ID']['input'];
  externalId?: InputMaybe<Scalars['String']['input']>;
  externalTypes: Array<Scalars['String']['input']>;
};


export type MutationUpdateClaimFollowupStatusesArgs = {
  updates: Array<ClaimFollowupStatusUpdateGql>;
};


export type MutationUpdateClaimIntakeStatusArgs = {
  assignedTo?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  status: ClaimIntakeStatus;
};


export type MutationUpdateClaimNoteArgs = {
  category: ClaimNoteCategory;
  claimId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
  responses: Array<ClaimNoteResponseInput>;
  workflowId: Scalars['ID']['input'];
  workflowStepKey: Scalars['String']['input'];
};


export type MutationUpdateConversationEmailPreferenceArgs = {
  email: Scalars['String']['input'];
  subscribe: Scalars['Boolean']['input'];
};


export type MutationUpdateConversationTranslationArgs = {
  input: UpdateConversationTranslationInput;
};


export type MutationUpdateGodControlsForCaseArgs = {
  caseId: Scalars['ID']['input'];
  controls: CaseGodControlsInput;
};


export type MutationUpdateGuidedPhotoCaptureMediaItemArgs = {
  input?: InputMaybe<UpdateGuidedPhotoCaptureMediaItemInput>;
};


export type MutationUpdateInvestigationArgs = {
  input: UpdateInvestigationInput;
};


export type MutationUpdateInvestigationCaseStatusArgs = {
  caseId: Scalars['ID']['input'];
  status: CaseStatus;
};


export type MutationUpdateInvestigationSettingsForCaseArgs = {
  addNewContacts: Array<CaseContactAddNewInput>;
  caseId: Scalars['ID']['input'];
  settings: Array<CaseContactInvestigationSettingsInput>;
};


export type MutationUpdateOAuthSessionArgs = {
  code?: InputMaybe<Scalars['String']['input']>;
  encryptChallenge: Scalars['Boolean']['input'];
  providerName: Scalars['String']['input'];
  sessionId: Scalars['String']['input'];
};


export type MutationUpdateSidekickDigitalRequestsForClaimArgs = {
  digitalRequestItems: Array<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};


export type MutationUpdateSubscriptionToCaseArgs = {
  caseId: Scalars['ID']['input'];
  isSubscribed: Scalars['Boolean']['input'];
};


export type MutationUpdateUserArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUploadDocumentArgs = {
  feature: DocumentFeature;
  source: Scalars['String']['input'];
};


export type MutationUpsertAdjusterOutOfOfficeSettingsArgs = {
  endAt?: InputMaybe<Scalars['String']['input']>;
  forwardToAdjusterRepCode?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  startAt?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpsertAdjusterOutOfOfficeSettingsByRepIdArgs = {
  endAt?: InputMaybe<Scalars['String']['input']>;
  forwardToAdjusterRepCode?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  repId: Scalars['String']['input'];
  startAt?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpsertCatPipelineArgs = {
  input: UpsertCatPipelineInput;
};


export type MutationUpsertGlobalMacrosArgs = {
  input: Array<UpsertGlobalMacroInput>;
};

export type NotFoundError = {
  __typename?: 'NotFoundError';
  /** Add more details for the client */
  message?: Maybe<Scalars['String']['output']>;
};

export type NotifyEmmaResult = {
  __typename?: 'NotifyEmmaResult';
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

export type OAuthSession = {
  __typename?: 'OAuthSession';
  challenge?: Maybe<Scalars['String']['output']>;
  customerId?: Maybe<Scalars['String']['output']>;
  providerName: Scalars['String']['output'];
  sessionId: Scalars['String']['output'];
};

export const ObjectIncidentInteractionType = {
  Initiated: 'INITIATED',
  WasAvoided: 'WAS_AVOIDED',
  WasHit: 'WAS_HIT'
} as const;

export type ObjectIncidentInteractionType = typeof ObjectIncidentInteractionType[keyof typeof ObjectIncidentInteractionType];
export const OtherClassification = {
  Animal: 'ANIMAL',
  DamagedFixedProperty: 'DAMAGED_FIXED_PROPERTY',
  Fell: 'FELL',
  Fire: 'FIRE',
  HitAndRun: 'HIT_AND_RUN',
  HitObjectInRoad: 'HIT_OBJECT_IN_ROAD',
  HitWhileParked: 'HIT_WHILE_PARKED',
  InjuryOnly: 'INJURY_ONLY',
  NoContactCollision: 'NO_CONTACT_COLLISION',
  ObjectFellFromAnotherVehicle: 'OBJECT_FELL_FROM_ANOTHER_VEHICLE',
  Other: 'OTHER',
  PartsStolen: 'PARTS_STOLEN',
  PedestrianOrBicycleCollision: 'PEDESTRIAN_OR_BICYCLE_COLLISION',
  RepossessionWithDamage: 'REPOSSESSION_WITH_DAMAGE',
  Roadside: 'ROADSIDE',
  RodentDamage: 'RODENT_DAMAGE',
  Vandalism: 'VANDALISM',
  VehicleStolen: 'VEHICLE_STOLEN',
  Weather: 'WEATHER'
} as const;

export type OtherClassification = typeof OtherClassification[keyof typeof OtherClassification];
export const OutOfBandWorkflowRequestChannel = {
  Chat: 'CHAT',
  Email: 'EMAIL',
  Sms: 'SMS'
} as const;

export type OutOfBandWorkflowRequestChannel = typeof OutOfBandWorkflowRequestChannel[keyof typeof OutOfBandWorkflowRequestChannel];
export const OutOfBandWorkflowRequestChannelGql = {
  Chat: 'CHAT',
  Email: 'EMAIL',
  Sms: 'SMS'
} as const;

export type OutOfBandWorkflowRequestChannelGql = typeof OutOfBandWorkflowRequestChannelGql[keyof typeof OutOfBandWorkflowRequestChannelGql];
export type OutOfBandWorkflowRequestGql = {
  __typename?: 'OutOfBandWorkflowRequestGQL';
  id: Scalars['String']['output'];
  status: OutOfBandWorkflowRequestStatus;
  workflowModules: Array<OutOfBandWorkflowRequestModuleGql>;
  workflowStepKey: Scalars['String']['output'];
};

export type OutOfBandWorkflowRequestGqlResult = {
  __typename?: 'OutOfBandWorkflowRequestGQLResult';
  completedModules?: Maybe<Array<OutOfBandWorkflowRequestModuleGql>>;
  outOfBandWorkflowRequest?: Maybe<OutOfBandWorkflowRequestGql>;
};

export type OutOfBandWorkflowRequestModuleGql = {
  __typename?: 'OutOfBandWorkflowRequestModuleGQL';
  id: Scalars['String']['output'];
  moduleName: Scalars['String']['output'];
  status: OutOfBandWorkflowRequestStatus;
  updatedAt: Scalars['DateTime']['output'];
  uploadedMedia?: Maybe<Array<MediaWithLinkGql>>;
};

export type OutOfBandWorkflowRequestModuleInputGql = {
  moduleName: Scalars['String']['input'];
  workflowType?: InputMaybe<WorkflowType>;
};

export const OutOfBandWorkflowRequestStatus = {
  Completed: 'COMPLETED',
  Created: 'CREATED',
  Declined: 'DECLINED',
  Failed: 'FAILED',
  Prepared: 'PREPARED',
  Received: 'RECEIVED',
  Sent: 'SENT',
  Started: 'STARTED'
} as const;

export type OutOfBandWorkflowRequestStatus = typeof OutOfBandWorkflowRequestStatus[keyof typeof OutOfBandWorkflowRequestStatus];
export const OutOfBandWorkflowRequestStatusGql = {
  Completed: 'COMPLETED',
  Created: 'CREATED',
  Declined: 'DECLINED',
  Failed: 'FAILED',
  Prepared: 'PREPARED',
  Received: 'RECEIVED',
  Sent: 'SENT',
  Started: 'STARTED'
} as const;

export type OutOfBandWorkflowRequestStatusGql = typeof OutOfBandWorkflowRequestStatusGql[keyof typeof OutOfBandWorkflowRequestStatusGql];
export type OutOfOfficeSettings = {
  __typename?: 'OutOfOfficeSettings';
  createdAt: Scalars['DateTime']['output'];
  endAt?: Maybe<Scalars['DateTime']['output']>;
  forwardToAdjusterRepCode?: Maybe<Scalars['String']['output']>;
  forwardToAdjusterUserId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  message?: Maybe<Scalars['String']['output']>;
  startAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type OutgoingCommunication = {
  __typename?: 'OutgoingCommunication';
  campaign: Scalars['String']['output'];
  communicationType: OutgoingCommunicationType;
  id: Scalars['ID']['output'];
  message: Scalars['String']['output'];
  recipient: Scalars['String']['output'];
  sentAt: Scalars['DateTime']['output'];
};

export const OutgoingCommunicationType = {
  Email: 'EMAIL',
  Robocall: 'ROBOCALL',
  Sms: 'SMS'
} as const;

export type OutgoingCommunicationType = typeof OutgoingCommunicationType[keyof typeof OutgoingCommunicationType];
export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type PartialClaimNote = {
  __typename?: 'PartialClaimNote';
  id?: Maybe<Scalars['String']['output']>;
};

export type PartyVehicleConnection = {
  __typename?: 'PartyVehicleConnection';
  connectionType: PartyVehicleConnectionType;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  transportedInvolvedParty?: Maybe<InvolvedParty>;
  transportedInvolvedPartyId?: Maybe<Scalars['String']['output']>;
  transportingInvolvedParty?: Maybe<InvolvedParty>;
  transportingInvolvedPartyId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export const PartyVehicleConnectionType = {
  Hitched: 'HITCHED',
  Loaded: 'LOADED'
} as const;

export type PartyVehicleConnectionType = typeof PartyVehicleConnectionType[keyof typeof PartyVehicleConnectionType];
export type PerformEmmaTaskActionInput = {
  actionKey: Scalars['String']['input'];
  taskId: Scalars['ID']['input'];
};

export type PerformEmmaTaskActionResponse = {
  __typename?: 'PerformEmmaTaskActionResponse';
  errors?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  success: Scalars['Boolean']['output'];
  task?: Maybe<EmmaTaskGql>;
  tasks?: Maybe<Array<EmmaTaskGql>>;
};

export type PhoneNumberLookup = {
  __typename?: 'PhoneNumberLookup';
  carrierName?: Maybe<Scalars['String']['output']>;
  mobileCountryCode?: Maybe<Scalars['String']['output']>;
  mobileNetworkCode?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  type?: Maybe<PhoneNumberType>;
};

export const PhoneNumberType = {
  Landline: 'landline',
  Mobile: 'mobile',
  Voip: 'voip'
} as const;

export type PhoneNumberType = typeof PhoneNumberType[keyof typeof PhoneNumberType];
export type Policy = {
  __typename?: 'Policy';
  carrierID: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  policyNumber: Scalars['String']['output'];
};

export type PolicyCount = {
  __typename?: 'PolicyCount';
  count?: Maybe<Scalars['Int']['output']>;
  error?: Maybe<Scalars['String']['output']>;
};

/** Count policies within the given GeoJSON area. */
export type PolicyCountInput = {
  /** The polygon area as GeoJSON. */
  geometry: Scalars['Json']['input'];
};

export type PolicyDetails = {
  __typename?: 'PolicyDetails';
  invalidPolicyCount?: Maybe<Scalars['Int']['output']>;
  lastProcessing?: Maybe<Scalars['DateTime']['output']>;
  totalPolicyCount?: Maybe<Scalars['Int']['output']>;
  totalPropertyCount?: Maybe<Scalars['Int']['output']>;
  usersContacted?: Maybe<Scalars['Int']['output']>;
};

/** Import a PolicyFile for CAT. */
export type PolicyFileImportInput = {
  bucketName?: InputMaybe<Scalars['String']['input']>;
  clean: Scalars['Boolean']['input'];
  filter: Scalars['Boolean']['input'];
  inputFile?: InputMaybe<Scalars['String']['input']>;
  objectKey?: InputMaybe<Scalars['String']['input']>;
};

export type PolicyLocation = {
  __typename?: 'PolicyLocation';
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  policyNumber: Scalars['String']['output'];
  postalCode?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
};

export type PolicyLocationCluster = {
  __typename?: 'PolicyLocationCluster';
  count?: Maybe<Scalars['Int']['output']>;
  lat?: Maybe<Scalars['Float']['output']>;
  long?: Maybe<Scalars['Float']['output']>;
};

export type PolicySearchDetailSectionAlertDetailsGql = {
  __typename?: 'PolicySearchDetailSectionAlertDetailsGQL';
  dataId?: Maybe<Scalars['String']['output']>;
  isInvalid?: Maybe<Scalars['Boolean']['output']>;
  items?: Maybe<Array<PolicySearchDetailSectionSubsectionItemGql>>;
  subtitle?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type PolicySearchDetailSectionAlertGql = {
  __typename?: 'PolicySearchDetailSectionAlertGQL';
  alertType: PolicySearchDetailSectionAlertTypeGql;
  details?: Maybe<PolicySearchDetailSectionAlertDetailsGql>;
  id: Scalars['String']['output'];
};

export const PolicySearchDetailSectionAlertTypeGql = {
  DateOfLossOutOfRange: 'DATE_OF_LOSS_OUT_OF_RANGE',
  DuplicateClaimWarning: 'DUPLICATE_CLAIM_WARNING'
} as const;

export type PolicySearchDetailSectionAlertTypeGql = typeof PolicySearchDetailSectionAlertTypeGql[keyof typeof PolicySearchDetailSectionAlertTypeGql];
export const PolicySearchDetailSectionControlGql = {
  Contact: 'CONTACT',
  ThirdPartyContact: 'THIRD_PARTY_CONTACT'
} as const;

export type PolicySearchDetailSectionControlGql = typeof PolicySearchDetailSectionControlGql[keyof typeof PolicySearchDetailSectionControlGql];
export type PolicySearchDetailSectionGql = {
  __typename?: 'PolicySearchDetailSectionGQL';
  alerts?: Maybe<Array<Maybe<PolicySearchDetailSectionAlertGql>>>;
  controls?: Maybe<PolicySearchDetailSectionControlGql>;
  isInvalid?: Maybe<Scalars['Boolean']['output']>;
  isolate?: Maybe<Scalars['Boolean']['output']>;
  sections?: Maybe<Array<PolicySearchDetailSectionSubsectionGql>>;
  title: Scalars['String']['output'];
};

export type PolicySearchDetailSectionSubsectionGql = {
  __typename?: 'PolicySearchDetailSectionSubsectionGQL';
  dataId?: Maybe<Scalars['String']['output']>;
  isInvalid?: Maybe<Scalars['Boolean']['output']>;
  items?: Maybe<Array<PolicySearchDetailSectionSubsectionItemGql>>;
  title: Scalars['String']['output'];
};

export type PolicySearchDetailSectionSubsectionItemGql = {
  __typename?: 'PolicySearchDetailSectionSubsectionItemGQL';
  id: Scalars['String']['output'];
  indentSize?: Maybe<Scalars['String']['output']>;
  isInvalid?: Maybe<Scalars['Boolean']['output']>;
  isVerifiable: Scalars['Boolean']['output'];
  items?: Maybe<Array<Maybe<PolicySearchDetailSectionSubsectionItemGql>>>;
  label: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type PolicySearchInputGql = {
  address?: InputMaybe<Scalars['String']['input']>;
  licenseNumber?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  policyNumber?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  productCode?: InputMaybe<Scalars['String']['input']>;
  stateCode?: InputMaybe<Scalars['String']['input']>;
};

export type PolicySearchResultGql = {
  __typename?: 'PolicySearchResultGQL';
  address: Location;
  dateOfLoss: Scalars['DateTime']['output'];
  effectiveDate: Scalars['DateTime']['output'];
  expirationDate: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  policyNumber: Scalars['String']['output'];
  productCode: Scalars['String']['output'];
};

export type PolicySearchRetrieveDetailGql = {
  __typename?: 'PolicySearchRetrieveDetailGQL';
  detailSections?: Maybe<Array<PolicySearchDetailSectionGql>>;
  policyNumber: Scalars['String']['output'];
  vehicleLabel?: Maybe<Scalars['String']['output']>;
};

export type PolicySearchRetrieveDetailPriorLosses = {
  __typename?: 'PolicySearchRetrieveDetailPriorLosses';
  sections?: Maybe<Array<PolicySearchDetailSectionSubsectionGql>>;
  title: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  GuidedPhotosSession?: Maybe<GuidedPhotosSessionGql>;
  /** Scheduled appointments for an adjuster */
  adjusterAppointments: Array<AdjusterAppointment>;
  /** Future time slots during which the adjuster is available for appointments, omitting times that are already scheduled */
  adjusterAvailability: Array<AdjusterAvailabilityTimeslot>;
  /** Weekly availability schedule for the adjuster */
  adjusterAvailabilitySchedule: Array<AdjusterAvailabilityScheduleTimeslot>;
  adjusterOutOfOfficeSettings?: Maybe<OutOfOfficeSettings>;
  adjusterOutOfOfficeSettingsByRepId?: Maybe<OutOfOfficeSettings>;
  adminCases?: Maybe<Array<Maybe<Case>>>;
  adminCasesCount?: Maybe<Scalars['Int']['output']>;
  adminClaimViewUsers?: Maybe<Array<Maybe<AdminClaimViewUser>>>;
  adminClaims?: Maybe<Array<Maybe<Claim>>>;
  adminClaimsCount?: Maybe<Scalars['Int']['output']>;
  adminTestBenchCreateClaim?: Maybe<AdminTestBenchClaimCreationResult>;
  /** Data for Analytics Dashboard charts */
  analyticsChartData?: Maybe<AnalyticsChartData>;
  autocompleteLocation?: Maybe<Array<Maybe<SmartyAutocompleteResult>>>;
  case?: Maybe<Case>;
  catFeed?: Maybe<Array<Maybe<CatastropheFeed>>>;
  catPipeline?: Maybe<Array<Maybe<CatastrophePipeline>>>;
  catastrophe?: Maybe<Array<Maybe<Catastrophe>>>;
  catastropheAffectedPolicyLocations?: Maybe<Array<Maybe<LocationWithPolicy>>>;
  /** The individual areas that make up a catastrophe. */
  catastropheAreas?: Maybe<Array<Maybe<CatastropheArea>>>;
  catastropheDetails?: Maybe<CatastropheDetails>;
  claim?: Maybe<Claim>;
  claimCase?: Maybe<Case>;
  claimIQ?: Maybe<Scalars['Json']['output']>;
  /**
   * Retrieves a list of all active claim category specifications, which define the
   * field displays for different categories of claim notes.
   */
  claimNoteCategorySpecifications?: Maybe<Array<ClaimNoteCategorySpecificationGql>>;
  claimNotes?: Maybe<Array<Maybe<ClaimNote>>>;
  claimRef?: Maybe<Claim>;
  claimV2?: Maybe<Claim>;
  conversation?: Maybe<ConversationResult>;
  conversationLanguages: Array<ConversationLanguage>;
  conversationMessageSpellcheck: Scalars['String']['output'];
  conversationTimelineSearch?: Maybe<ConversationEventConnection>;
  conversations?: Maybe<ConversationsConnection>;
  demo?: Maybe<Demo>;
  dispatch?: Maybe<Dispatch>;
  domainIncident?: Maybe<DomainIncident>;
  drugInformationByRxcui?: Maybe<Scalars['Json']['output']>;
  emailSubscriptionPreference?: Maybe<ConversationEmailPreferenceResult>;
  emmaGatheredDataFromContact: EmmaGatheredData;
  emmaGeneratedTaskSearch?: Maybe<EmmaGeneratedTaskSearchResult>;
  /** Stats for Emma tasks for a given case that include counts for tasks in each tracked state. */
  emmaTaskStatsForCase?: Maybe<EmmaTaskStats>;
  /**
   * Types of tasks that can be assigned to Emma for a specific claim. E.g., GPC
   * isn't available for contacts who aren't associated with a vehicle.
   */
  emmaTaskTypesAvailableForContact?: Maybe<EmmaTaskTypesAvailableForContactResponse>;
  /**
   * Tasks assigned to Emma for a specific claim
   * @deprecated Use emmaTasksAssignedForContact instead
   */
  emmaTasksAssignedForClaim?: Maybe<EmmaTasksAssignedForClaimResponse>;
  /** Tasks assigned to Emma for a specific contact */
  emmaTasksAssignedForContact?: Maybe<EmmaTasksAssignedForContactResponse>;
  enrichLocation?: Maybe<Location>;
  geocodeIntersection?: Maybe<Location>;
  getSignedUrl?: Maybe<Scalars['String']['output']>;
  guidedPhotoSessionPhoneNumber?: Maybe<Scalars['String']['output']>;
  hydratedMacroGlobalVariables: Array<ConversationMessageMacroHydratedGlobalVariable>;
  icdCodePredictionForInjury?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  lookupAuthenticatedDeepLink?: Maybe<AuthenticatedDeepLinkData>;
  lookupCaseByExternalId?: Maybe<Case>;
  lookupCaseOrClaimByIdentifier?: Maybe<CaseIdOrClaimId>;
  lookupCatastropheIncidentClaimInvitation?: Maybe<CatastropheIncidentClaimInvitation>;
  lookupClaimViewUrl?: Maybe<Scalars['String']['output']>;
  lookupDemo?: Maybe<Demo>;
  lookupPhoneNumbers?: Maybe<Array<Maybe<PhoneNumberLookup>>>;
  macro?: Maybe<ConversationMessageMacro>;
  macroGlobalVariables: Array<ConversationMessageMacroGlobalVariable>;
  macros: Array<ConversationMessageMacro>;
  me?: Maybe<User>;
  municipalityConfig?: Maybe<MunicipalityConfig>;
  outOfBandWorkflowRequestsByClaimId?: Maybe<OutOfBandWorkflowRequestGqlResult>;
  policyCountInArea?: Maybe<PolicyCount>;
  policyDetails?: Maybe<PolicyDetails>;
  policyErrors?: Maybe<Array<Maybe<PolicyLocation>>>;
  policyLocation?: Maybe<Array<Maybe<Coordinate>>>;
  policyLocationClusters?: Maybe<Array<Maybe<PolicyLocationCluster>>>;
  searchConversationMessages: Array<ConversationMessage>;
  searchHealthLocations?: Maybe<Array<Maybe<HealthSearchLocation>>>;
  searchHealthProviders?: Maybe<Array<Maybe<HealthSearchProvider>>>;
  searchReferenceImages?: Maybe<Array<Maybe<ReferenceImage>>>;
  searchShops?: Maybe<SearchShopsResult>;
  searchStateCities?: Maybe<StateCities>;
  searchStateHighways?: Maybe<StateHighways>;
  searchVehicleModels?: Maybe<Array<Maybe<VehicleModel>>>;
  shop?: Maybe<LocalShop>;
  sidekickAvailableDigitalRequestModuleTypes?: Maybe<SidekickAvailableDigitalRequestModuleTypesGql>;
  sidekickClaims?: Maybe<Array<Maybe<Claim>>>;
  sidekickPolicySearch?: Maybe<Array<Maybe<PolicySearchResultGql>>>;
  sidekickPolicySearchRetrieveDetail?: Maybe<PolicySearchRetrieveDetailGql>;
  sidekickPolicySearchRetrieveDetailPriorLosses?: Maybe<PolicySearchRetrieveDetailPriorLosses>;
  sidekickSearchClaims?: Maybe<Array<Maybe<Claim>>>;
  tenantConfig?: Maybe<Scalars['Json']['output']>;
  translate?: Maybe<Translation>;
  userExperienceForCatastrophe?: Maybe<CatastropheUserExperience>;
  validateCatPipeline?: Maybe<ValidateCatastrophePipelineResponse>;
  validateClaimExternalId?: Maybe<ClaimExternalIdValidationResponse>;
  validateRepId?: Maybe<RepIdValidationResponse>;
  vehicle?: Maybe<Vehicle>;
  vehicles?: Maybe<Array<Maybe<Vehicle>>>;
  verifySidekickResume2facAuth?: Maybe<VerifySidekickResume2facAuthResponse>;
  weather?: Maybe<Scalars['Json']['output']>;
  workflowCurrentStep?: Maybe<WorkflowStep>;
};


export type QueryGuidedPhotosSessionArgs = {
  token: Scalars['ID']['input'];
};


export type QueryAdjusterAppointmentsArgs = {
  filter?: InputMaybe<AdjusterAppointmentsFilter>;
};


export type QueryAdjusterAvailabilityArgs = {
  adjusterId?: InputMaybe<Scalars['String']['input']>;
  count: Scalars['Int']['input'];
  duration: Scalars['Int']['input'];
  endAt: Scalars['DateTime']['input'];
  startAt: Scalars['DateTime']['input'];
};


export type QueryAdjusterAvailabilityScheduleArgs = {
  timezone: Scalars['String']['input'];
};


export type QueryAdjusterOutOfOfficeSettingsByRepIdArgs = {
  repId: Scalars['String']['input'];
};


export type QueryAdminCasesArgs = {
  cursor?: InputMaybe<Scalars['ID']['input']>;
  filter: AdminInvestigationCaseFilter;
  filterMode?: InputMaybe<AdminInvestigationCaseFilterMode>;
  filterUser?: InputMaybe<Scalars['String']['input']>;
  limit?: Scalars['Int']['input'];
  sortInfo: SortInfo;
};


export type QueryAdminCasesCountArgs = {
  filter: AdminInvestigationCaseFilter;
  filterMode?: InputMaybe<AdminInvestigationCaseFilterMode>;
  filterUser?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAdminClaimsArgs = {
  cursor?: InputMaybe<Scalars['ID']['input']>;
  filter: AdminIntakeClaimsFilter;
  limit?: Scalars['Int']['input'];
};


export type QueryAdminClaimsCountArgs = {
  filter: AdminIntakeClaimsFilter;
};


export type QueryAdminTestBenchCreateClaimArgs = {
  claim: Scalars['ID']['input'];
  payload: Scalars['String']['input'];
  query?: InputMaybe<AdminTestBenchQueryParams>;
  specificCarrier?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAnalyticsChartDataArgs = {
  input: AnalyticsChartDataInput;
};


export type QueryAutocompleteLocationArgs = {
  prefix: Scalars['String']['input'];
  searchBias?: InputMaybe<LocationFragmentInput>;
  selected?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCaseArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCatastropheArgs = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  updatedSince?: InputMaybe<Scalars['DateTime']['input']>;
};


export type QueryCatastropheAffectedPolicyLocationsArgs = {
  catastropheId: Scalars['ID']['input'];
};


export type QueryCatastropheAreasArgs = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  id: Scalars['ID']['input'];
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};


export type QueryCatastropheDetailsArgs = {
  id: Scalars['ID']['input'];
};


export type QueryClaimArgs = {
  id: Scalars['ID']['input'];
};


export type QueryClaimCaseArgs = {
  claimId: Scalars['ID']['input'];
};


export type QueryClaimIqArgs = {
  id: Scalars['ID']['input'];
};


export type QueryClaimNotesArgs = {
  claimId: Scalars['ID']['input'];
};


export type QueryClaimRefArgs = {
  id: Scalars['ID']['input'];
};


export type QueryClaimV2Args = {
  id: Scalars['ID']['input'];
};


export type QueryConversationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryConversationMessageSpellcheckArgs = {
  text: Scalars['String']['input'];
};


export type QueryConversationTimelineSearchArgs = {
  input: ConversationTimelineSearchInput;
};


export type QueryConversationsArgs = {
  input: ConversationsInput;
};


export type QueryDemoArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDispatchArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDomainIncidentArgs = {
  claimId: Scalars['ID']['input'];
};


export type QueryDrugInformationByRxcuiArgs = {
  rxcui: Scalars['String']['input'];
};


export type QueryEmailSubscriptionPreferenceArgs = {
  email: Scalars['String']['input'];
};


export type QueryEmmaGatheredDataFromContactArgs = {
  caseContactId: Scalars['ID']['input'];
};


export type QueryEmmaGeneratedTaskSearchArgs = {
  caseContactId: Scalars['ID']['input'];
  query: Scalars['String']['input'];
};


export type QueryEmmaTaskStatsForCaseArgs = {
  caseId: Scalars['ID']['input'];
};


export type QueryEmmaTaskTypesAvailableForContactArgs = {
  caseContactId: Scalars['ID']['input'];
};


export type QueryEmmaTasksAssignedForClaimArgs = {
  claimId: Scalars['ID']['input'];
  types?: InputMaybe<Array<EmmaTaskType>>;
};


export type QueryEmmaTasksAssignedForContactArgs = {
  caseContactId: Scalars['ID']['input'];
};


export type QueryEnrichLocationArgs = {
  location: LocationFragmentInput;
};


export type QueryGeocodeIntersectionArgs = {
  city: Scalars['String']['input'];
  state: Scalars['String']['input'];
  street1: Scalars['String']['input'];
  street2: Scalars['String']['input'];
};


export type QueryGetSignedUrlArgs = {
  asImage?: InputMaybe<Scalars['Boolean']['input']>;
  compact?: InputMaybe<Scalars['Boolean']['input']>;
  source: Scalars['String']['input'];
};


export type QueryGuidedPhotoSessionPhoneNumberArgs = {
  claimId: Scalars['ID']['input'];
};


export type QueryHydratedMacroGlobalVariablesArgs = {
  caseContactId: Scalars['String']['input'];
  caseId: Scalars['String']['input'];
};


export type QueryIcdCodePredictionForInjuryArgs = {
  injuries: Array<IcdCodePredictionInjuryPayload>;
  region: Scalars['String']['input'];
};


export type QueryLookupAuthenticatedDeepLinkArgs = {
  token: Scalars['String']['input'];
};


export type QueryLookupCaseByExternalIdArgs = {
  externalId: Scalars['String']['input'];
};


export type QueryLookupCaseOrClaimByIdentifierArgs = {
  identifier: Scalars['String']['input'];
};


export type QueryLookupCatastropheIncidentClaimInvitationArgs = {
  token: Scalars['String']['input'];
};


export type QueryLookupClaimViewUrlArgs = {
  confirmationNumber: Scalars['String']['input'];
  contactExternalId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryLookupDemoArgs = {
  demoToken: Scalars['String']['input'];
};


export type QueryLookupPhoneNumbersArgs = {
  phoneNumbers: Array<Scalars['String']['input']>;
};


export type QueryMacroArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMunicipalityConfigArgs = {
  claimId: Scalars['ID']['input'];
};


export type QueryOutOfBandWorkflowRequestsByClaimIdArgs = {
  claimId: Scalars['ID']['input'];
};


export type QueryPolicyCountInAreaArgs = {
  input: PolicyCountInput;
};


export type QueryPolicyLocationClustersArgs = {
  maxLat: Scalars['Float']['input'];
  maxLong: Scalars['Float']['input'];
  minLat: Scalars['Float']['input'];
  minLong: Scalars['Float']['input'];
  zoom: Scalars['Int']['input'];
};


export type QuerySearchConversationMessagesArgs = {
  caseId: Scalars['ID']['input'];
  query: Scalars['String']['input'];
};


export type QuerySearchHealthLocationsArgs = {
  distance?: InputMaybe<Scalars['Int']['input']>;
  excludedLocationTypes: Array<Scalars['String']['input']>;
  fields: Array<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  locationTypes: Array<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySearchHealthProvidersArgs = {
  distance?: InputMaybe<Scalars['Int']['input']>;
  excludedProviderTypes: Array<Scalars['String']['input']>;
  fields: Array<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  providerTypes: Array<Scalars['String']['input']>;
};


export type QuerySearchReferenceImagesArgs = {
  queryString: Scalars['String']['input'];
};


export type QuerySearchShopsArgs = {
  addressText: Scalars['String']['input'];
};


export type QuerySearchStateCitiesArgs = {
  state: Scalars['String']['input'];
};


export type QuerySearchStateHighwaysArgs = {
  state: Scalars['String']['input'];
};


export type QuerySearchVehicleModelsArgs = {
  make: Scalars['String']['input'];
};


export type QueryShopArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySidekickAvailableDigitalRequestModuleTypesArgs = {
  claimId: Scalars['ID']['input'];
};


export type QuerySidekickClaimsArgs = {
  cursor?: InputMaybe<Scalars['ID']['input']>;
  limit?: Scalars['Int']['input'];
};


export type QuerySidekickPolicySearchArgs = {
  claimId: Scalars['ID']['input'];
  query: PolicySearchInputGql;
};


export type QuerySidekickPolicySearchRetrieveDetailArgs = {
  claimId: Scalars['ID']['input'];
  policyNumber: Scalars['String']['input'];
};


export type QuerySidekickPolicySearchRetrieveDetailPriorLossesArgs = {
  centuryClaimNumbers: Array<Scalars['String']['input']>;
  claimId: Scalars['ID']['input'];
  policyNumber: Scalars['String']['input'];
};


export type QuerySidekickSearchClaimsArgs = {
  query: Scalars['String']['input'];
};


export type QueryTranslateArgs = {
  mimeType: TranslationMimeType;
  sourceLanguageCode?: InputMaybe<LanguageCode>;
  targetLanguageCode: LanguageCode;
  text: Scalars['String']['input'];
};


export type QueryUserExperienceForCatastropheArgs = {
  catastropheId: Scalars['ID']['input'];
};


export type QueryValidateCatPipelineArgs = {
  config: Scalars['String']['input'];
  cronTab: Scalars['String']['input'];
};


export type QueryValidateClaimExternalIdArgs = {
  claimId: Scalars['ID']['input'];
  externalId: Scalars['String']['input'];
};


export type QueryValidateRepIdArgs = {
  repId: Scalars['String']['input'];
};


export type QueryVehicleArgs = {
  id: Scalars['ID']['input'];
};


export type QueryVehiclesArgs = {
  claimId: Scalars['ID']['input'];
};


export type QueryVerifySidekickResume2facAuthArgs = {
  authenticationCode: Scalars['String']['input'];
  claimId: Scalars['ID']['input'];
  phoneNumber: Scalars['String']['input'];
};


export type QueryWeatherArgs = {
  date: Scalars['String']['input'];
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  previousWeatherForecast?: InputMaybe<Scalars['String']['input']>;
};


export type QueryWorkflowCurrentStepArgs = {
  forceStep?: InputMaybe<Scalars['String']['input']>;
  workflow: Scalars['ID']['input'];
};

export type ReferenceImage = {
  __typename?: 'ReferenceImage';
  link?: Maybe<Scalars['String']['output']>;
};

export const RelationshipToParty = {
  Adjuster: 'Adjuster',
  AdminOfEstate: 'AdminOfEstate',
  Agent: 'Agent',
  Attorney: 'Attorney',
  BusinessRepresentative: 'BusinessRepresentative',
  Carrier: 'Carrier',
  Driver: 'Driver',
  Employee: 'Employee',
  Friend: 'Friend',
  InjuredPerson: 'InjuredPerson',
  Lienholder: 'Lienholder',
  MedProvider: 'MedProvider',
  MedicalProvider: 'MedicalProvider',
  MemberOfInsuredHousehold: 'MemberOfInsuredHousehold',
  Other: 'Other',
  Owner: 'Owner',
  ParentGuardian: 'ParentGuardian',
  Passenger: 'Passenger',
  Pedestrian: 'Pedestrian',
  PoliceOfficer: 'PoliceOfficer',
  PolicyHolder: 'PolicyHolder',
  PropertyManager: 'PropertyManager',
  PublicAdjuster: 'PublicAdjuster',
  Relative: 'Relative',
  RentalCompany: 'RentalCompany',
  RentalRepresentative: 'RentalRepresentative',
  RepairShop: 'RepairShop',
  Spouse: 'Spouse',
  Supervisor: 'Supervisor',
  Surrogate: 'Surrogate',
  ThirdPartyAdmin: 'ThirdPartyAdmin',
  Witness: 'Witness'
} as const;

export type RelationshipToParty = typeof RelationshipToParty[keyof typeof RelationshipToParty];
export const RentalIntent = {
  AcceptedCovered: 'ACCEPTED_COVERED',
  AcceptedNotCovered: 'ACCEPTED_NOT_COVERED',
  DeclinedCovered: 'DECLINED_COVERED',
  DeclinedNotCovered: 'DECLINED_NOT_COVERED'
} as const;

export type RentalIntent = typeof RentalIntent[keyof typeof RentalIntent];
export type RentalReservation = {
  __typename?: 'RentalReservation';
  caseId?: Maybe<Scalars['String']['output']>;
  caseVehicleId?: Maybe<Scalars['String']['output']>;
  domainVehicleId?: Maybe<Scalars['String']['output']>;
  reservationNumber?: Maybe<Scalars['String']['output']>;
  vehicleId?: Maybe<Scalars['String']['output']>;
};

export type RepIdValidationResponse = {
  __typename?: 'RepIdValidationResponse';
  valid: Scalars['Boolean']['output'];
};

export const RepairIntent = {
  CarrierCoordinated: 'CARRIER_COORDINATED',
  CustomerCoordinated: 'CUSTOMER_COORDINATED',
  Payout: 'PAYOUT',
  Undecided: 'UNDECIDED'
} as const;

export type RepairIntent = typeof RepairIntent[keyof typeof RepairIntent];
export type ResumeWorkflow = {
  __typename?: 'ResumeWorkflow';
  claimId?: Maybe<Scalars['String']['output']>;
  workflowId?: Maybe<Scalars['String']['output']>;
};

export type ResumeWorkflowGqlResponse = {
  __typename?: 'ResumeWorkflowGQLResponse';
  data?: Maybe<ResumeWorkflow>;
  errors?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type SmsGuidedPhotoCaptureLinkResponse = {
  __typename?: 'SMSGuidedPhotoCaptureLinkResponse';
  message?: Maybe<Scalars['String']['output']>;
};

/** Response to the scheduleAdjusterAppointment mutation */
export type ScheduleAdjusterAppointmentResponse = {
  __typename?: 'ScheduleAdjusterAppointmentResponse';
  /** The appointment that was scheduled */
  appointment?: Maybe<AdjusterAppointment>;
  /** Error that occurred while attempting to process the request */
  error?: Maybe<Scalars['String']['output']>;
};

export type SearchLocation = {
  __typename?: 'SearchLocation';
  addressText?: Maybe<Scalars['String']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
};

export type SearchShopsResult = {
  __typename?: 'SearchShopsResult';
  searchLocation: SearchLocation;
  shopResults: Array<LocalShop>;
};

export type ShopDistanceValue = {
  __typename?: 'ShopDistanceValue';
  text?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Int']['output']>;
};

export type SidekickAvailableDigitalRequestModuleTypeGql = {
  __typename?: 'SidekickAvailableDigitalRequestModuleTypeGQL';
  moduleName: Scalars['String']['output'];
  reasonWhyUnavailable?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type SidekickAvailableDigitalRequestModuleTypesGql = {
  __typename?: 'SidekickAvailableDigitalRequestModuleTypesGQL';
  digitalRequests?: Maybe<Array<SidekickAvailableDigitalRequestModuleTypeGql>>;
};

export type SingleClaimAdminInvite = {
  __typename?: 'SingleClaimAdminInvite';
  claimId?: Maybe<Scalars['String']['output']>;
  claimViewUrl?: Maybe<Scalars['String']['output']>;
};

export type SingleClaimDispatchResult = {
  __typename?: 'SingleClaimDispatchResult';
  claimId?: Maybe<Scalars['String']['output']>;
};

export const SinglePartyCollisionType = {
  AnotherVehicle: 'ANOTHER_VEHICLE',
  BuildingStructure: 'BUILDING_STRUCTURE',
  DroveOffRoadway: 'DROVE_OFF_ROADWAY',
  Guardrail: 'GUARDRAIL',
  Other: 'OTHER',
  Pole: 'POLE',
  Tree: 'TREE'
} as const;

export type SinglePartyCollisionType = typeof SinglePartyCollisionType[keyof typeof SinglePartyCollisionType];
export const SiteCleanupType = {
  Cargo: 'CARGO',
  Debris: 'DEBRIS',
  Fuel: 'FUEL',
  HazardousMaterial: 'HAZARDOUS_MATERIAL',
  Other: 'OTHER',
  RoadRepair: 'ROAD_REPAIR'
} as const;

export type SiteCleanupType = typeof SiteCleanupType[keyof typeof SiteCleanupType];
export type SmartyAutocompleteResult = {
  __typename?: 'SmartyAutocompleteResult';
  city: Scalars['String']['output'];
  entries: Scalars['Int']['output'];
  secondary?: Maybe<Scalars['String']['output']>;
  state: Scalars['String']['output'];
  streetLine: Scalars['String']['output'];
  zipcode: Scalars['String']['output'];
};

export type SmsMessage = {
  __typename?: 'SmsMessage';
  content?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  events: Array<SmsMessageEvent>;
  from: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  status: SmsMessageStatusGql;
  to?: Maybe<Array<Scalars['String']['output']>>;
};

export type SmsMessageEvent = {
  __typename?: 'SmsMessageEvent';
  createdAt: Scalars['DateTime']['output'];
  errorCode?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  status: SmsMessageStatusGql;
};

export const SmsMessageStatusGql = {
  Delivered: 'DELIVERED',
  Failed: 'FAILED',
  Pending: 'PENDING'
} as const;

export type SmsMessageStatusGql = typeof SmsMessageStatusGql[keyof typeof SmsMessageStatusGql];
export type SocialProfiles = {
  __typename?: 'SocialProfiles';
  network?: Maybe<Scalars['String']['output']>;
  socialProfileId?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export const SortDirInput = {
  Asc: 'asc',
  Desc: 'desc'
} as const;

export type SortDirInput = typeof SortDirInput[keyof typeof SortDirInput];
export type SortInfo = {
  sortDir: SortDirInput;
  sortField: CaseOrderByInput;
};

export type StateCities = {
  __typename?: 'StateCities';
  cities?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type StateHighway = {
  __typename?: 'StateHighway';
  directions?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type StateHighways = {
  __typename?: 'StateHighways';
  highways?: Maybe<Array<Maybe<StateHighway>>>;
};

export type SubmitDamageMediaItemsResponse = {
  __typename?: 'SubmitDamageMediaItemsResponse';
  errors?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  conversationCreated?: Maybe<Conversation>;
  conversationEventCreated?: Maybe<ConversationEvent>;
  conversationMessageCreated?: Maybe<ConversationMessage>;
  /** Subscribes to status updates for out of band workflow request modules */
  outOfBandWorkflowModuleStatusUpdated?: Maybe<OutOfBandWorkflowRequestGql>;
};


export type SubscriptionConversationCreatedArgs = {
  caseId: Scalars['ID']['input'];
};


export type SubscriptionConversationEventCreatedArgs = {
  caseId: Scalars['ID']['input'];
};


export type SubscriptionConversationMessageCreatedArgs = {
  caseId: Scalars['ID']['input'];
};


export type SubscriptionOutOfBandWorkflowModuleStatusUpdatedArgs = {
  outOfBandWorkflowRequestId?: InputMaybe<Scalars['ID']['input']>;
};

export type SurrogateForFilingParty = {
  __typename?: 'SurrogateForFilingParty';
  claim?: Maybe<Claim>;
  claimId?: Maybe<Scalars['String']['output']>;
  company?: Maybe<Scalars['String']['output']>;
  emailAddress?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  referenceNumber?: Maybe<Scalars['String']['output']>;
  relationToClaimant?: Maybe<SurrogateRelation>;
  relationToClaimantOther?: Maybe<Scalars['String']['output']>;
  workflowState?: Maybe<Scalars['Json']['output']>;
};

export const SurrogateRelation = {
  Adjuster: 'ADJUSTER',
  Agent: 'AGENT',
  Attorney: 'ATTORNEY',
  Employee: 'EMPLOYEE',
  MedicalProvider: 'MEDICAL_PROVIDER',
  Other: 'OTHER',
  Owner: 'OWNER',
  Police: 'POLICE',
  Relative: 'RELATIVE',
  RentalRepresentative: 'RENTAL_REPRESENTATIVE',
  RepairShop: 'REPAIR_SHOP',
  Spouse: 'SPOUSE',
  Witness: 'WITNESS'
} as const;

export type SurrogateRelation = typeof SurrogateRelation[keyof typeof SurrogateRelation];
export const Tenant = {
  Allianz: 'allianz',
  Allstate: 'allstate',
  Americanmodern: 'americanmodern',
  Americannational: 'americannational',
  Amfam: 'amfam',
  Amtrust: 'amtrust',
  Assured: 'assured',
  Autoclubgroup: 'autoclubgroup',
  Autoowners: 'autoowners',
  Chubb: 'chubb',
  Cinfin: 'cinfin',
  Countryfinancial: 'countryfinancial',
  Crawford: 'crawford',
  Csaa: 'csaa',
  Donegal: 'donegal',
  Farmers: 'farmers',
  Geico: 'geico',
  Hanover: 'hanover',
  Hiroad: 'hiroad',
  Homesite: 'homesite',
  Kemper: 'kemper',
  Liberty: 'liberty',
  Libertymutual: 'libertymutual',
  Mainstreetamerica: 'mainstreetamerica',
  Mapfre: 'mapfre',
  Meemic: 'meemic',
  Mercury: 'mercury',
  Nationalgeneral: 'nationalgeneral',
  Nycm: 'nycm',
  Plymouthrock: 'plymouthrock',
  Progressive: 'progressive',
  Safeauto: 'safeauto',
  Safety: 'safety',
  Selective: 'selective',
  Sentry: 'sentry',
  Shelter: 'shelter',
  Statefarm: 'statefarm',
  Tesla: 'tesla',
  Thegeneral: 'thegeneral',
  Thehartford: 'thehartford',
  Tokiomarineamerica: 'tokiomarineamerica',
  Travelers: 'travelers',
  Txfb: 'txfb',
  Usaa: 'usaa',
  Utica: 'utica',
  Vtm: 'vtm',
  Westbend: 'westbend',
  Westfield: 'westfield'
} as const;

export type Tenant = typeof Tenant[keyof typeof Tenant];
export const TheftType = {
  Belongings: 'BELONGINGS',
  Parts: 'PARTS',
  Vehicle: 'VEHICLE'
} as const;

export type TheftType = typeof TheftType[keyof typeof TheftType];
export type TotalLossScore = {
  __typename?: 'TotalLossScore';
  claimId: Scalars['String']['output'];
  domainVehicleId?: Maybe<Scalars['String']['output']>;
  isTotalLoss: Scalars['Boolean']['output'];
  vehicleId?: Maybe<Scalars['String']['output']>;
};

export const TrailerContentsType = {
  BoatOrWatercraft: 'BOAT_OR_WATERCRAFT',
  Empty: 'EMPTY',
  Livestock: 'LIVESTOCK',
  NonPerishableGoods: 'NON_PERISHABLE_GOODS',
  OffRoadVehicle: 'OFF_ROAD_VEHICLE',
  Other: 'OTHER',
  PerishableGoods: 'PERISHABLE_GOODS'
} as const;

export type TrailerContentsType = typeof TrailerContentsType[keyof typeof TrailerContentsType];
export const TrailerDamageType = {
  TowingVehicle: 'TOWING_VEHICLE',
  Trailer: 'TRAILER',
  TrailerContents: 'TRAILER_CONTENTS'
} as const;

export type TrailerDamageType = typeof TrailerDamageType[keyof typeof TrailerDamageType];
export type Translation = {
  __typename?: 'Translation';
  detectedLanguageCode?: Maybe<LanguageCode>;
  disclaimer: Scalars['String']['output'];
  translatedDisclaimer: Scalars['String']['output'];
  translatedText: Scalars['String']['output'];
};

export const TranslationMimeType = {
  Html: 'HTML',
  PlainText: 'PLAIN_TEXT'
} as const;

export type TranslationMimeType = typeof TranslationMimeType[keyof typeof TranslationMimeType];
/** Response to the updateAdjusterAvailability mutation */
export type UpdateAdjusterAvailabilityResponse = {
  __typename?: 'UpdateAdjusterAvailabilityResponse';
  /** The updated availability time slots. If an error occurs, the original availability will be returned */
  availability: Array<AdjusterAvailabilityScheduleTimeslot>;
  /** Error that occurred while attempting to process the request */
  error?: Maybe<Scalars['String']['output']>;
};

/** Update the provided fields of the catastrophe area with the given id. */
export type UpdateCatastropheAreaInput = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  catastropheId?: InputMaybe<Scalars['String']['input']>;
  draft?: InputMaybe<Scalars['Boolean']['input']>;
  end?: InputMaybe<Scalars['DateTime']['input']>;
  id: Scalars['ID']['input'];
  phase?: InputMaybe<CatastrophePhase>;
  severity?: InputMaybe<CatastropheSeverity>;
  start?: InputMaybe<Scalars['DateTime']['input']>;
  type?: InputMaybe<CatType>;
};

/** Update the provided fields of the catastrophe with the given id. */
export type UpdateCatastropheInput = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  areas?: InputMaybe<Array<InputMaybe<UpdateCatastropheAreaInput>>>;
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateConversationTranslationInput = {
  autoTranslate?: InputMaybe<Scalars['Boolean']['input']>;
  conversationId: Scalars['ID']['input'];
  translationLanguageCode?: InputMaybe<LanguageCode>;
};

export type UpdateGuidedPhotoCaptureMediaItemInput = {
  categoryData?: InputMaybe<Scalars['String']['input']>;
  claimType?: InputMaybe<ClaimTypeGql>;
  contactId?: InputMaybe<Scalars['String']['input']>;
  details?: InputMaybe<Scalars['String']['input']>;
  feature: MediaFeatureGql;
  lat?: InputMaybe<Scalars['Float']['input']>;
  long?: InputMaybe<Scalars['Float']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['ID']['input'];
  type?: InputMaybe<MediaTypeGql>;
};

/** Input object for updating the settings of an investigation */
export type UpdateInvestigationInput = {
  /** ID of the Case (investigation) to update */
  caseId: Scalars['ID']['input'];
  /** Contacts to add to the case */
  contactInsertions?: InputMaybe<Array<InvestigationContactInsertionInput>>;
  /** Updates to contacts already assigned to the case */
  contactUpdates?: InputMaybe<Array<InvestigationContactUpdateInput>>;
};

/** Create/Update a CAT Pipeline. */
export type UpsertCatPipelineInput = {
  config: Scalars['String']['input'];
  cronTab: Scalars['String']['input'];
  enabled: Scalars['Boolean']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
};

/** Response when upserting a catastrophe pipeline */
export type UpsertCatastrophePipelineResponse = {
  __typename?: 'UpsertCatastrophePipelineResponse';
  error?: Maybe<Scalars['String']['output']>;
  pipeline?: Maybe<CatastrophePipeline>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type UpsertGlobalMacroInput = {
  actions?: InputMaybe<Array<ActionTypeGql>>;
  message: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type User = {
  __typename?: 'User';
  agent?: Maybe<Agent>;
  demo?: Maybe<Demo>;
  email: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  magicToken?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  tenant: Tenant;
  tenantDisplayName?: Maybe<Scalars['String']['output']>;
};

export type UserInvite = {
  __typename?: 'UserInvite';
  id?: Maybe<Scalars['String']['output']>;
  magicToken?: Maybe<Scalars['String']['output']>;
};

/** Response when validating a catastrophe pipeline */
export type ValidateCatastrophePipelineResponse = {
  __typename?: 'ValidateCatastrophePipelineResponse';
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export const VandalismType = {
  InteriorDamage: 'INTERIOR_DAMAGE',
  ObjectOrSubstanceInGasTank: 'OBJECT_OR_SUBSTANCE_IN_GAS_TANK',
  Other: 'OTHER',
  ScratchedOrKeyed: 'SCRATCHED_OR_KEYED',
  SlashedOrStolenTires: 'SLASHED_OR_STOLEN_TIRES',
  StruckByThrownObject: 'STRUCK_BY_THROWN_OBJECT',
  SugarInGasTank: 'SUGAR_IN_GAS_TANK'
} as const;

export type VandalismType = typeof VandalismType[keyof typeof VandalismType];
export type Vehicle = {
  __typename?: 'Vehicle';
  color?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  licensePlate?: Maybe<Scalars['String']['output']>;
  licensePlateState?: Maybe<Scalars['String']['output']>;
  make?: Maybe<Scalars['String']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  numSeats?: Maybe<Scalars['Int']['output']>;
  otherColor?: Maybe<Scalars['String']['output']>;
  referenceImages?: Maybe<Array<Maybe<VehicleReferenceImage>>>;
  type?: Maybe<VehicleType>;
  vin?: Maybe<Scalars['String']['output']>;
  year?: Maybe<Scalars['Int']['output']>;
};

export type VehicleModel = {
  __typename?: 'VehicleModel';
  make?: Maybe<Scalars['String']['output']>;
  model?: Maybe<Scalars['String']['output']>;
};

export const VehicleMovementState = {
  Driving: 'DRIVING',
  Parked: 'PARKED'
} as const;

export type VehicleMovementState = typeof VehicleMovementState[keyof typeof VehicleMovementState];
export type VehicleOccupant = {
  __typename?: 'VehicleOccupant';
  claim?: Maybe<Claim>;
  commercialAutoParty?: Maybe<CommercialAutoParty>;
  contactAddress?: Maybe<Location>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  seat?: Maybe<VehicleOccupantSeat>;
};

export const VehicleOccupantSeat = {
  Driver: 'DRIVER',
  Other: 'OTHER',
  Passenger: 'PASSENGER',
  SecondRowDriverSide: 'SECOND_ROW_DRIVER_SIDE',
  SecondRowMiddle: 'SECOND_ROW_MIDDLE',
  SecondRowPassengerSide: 'SECOND_ROW_PASSENGER_SIDE',
  ThirdRowDriverSide: 'THIRD_ROW_DRIVER_SIDE',
  ThirdRowMiddle: 'THIRD_ROW_MIDDLE',
  ThirdRowPassengerSide: 'THIRD_ROW_PASSENGER_SIDE',
  Unknown: 'UNKNOWN'
} as const;

export type VehicleOccupantSeat = typeof VehicleOccupantSeat[keyof typeof VehicleOccupantSeat];
export type VehicleReferenceImage = {
  __typename?: 'VehicleReferenceImage';
  link?: Maybe<Scalars['String']['output']>;
};

export const VehicleType = {
  BoxTruck: 'BOX_TRUCK',
  Car: 'CAR',
  Motorcycle: 'MOTORCYCLE',
  Other: 'OTHER',
  PickupTruck: 'PICKUP_TRUCK',
  Sedan: 'SEDAN',
  SemiTruck: 'SEMI_TRUCK',
  Suv: 'SUV',
  Tesla: 'TESLA',
  Unknown: 'UNKNOWN'
} as const;

export type VehicleType = typeof VehicleType[keyof typeof VehicleType];
export type VerifySidekickResume2facAuthResponse = {
  __typename?: 'VerifySidekickResume2facAuthResponse';
  errors?: Maybe<Scalars['Json']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export const WhoPackagedCargo = {
  Carrier: 'CARRIER',
  DontKnow: 'DONT_KNOW',
  Manufacturer: 'MANUFACTURER',
  Shipper: 'SHIPPER'
} as const;

export type WhoPackagedCargo = typeof WhoPackagedCargo[keyof typeof WhoPackagedCargo];
export const WindIncidentType = {
  DebrisOrBlowingObject: 'DEBRIS_OR_BLOWING_OBJECT',
  EntireTreeFell: 'ENTIRE_TREE_FELL',
  Other: 'OTHER',
  TreeLimbFell: 'TREE_LIMB_FELL'
} as const;

export type WindIncidentType = typeof WindIncidentType[keyof typeof WindIncidentType];
export type Workflow = {
  __typename?: 'Workflow';
  claim: Claim;
  completedSteps?: Maybe<Array<Maybe<WorkflowStep>>>;
  currentStep?: Maybe<WorkflowStep>;
  digitalRequestItems: Array<Scalars['String']['output']>;
  fulfilledDigitalRequestItems: Array<Scalars['String']['output']>;
  history: Array<WorkflowHistoryItem>;
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  workflowChannel: WorkflowChannel;
  workflowType: WorkflowType;
};

export const WorkflowChannel = {
  SelfService: 'self_service',
  Sidekick: 'sidekick'
} as const;

export type WorkflowChannel = typeof WorkflowChannel[keyof typeof WorkflowChannel];
export type WorkflowGqlResponse = {
  __typename?: 'WorkflowGQLResponse';
  data?: Maybe<Workflow>;
  errors?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type WorkflowHistoryComponent = {
  __typename?: 'WorkflowHistoryComponent';
  prompt?: Maybe<Scalars['String']['output']>;
  response?: Maybe<Scalars['Json']['output']>;
};

export type WorkflowHistoryItem = {
  __typename?: 'WorkflowHistoryItem';
  components?: Maybe<Array<WorkflowHistoryComponent>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  status?: Maybe<WorkflowStepStatus>;
  submittedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type WorkflowId = {
  __typename?: 'WorkflowId';
  claimId?: Maybe<Scalars['String']['output']>;
  requires2Fac?: Maybe<Scalars['Boolean']['output']>;
};

export type WorkflowStep = {
  __typename?: 'WorkflowStep';
  activeStepKeys?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  content: Scalars['Json']['output'];
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  notifications: Array<WorkflowStepNotification>;
  stepIndex: Scalars['Int']['output'];
  totalSteps: Scalars['Int']['output'];
  updatedSearchDocuments?: Maybe<Array<ClaimWorkflowSearchResultGql>>;
  workflow: Workflow;
};

export type WorkflowStepNotification = {
  __typename?: 'WorkflowStepNotification';
  data?: Maybe<Scalars['Json']['output']>;
  id: Scalars['ID']['output'];
  type: Scalars['String']['output'];
};

export type WorkflowStepResponse = {
  data?: InputMaybe<Scalars['Json']['input']>;
};

export const WorkflowStepStatus = {
  Completed: 'COMPLETED',
  NavigatedBack: 'NAVIGATED_BACK',
  Orphaned: 'ORPHANED',
  Pending: 'PENDING'
} as const;

export type WorkflowStepStatus = typeof WorkflowStepStatus[keyof typeof WorkflowStepStatus];
export type WorkflowTelemetryPayload = {
  serializedLocation?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  utmCampaign?: InputMaybe<Scalars['String']['input']>;
  utmContent?: InputMaybe<Scalars['String']['input']>;
  utmMedium?: InputMaybe<Scalars['String']['input']>;
  utmSource?: InputMaybe<Scalars['String']['input']>;
  utmTerm?: InputMaybe<Scalars['String']['input']>;
};

export const WorkflowType = {
  AutoSidekick: 'auto_sidekick',
  CatAutoDemoLong: 'cat_auto_demo_long',
  CatDemo: 'cat_demo',
  CatPropertyDemoFire: 'cat_property_demo_fire',
  CatPropertyDemoLong: 'cat_property_demo_long',
  CatPropertyDemoShort: 'cat_property_demo_short',
  Cyber: 'cyber',
  DigitalRequest: 'digital_request',
  ExternalParty: 'external_party',
  Financial: 'financial',
  FnolPlus: 'fnol_plus',
  GuidedCollisionReconstruction: 'guided_collision_reconstruction',
  Home: 'home',
  IntelligentInvestigation: 'intelligent_investigation',
  IntelligentInvestigationDemoPlaceholder: 'intelligent_investigation_demo_placeholder',
  IntelligentInvestigationInjury: 'intelligent_investigation_injury',
  IsaRepairIntent: 'isa_repair_intent',
  Liability: 'liability',
  OutOfBandWorkflow: 'out_of_band_workflow',
  PluginDemo: 'plugin_demo',
  RepairCoordination: 'repair_coordination',
  Standard: 'standard',
  WorkersComp: 'workers_comp',
  WorkersCompV2: 'workers_compV2'
} as const;

export type WorkflowType = typeof WorkflowType[keyof typeof WorkflowType];
export type AuthenticateUserMutationVariables = Exact<{
  name?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  carrierUserId?: InputMaybe<Scalars['String']['input']>;
  carrierSessionId?: InputMaybe<Scalars['String']['input']>;
  dispatchId?: InputMaybe<Scalars['String']['input']>;
}>;


export type AuthenticateUserMutation = { __typename?: 'Mutation', authenticateUserByPhone?: { __typename?: 'User', id: string, firstName?: string | undefined, magicToken?: string | undefined } | undefined };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, firstName?: string | undefined, magicToken?: string | undefined } | undefined };

export type Begin2FacAuthMutationVariables = Exact<{ [key: string]: never; }>;


export type Begin2FacAuthMutation = { __typename?: 'Mutation', begin2FacAuth?: { __typename?: 'Begin2FAcAuthResponse', success?: boolean | undefined, phoneNumber?: string | undefined } | undefined };

export type FindWorkflowDraftMutationVariables = Exact<{
  type?: InputMaybe<WorkflowType>;
}>;


export type FindWorkflowDraftMutation = { __typename?: 'Mutation', findWorkflowDraft?: { __typename?: 'FindExistingWorkflowDraftGQLResponse', success?: boolean | undefined, errors?: Array<string | undefined> | undefined, data?: { __typename?: 'WorkflowId', requires2Fac?: boolean | undefined, claimId?: string | undefined } | undefined } | undefined };

export type ResumeWorkflowDraftMutationVariables = Exact<{
  draftClaimId: Scalars['ID']['input'];
  authenticationCode?: InputMaybe<Scalars['String']['input']>;
}>;


export type ResumeWorkflowDraftMutation = { __typename?: 'Mutation', resumeWorkflowDraft?: { __typename?: 'ResumeWorkflowGQLResponse', success?: boolean | undefined, errors?: Array<string | undefined> | undefined, data?: { __typename?: 'ResumeWorkflow', workflowId?: string | undefined } | undefined } | undefined };

export type StartNewClaimWorkflowMutationVariables = Exact<{
  type?: InputMaybe<WorkflowType>;
  terminateDraftClaimId?: InputMaybe<Scalars['ID']['input']>;
  authenticatedDeepLinkToken?: InputMaybe<Scalars['String']['input']>;
  policyNumber?: InputMaybe<Scalars['String']['input']>;
  policyLocation?: InputMaybe<LocationInput>;
  catastropheId?: InputMaybe<Scalars['ID']['input']>;
  isCommercialClaim?: InputMaybe<Scalars['Boolean']['input']>;
  catastropheIncidentClaimInvitationToken?: InputMaybe<Scalars['String']['input']>;
  carrierSessionId?: InputMaybe<Scalars['String']['input']>;
  carrierDeviceId?: InputMaybe<Scalars['String']['input']>;
  dispatchId?: InputMaybe<Scalars['String']['input']>;
  isDeepLink?: InputMaybe<Scalars['Boolean']['input']>;
  isDeepLinkNLI?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type StartNewClaimWorkflowMutation = { __typename?: 'Mutation', startNewClaimWorkflow?: { __typename?: 'WorkflowGQLResponse', data?: { __typename?: 'Workflow', id: string } | undefined } | undefined };

export type UploadDocumentMutationVariables = Exact<{
  source: Scalars['String']['input'];
  feature: DocumentFeature;
}>;


export type UploadDocumentMutation = { __typename?: 'Mutation', uploadDocument?: { __typename?: 'DocumentUpload', extractionConfidence?: number | undefined, document?: { __typename?: 'Document', id: string, parsedContent?: any | undefined } | undefined } | undefined };

export type SmsGuidedPhotoCaptureLinkMutationVariables = Exact<{
  guidedPhotoSessionToken: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  sendNow: Scalars['Boolean']['input'];
}>;


export type SmsGuidedPhotoCaptureLinkMutation = { __typename?: 'Mutation', smsGuidedPhotoCaptureLink?: { __typename?: 'SMSGuidedPhotoCaptureLinkResponse', message?: string | undefined } | undefined };

export type DeclineGuidedPhotoCaptureSessionMutationVariables = Exact<{
  guidedPhotoSessionToken: Scalars['String']['input'];
}>;


export type DeclineGuidedPhotoCaptureSessionMutation = { __typename?: 'Mutation', declineGuidedPhotoCaptureSession?: { __typename?: 'GuidedPhotoCaptureSessionDeclinedResponse', sessionId: string, declinedAt: any } | undefined };

export type UpdateClaimFollowupStatusesMutationVariables = Exact<{
  updates: Array<ClaimFollowupStatusUpdateGql> | ClaimFollowupStatusUpdateGql;
}>;


export type UpdateClaimFollowupStatusesMutation = { __typename?: 'Mutation', updateClaimFollowupStatuses?: Array<{ __typename?: 'ClaimFollowupStatus', id: string } | undefined> | undefined };

export type GeocodeCityQueryVariables = Exact<{
  city: Scalars['String']['input'];
  state: Scalars['String']['input'];
}>;


export type GeocodeCityQuery = { __typename?: 'Query', enrichLocation?: { __typename?: 'Location', latitude?: number | undefined, longitude?: number | undefined, addressText?: string | undefined, postalCode?: string | undefined, country: Country } | undefined };

export type GeocodeIntersectionQueryVariables = Exact<{
  city: Scalars['String']['input'];
  state: Scalars['String']['input'];
  street1: Scalars['String']['input'];
  street2: Scalars['String']['input'];
}>;


export type GeocodeIntersectionQuery = { __typename?: 'Query', geocodeIntersection?: { __typename?: 'Location', latitude?: number | undefined, longitude?: number | undefined, addressText?: string | undefined, postalCode?: string | undefined } | undefined };

export type ShopsNearLocationQueryVariables = Exact<{
  addressText: Scalars['String']['input'];
}>;


export type ShopsNearLocationQuery = { __typename?: 'Query', searchShops?: { __typename?: 'SearchShopsResult', shopResults: Array<{ __typename?: 'LocalShop', id?: string | undefined, carrierId?: string | undefined, businessName?: string | undefined, milesAway?: number | undefined, phone?: string | undefined, isSWE?: boolean | undefined, availableDateTimes: Array<string>, timezone?: string | undefined, location?: { __typename?: 'Location', businessName?: string | undefined, addressText?: string | undefined, line1?: string | undefined, line2?: string | undefined, city?: string | undefined, state?: string | undefined, postalCode?: string | undefined, country: Country, latitude?: number | undefined, longitude?: number | undefined } | undefined, drivingDistance?: { __typename?: 'ShopDistanceValue', value?: number | undefined, text?: string | undefined } | undefined, drivingDuration?: { __typename?: 'ShopDistanceValue', value?: number | undefined, text?: string | undefined } | undefined }>, searchLocation: { __typename?: 'SearchLocation', addressText?: string | undefined, latitude?: number | undefined, longitude?: number | undefined } } | undefined };

export type SearchVehicleModelsQueryVariables = Exact<{
  make: Scalars['String']['input'];
}>;


export type SearchVehicleModelsQuery = { __typename?: 'Query', searchVehicleModels?: Array<{ __typename?: 'VehicleModel', model?: string | undefined } | undefined> | undefined };

export type SearchStateCitiesQueryVariables = Exact<{
  state: Scalars['String']['input'];
}>;


export type SearchStateCitiesQuery = { __typename?: 'Query', searchStateCities?: { __typename?: 'StateCities', cities?: Array<string | undefined> | undefined } | undefined };

export type SearchStateHighwaysQueryVariables = Exact<{
  state: Scalars['String']['input'];
}>;


export type SearchStateHighwaysQuery = { __typename?: 'Query', searchStateHighways?: { __typename?: 'StateHighways', highways?: Array<{ __typename?: 'StateHighway', id?: string | undefined, name?: string | undefined, directions?: Array<string | undefined> | undefined } | undefined> | undefined } | undefined };

export type GetTenantConfigQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTenantConfigQuery = { __typename?: 'Query', tenantConfig?: any | undefined };

export type SaveWorkflowTelemetryMutationVariables = Exact<{
  workflow: Scalars['ID']['input'];
  payload: WorkflowTelemetryPayload;
}>;


export type SaveWorkflowTelemetryMutation = { __typename?: 'Mutation', saveWorkflowTelemetry?: any | undefined };

export type LookupDemoQueryVariables = Exact<{
  demoToken: Scalars['String']['input'];
}>;


export type LookupDemoQuery = { __typename?: 'Query', lookupDemo?: { __typename?: 'Demo', id: string, tenant: Tenant } | undefined };

export type ActivateDemoMutationVariables = Exact<{
  demoToken: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
}>;


export type ActivateDemoMutation = { __typename?: 'Mutation', activateDemo?: { __typename?: 'UserInvite', id?: string | undefined } | undefined };

export type LookupAuthenticatedDeepLinkQueryVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type LookupAuthenticatedDeepLinkQuery = { __typename?: 'Query', lookupAuthenticatedDeepLink?: { __typename?: 'AuthenticatedDeepLinkData', policies?: Array<{ __typename?: 'AuthenticatedDeepLinkDataPolicy', policyNumber?: string | undefined, policyType?: string | undefined, contacts?: Array<{ __typename?: 'AuthenticatedDeepLinkDataContact', name?: string | undefined, phoneNumber?: string | undefined, dateOfBirth?: string | undefined } | undefined> | undefined } | undefined> | undefined, authenticatedSessionData?: { __typename?: 'AuthenticatedDeepLinkDataSession', carrierUserId?: string | undefined } | undefined } | undefined };

export type GetCatastropheAffectedPolicyLocationsQueryVariables = Exact<{
  catastropheId: Scalars['ID']['input'];
}>;


export type GetCatastropheAffectedPolicyLocationsQuery = { __typename?: 'Query', catastropheAffectedPolicyLocations?: Array<{ __typename?: 'LocationWithPolicy', policyNumber?: string | undefined, isCommercial?: boolean | undefined, location?: { __typename?: 'Location', addressText?: string | undefined, line1?: string | undefined, line2?: string | undefined, city?: string | undefined, state?: string | undefined, postalCode?: string | undefined, country: Country, latitude?: number | undefined, longitude?: number | undefined } | undefined } | undefined> | undefined };

export type LookupCatastropheIncidentClaimInvitationQueryVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type LookupCatastropheIncidentClaimInvitationQuery = { __typename?: 'Query', lookupCatastropheIncidentClaimInvitation?: { __typename?: 'CatastropheIncidentClaimInvitation', id: string, name: string, phoneNumber: string, catastropheIncident?: { __typename?: 'CatastropheIncident', id: string, name: string, type?: CatastropheType | undefined } | undefined } | undefined };

export type RetrieveIvrDispatchQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type RetrieveIvrDispatchQuery = { __typename?: 'Query', dispatch?: { __typename?: 'Dispatch', id: string, phoneNumber?: string | undefined } | undefined };

export type StartNewLinkInvestigationResponseMutationVariables = Exact<{
  name: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  investigationToken: Scalars['String']['input'];
}>;


export type StartNewLinkInvestigationResponseMutation = { __typename?: 'Mutation', authenticateAndStartNewLinkInvestigationResponse?: { __typename?: 'LinkInvestigationStartResponseData', user?: { __typename?: 'User', magicToken?: string | undefined } | undefined, workflow?: { __typename?: 'Workflow', id: string } | undefined } | undefined };

export type CardsGetMeQueryVariables = Exact<{ [key: string]: never; }>;


export type CardsGetMeQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, name?: string | undefined, email: string, phoneNumber?: string | undefined, firstName?: string | undefined, tenant: Tenant, agent?: { __typename?: 'Agent', id: string, name: string, phoneNumber?: string | undefined } | undefined } | undefined };

export type CatastropheGetMeQueryVariables = Exact<{ [key: string]: never; }>;


export type CatastropheGetMeQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, name?: string | undefined, firstName?: string | undefined, tenant: Tenant, tenantDisplayName?: string | undefined } | undefined };

export type CatastrophePayloadFragment = { __typename?: 'CatastropheUserExperience', key?: string | undefined, name?: string | undefined, type?: FullCatastropheType | undefined, startDate?: any | undefined, currentlyInProgress?: boolean | undefined, details?: any | undefined, todos?: Array<{ __typename?: 'CatastropheTodo', key?: string | undefined, completed?: boolean | undefined, title?: string | undefined, description?: string | undefined, type?: CatastropheTodoType | undefined, steps?: Array<any | undefined> | undefined } | undefined> | undefined };

export type GetUserExperienceForCatastropheQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetUserExperienceForCatastropheQuery = { __typename?: 'Query', userExperienceForCatastrophe?: { __typename?: 'CatastropheUserExperience', key?: string | undefined, name?: string | undefined, type?: FullCatastropheType | undefined, startDate?: any | undefined, currentlyInProgress?: boolean | undefined, details?: any | undefined, todos?: Array<{ __typename?: 'CatastropheTodo', key?: string | undefined, completed?: boolean | undefined, title?: string | undefined, description?: string | undefined, type?: CatastropheTodoType | undefined, steps?: Array<any | undefined> | undefined } | undefined> | undefined } | undefined };

export type ContinueUserExperienceForCatastropheMutationVariables = Exact<{
  catastropheId: Scalars['ID']['input'];
  completedSteps: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type ContinueUserExperienceForCatastropheMutation = { __typename?: 'Mutation', continueUserExperienceForCatastrophe?: { __typename?: 'CatastropheUserExperience', key?: string | undefined, name?: string | undefined, type?: FullCatastropheType | undefined, startDate?: any | undefined, currentlyInProgress?: boolean | undefined, details?: any | undefined, todos?: Array<{ __typename?: 'CatastropheTodo', key?: string | undefined, completed?: boolean | undefined, title?: string | undefined, description?: string | undefined, type?: CatastropheTodoType | undefined, steps?: Array<any | undefined> | undefined } | undefined> | undefined } | undefined };

export type GetTenantQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTenantQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, name?: string | undefined, tenant: Tenant, demo?: { __typename?: 'Demo', prefill: boolean } | undefined } | undefined };

export type ClaimWorkflowStepFragment = { __typename?: 'WorkflowStep', id: string, key: string, content: any, activeStepKeys?: Array<string | undefined> | undefined, stepIndex: number, totalSteps: number, notifications: Array<{ __typename?: 'WorkflowStepNotification', id: string, type: string, data?: any | undefined }>, workflow: { __typename?: 'Workflow', id: string, workflowType: WorkflowType } };

export type WorkflowCurrentStepQueryVariables = Exact<{
  workflow: Scalars['ID']['input'];
  forceStep?: InputMaybe<Scalars['String']['input']>;
}>;


export type WorkflowCurrentStepQuery = { __typename?: 'Query', workflowCurrentStep?: { __typename?: 'WorkflowStep', id: string, key: string, content: any, activeStepKeys?: Array<string | undefined> | undefined, stepIndex: number, totalSteps: number, notifications: Array<{ __typename?: 'WorkflowStepNotification', id: string, type: string, data?: any | undefined }>, workflow: { __typename?: 'Workflow', id: string, workflowType: WorkflowType } } | undefined };

export type ContinueWorkflowMutationVariables = Exact<{
  workflow: Scalars['ID']['input'];
  assumedCurrentStepKey?: InputMaybe<Scalars['String']['input']>;
  response: WorkflowStepResponse;
}>;


export type ContinueWorkflowMutation = { __typename?: 'Mutation', continueWorkflow?: { __typename?: 'WorkflowStep', id: string, key: string, content: any, activeStepKeys?: Array<string | undefined> | undefined, stepIndex: number, totalSteps: number, notifications: Array<{ __typename?: 'WorkflowStepNotification', id: string, type: string, data?: any | undefined }>, workflow: { __typename?: 'Workflow', id: string, workflowType: WorkflowType } } | undefined };

export type GoBackWorkflowMutationVariables = Exact<{
  workflow: Scalars['ID']['input'];
}>;


export type GoBackWorkflowMutation = { __typename?: 'Mutation', goBackWorkflow?: { __typename?: 'WorkflowStep', id: string, key: string, content: any, activeStepKeys?: Array<string | undefined> | undefined, stepIndex: number, totalSteps: number, notifications: Array<{ __typename?: 'WorkflowStepNotification', id: string, type: string, data?: any | undefined }>, workflow: { __typename?: 'Workflow', id: string, workflowType: WorkflowType } } | undefined };

export type EmailUnsubscribeCommunicationPreferenceQueryVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type EmailUnsubscribeCommunicationPreferenceQuery = { __typename?: 'Query', emailSubscriptionPreference?: { __typename?: 'ConversationEmailPreference', isSubscribed: boolean, email: string, id: string } | { __typename?: 'ConversationEmailPreferenceError', message: string } | undefined };

export type EmailUnsubscribeUpdateCommunicationPreferenceMutationVariables = Exact<{
  email: Scalars['String']['input'];
  subscribe: Scalars['Boolean']['input'];
}>;


export type EmailUnsubscribeUpdateCommunicationPreferenceMutation = { __typename?: 'Mutation', updateConversationEmailPreference?: { __typename?: 'ConversationEmailPreference', isSubscribed: boolean, email: string, id: string } | { __typename?: 'ConversationEmailPreferenceError', message: string } | undefined };

export type HomeGetMeQueryVariables = Exact<{ [key: string]: never; }>;


export type HomeGetMeQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, name?: string | undefined, email: string, phoneNumber?: string | undefined, firstName?: string | undefined, tenant: Tenant, demo?: { __typename?: 'Demo', jumpIntoWorkflow?: WorkflowType | undefined } | undefined } | undefined };

export type StartNewClaimMutationVariables = Exact<{
  type?: InputMaybe<WorkflowType>;
  catastropheId?: InputMaybe<Scalars['ID']['input']>;
}>;


export type StartNewClaimMutation = { __typename?: 'Mutation', startNewClaimWorkflow?: { __typename?: 'WorkflowGQLResponse', data?: { __typename?: 'Workflow', id: string } | undefined } | undefined };

export type GetCatastropheDetailsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetCatastropheDetailsQuery = { __typename?: 'Query', catastropheDetails?: { __typename?: 'CatastropheDetails', type: CatType } | undefined };

export type ManagePolicyGetMeQueryVariables = Exact<{ [key: string]: never; }>;


export type ManagePolicyGetMeQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, name?: string | undefined, email: string, phoneNumber?: string | undefined, firstName?: string | undefined, tenant: Tenant } | undefined };

export type TodoDetailsFragment = { __typename?: 'CatastropheTodo', key?: string | undefined, completed?: boolean | undefined, title?: string | undefined, description?: string | undefined, type?: CatastropheTodoType | undefined, steps?: Array<any | undefined> | undefined, id?: string | undefined };

export type CatastropheSiteQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type CatastropheSiteQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, name?: string | undefined, firstName?: string | undefined, tenant: Tenant, tenantDisplayName?: string | undefined } | undefined, catastrophe?: Array<{ __typename?: 'Catastrophe', id: string, name: string, geometry?: any | undefined, createdAt: any, updatedAt: any, todos?: Array<{ __typename?: 'CatastropheTodo', key?: string | undefined, completed?: boolean | undefined, title?: string | undefined, description?: string | undefined, type?: CatastropheTodoType | undefined, steps?: Array<any | undefined> | undefined, id?: string | undefined } | undefined> | undefined } | undefined> | undefined, catastropheDetails?: { __typename?: 'CatastropheDetails', type: CatType, phase: CatastrophePhase, severity: CatastropheSeverity } | undefined };

export type StartCatSiteVisitMutationVariables = Exact<{
  catastropheId: Scalars['ID']['input'];
  payload: WorkflowTelemetryPayload;
}>;


export type StartCatSiteVisitMutation = { __typename?: 'Mutation', saveCATTelemetry?: any | undefined };

export type CatastropheSiteTodoCompleteQueryVariables = Exact<{
  catastropheId: Scalars['ID']['input'];
}>;


export type CatastropheSiteTodoCompleteQuery = { __typename?: 'Query', catastrophe?: Array<{ __typename?: 'Catastrophe', id: string, todos?: Array<{ __typename?: 'CatastropheTodo', key?: string | undefined, completed?: boolean | undefined, title?: string | undefined, description?: string | undefined, type?: CatastropheTodoType | undefined, steps?: Array<any | undefined> | undefined, id?: string | undefined } | undefined> | undefined } | undefined> | undefined };

export type AcceptTermsStartCatastropheTodosMutationVariables = Exact<{
  catastropheId: Scalars['ID']['input'];
}>;


export type AcceptTermsStartCatastropheTodosMutation = { __typename?: 'Mutation', startCatastropheTodos?: { __typename?: 'CatastropheUserExperience', todos?: Array<{ __typename?: 'CatastropheTodo', key?: string | undefined, completed?: boolean | undefined, title?: string | undefined, description?: string | undefined, type?: CatastropheTodoType | undefined, steps?: Array<any | undefined> | undefined, id?: string | undefined } | undefined> | undefined } | undefined };

export type ChangeCatastropheTodoMutationVariables = Exact<{
  catastropheId: Scalars['ID']['input'];
  completedSteps: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type ChangeCatastropheTodoMutation = { __typename?: 'Mutation', changeCatastropheTodo?: { __typename?: 'Catastrophe', id: string, todos?: Array<{ __typename?: 'CatastropheTodo', key?: string | undefined, completed?: boolean | undefined, title?: string | undefined, description?: string | undefined, type?: CatastropheTodoType | undefined, steps?: Array<any | undefined> | undefined, id?: string | undefined } | undefined> | undefined } | undefined };

export type UpdateOAuthSessionMutationVariables = Exact<{
  sessionId: Scalars['String']['input'];
  providerName: Scalars['String']['input'];
  code?: InputMaybe<Scalars['String']['input']>;
  encryptChallenge: Scalars['Boolean']['input'];
}>;


export type UpdateOAuthSessionMutation = { __typename?: 'Mutation', updateOAuthSession?: { __typename?: 'OAuthSession', sessionId: string, providerName: string, challenge?: string | undefined, customerId?: string | undefined } | undefined };

export type AuthCodeOauthSessionMutationVariables = Exact<{
  code: Scalars['String']['input'];
  policyNumber: Scalars['String']['input'];
}>;


export type AuthCodeOauthSessionMutation = { __typename?: 'Mutation', authCodeOauthSession?: { __typename?: 'OAuthSession', sessionId: string, providerName: string, customerId?: string | undefined } | undefined };

export type GuidedPhotoSessionPhoneNumberQueryVariables = Exact<{
  claimId: Scalars['ID']['input'];
}>;


export type GuidedPhotoSessionPhoneNumberQuery = { __typename?: 'Query', guidedPhotoSessionPhoneNumber?: string | undefined };

export type PdsOutputCopyBadgeQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type PdsOutputCopyBadgeQuery = { __typename?: 'Query', claim?: { __typename?: 'Claim', pdsOutput?: Array<string | undefined> | undefined } | undefined, isaDispatchTasks?: { __typename?: 'EmmaTasksAssignedForClaimResponse', tasks?: Array<{ __typename?: 'EmmaTaskGQL', status: EmmaTaskStatus, parameters?: any | undefined }> | undefined } | undefined };

export type ShopQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ShopQuery = { __typename?: 'Query', shop?: { __typename?: 'LocalShop', id?: string | undefined, carrierId?: string | undefined, businessName?: string | undefined, phone?: string | undefined, carrierRepName?: string | undefined } | undefined };

export const CatastrophePayloadFragmentDoc = gql`
    fragment CatastrophePayload on CatastropheUserExperience {
  key
  name
  type
  startDate
  currentlyInProgress
  details
  todos {
    key
    completed
    title
    description
    type
    steps
  }
}
    `;
export const ClaimWorkflowStepFragmentDoc = gql`
    fragment ClaimWorkflowStep on WorkflowStep {
  id
  key
  content
  activeStepKeys
  notifications {
    id
    type
    data
  }
  stepIndex
  totalSteps
  workflow {
    id
    workflowType
  }
}
    `;
export const TodoDetailsFragmentDoc = gql`
    fragment todoDetails on CatastropheTodo {
  id: key
  key
  completed
  title
  description
  type
  steps
}
    `;
export const AuthenticateUserDocument = gql`
    mutation AuthenticateUser($name: String, $phoneNumber: String, $carrierUserId: String, $carrierSessionId: String, $dispatchId: String) {
  authenticateUserByPhone(
    name: $name
    phoneNumber: $phoneNumber
    carrierUserId: $carrierUserId
    carrierSessionId: $carrierSessionId
    dispatchId: $dispatchId
  ) {
    id
    firstName
    magicToken
  }
}
    `;
export type AuthenticateUserMutationFn = Apollo.MutationFunction<AuthenticateUserMutation, AuthenticateUserMutationVariables>;

/**
 * __useAuthenticateUserMutation__
 *
 * To run a mutation, you first call `useAuthenticateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthenticateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authenticateUserMutation, { data, loading, error }] = useAuthenticateUserMutation({
 *   variables: {
 *      name: // value for 'name'
 *      phoneNumber: // value for 'phoneNumber'
 *      carrierUserId: // value for 'carrierUserId'
 *      carrierSessionId: // value for 'carrierSessionId'
 *      dispatchId: // value for 'dispatchId'
 *   },
 * });
 */
export function useAuthenticateUserMutation(baseOptions?: Apollo.MutationHookOptions<AuthenticateUserMutation, AuthenticateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AuthenticateUserMutation, AuthenticateUserMutationVariables>(AuthenticateUserDocument, options);
      }
export type AuthenticateUserMutationHookResult = ReturnType<typeof useAuthenticateUserMutation>;
export type AuthenticateUserMutationResult = Apollo.MutationResult<AuthenticateUserMutation>;
export type AuthenticateUserMutationOptions = Apollo.BaseMutationOptions<AuthenticateUserMutation, AuthenticateUserMutationVariables>;
export const MeDocument = gql`
    query Me {
  me {
    id
    firstName
    magicToken
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export function refetchMeQuery(variables?: MeQueryVariables) {
      return { query: MeDocument, variables: variables }
    }
export const Begin2FacAuthDocument = gql`
    mutation Begin2FacAuth {
  begin2FacAuth {
    success
    phoneNumber
  }
}
    `;
export type Begin2FacAuthMutationFn = Apollo.MutationFunction<Begin2FacAuthMutation, Begin2FacAuthMutationVariables>;

/**
 * __useBegin2FacAuthMutation__
 *
 * To run a mutation, you first call `useBegin2FacAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBegin2FacAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [begin2FacAuthMutation, { data, loading, error }] = useBegin2FacAuthMutation({
 *   variables: {
 *   },
 * });
 */
export function useBegin2FacAuthMutation(baseOptions?: Apollo.MutationHookOptions<Begin2FacAuthMutation, Begin2FacAuthMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Begin2FacAuthMutation, Begin2FacAuthMutationVariables>(Begin2FacAuthDocument, options);
      }
export type Begin2FacAuthMutationHookResult = ReturnType<typeof useBegin2FacAuthMutation>;
export type Begin2FacAuthMutationResult = Apollo.MutationResult<Begin2FacAuthMutation>;
export type Begin2FacAuthMutationOptions = Apollo.BaseMutationOptions<Begin2FacAuthMutation, Begin2FacAuthMutationVariables>;
export const FindWorkflowDraftDocument = gql`
    mutation findWorkflowDraft($type: WorkflowType) {
  findWorkflowDraft(type: $type) {
    success
    errors
    data {
      requires2Fac
      claimId
    }
  }
}
    `;
export type FindWorkflowDraftMutationFn = Apollo.MutationFunction<FindWorkflowDraftMutation, FindWorkflowDraftMutationVariables>;

/**
 * __useFindWorkflowDraftMutation__
 *
 * To run a mutation, you first call `useFindWorkflowDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFindWorkflowDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [findWorkflowDraftMutation, { data, loading, error }] = useFindWorkflowDraftMutation({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useFindWorkflowDraftMutation(baseOptions?: Apollo.MutationHookOptions<FindWorkflowDraftMutation, FindWorkflowDraftMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FindWorkflowDraftMutation, FindWorkflowDraftMutationVariables>(FindWorkflowDraftDocument, options);
      }
export type FindWorkflowDraftMutationHookResult = ReturnType<typeof useFindWorkflowDraftMutation>;
export type FindWorkflowDraftMutationResult = Apollo.MutationResult<FindWorkflowDraftMutation>;
export type FindWorkflowDraftMutationOptions = Apollo.BaseMutationOptions<FindWorkflowDraftMutation, FindWorkflowDraftMutationVariables>;
export const ResumeWorkflowDraftDocument = gql`
    mutation ResumeWorkflowDraft($draftClaimId: ID!, $authenticationCode: String) {
  resumeWorkflowDraft(
    draftClaimId: $draftClaimId
    authenticationCode: $authenticationCode
  ) {
    success
    errors
    data {
      workflowId
    }
  }
}
    `;
export type ResumeWorkflowDraftMutationFn = Apollo.MutationFunction<ResumeWorkflowDraftMutation, ResumeWorkflowDraftMutationVariables>;

/**
 * __useResumeWorkflowDraftMutation__
 *
 * To run a mutation, you first call `useResumeWorkflowDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResumeWorkflowDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resumeWorkflowDraftMutation, { data, loading, error }] = useResumeWorkflowDraftMutation({
 *   variables: {
 *      draftClaimId: // value for 'draftClaimId'
 *      authenticationCode: // value for 'authenticationCode'
 *   },
 * });
 */
export function useResumeWorkflowDraftMutation(baseOptions?: Apollo.MutationHookOptions<ResumeWorkflowDraftMutation, ResumeWorkflowDraftMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResumeWorkflowDraftMutation, ResumeWorkflowDraftMutationVariables>(ResumeWorkflowDraftDocument, options);
      }
export type ResumeWorkflowDraftMutationHookResult = ReturnType<typeof useResumeWorkflowDraftMutation>;
export type ResumeWorkflowDraftMutationResult = Apollo.MutationResult<ResumeWorkflowDraftMutation>;
export type ResumeWorkflowDraftMutationOptions = Apollo.BaseMutationOptions<ResumeWorkflowDraftMutation, ResumeWorkflowDraftMutationVariables>;
export const StartNewClaimWorkflowDocument = gql`
    mutation StartNewClaimWorkflow($type: WorkflowType, $terminateDraftClaimId: ID, $authenticatedDeepLinkToken: String, $policyNumber: String, $policyLocation: LocationInput, $catastropheId: ID, $isCommercialClaim: Boolean, $catastropheIncidentClaimInvitationToken: String, $carrierSessionId: String, $carrierDeviceId: String, $dispatchId: String, $isDeepLink: Boolean, $isDeepLinkNLI: Boolean) {
  startNewClaimWorkflow(
    type: $type
    terminateDraftClaimId: $terminateDraftClaimId
    authenticatedDeepLinkToken: $authenticatedDeepLinkToken
    policyNumber: $policyNumber
    policyLocation: $policyLocation
    catastropheId: $catastropheId
    catastropheIncidentClaimInvitationToken: $catastropheIncidentClaimInvitationToken
    carrierSessionId: $carrierSessionId
    carrierDeviceId: $carrierDeviceId
    dispatchId: $dispatchId
    isDeepLink: $isDeepLink
    isDeepLinkNLI: $isDeepLinkNLI
    isCommercialClaim: $isCommercialClaim
  ) {
    data {
      id
    }
  }
}
    `;
export type StartNewClaimWorkflowMutationFn = Apollo.MutationFunction<StartNewClaimWorkflowMutation, StartNewClaimWorkflowMutationVariables>;

/**
 * __useStartNewClaimWorkflowMutation__
 *
 * To run a mutation, you first call `useStartNewClaimWorkflowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartNewClaimWorkflowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startNewClaimWorkflowMutation, { data, loading, error }] = useStartNewClaimWorkflowMutation({
 *   variables: {
 *      type: // value for 'type'
 *      terminateDraftClaimId: // value for 'terminateDraftClaimId'
 *      authenticatedDeepLinkToken: // value for 'authenticatedDeepLinkToken'
 *      policyNumber: // value for 'policyNumber'
 *      policyLocation: // value for 'policyLocation'
 *      catastropheId: // value for 'catastropheId'
 *      isCommercialClaim: // value for 'isCommercialClaim'
 *      catastropheIncidentClaimInvitationToken: // value for 'catastropheIncidentClaimInvitationToken'
 *      carrierSessionId: // value for 'carrierSessionId'
 *      carrierDeviceId: // value for 'carrierDeviceId'
 *      dispatchId: // value for 'dispatchId'
 *      isDeepLink: // value for 'isDeepLink'
 *      isDeepLinkNLI: // value for 'isDeepLinkNLI'
 *   },
 * });
 */
export function useStartNewClaimWorkflowMutation(baseOptions?: Apollo.MutationHookOptions<StartNewClaimWorkflowMutation, StartNewClaimWorkflowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartNewClaimWorkflowMutation, StartNewClaimWorkflowMutationVariables>(StartNewClaimWorkflowDocument, options);
      }
export type StartNewClaimWorkflowMutationHookResult = ReturnType<typeof useStartNewClaimWorkflowMutation>;
export type StartNewClaimWorkflowMutationResult = Apollo.MutationResult<StartNewClaimWorkflowMutation>;
export type StartNewClaimWorkflowMutationOptions = Apollo.BaseMutationOptions<StartNewClaimWorkflowMutation, StartNewClaimWorkflowMutationVariables>;
export const UploadDocumentDocument = gql`
    mutation UploadDocument($source: String!, $feature: DocumentFeature!) {
  uploadDocument(source: $source, feature: $feature) {
    document {
      id
      parsedContent
    }
    extractionConfidence
  }
}
    `;
export type UploadDocumentMutationFn = Apollo.MutationFunction<UploadDocumentMutation, UploadDocumentMutationVariables>;

/**
 * __useUploadDocumentMutation__
 *
 * To run a mutation, you first call `useUploadDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadDocumentMutation, { data, loading, error }] = useUploadDocumentMutation({
 *   variables: {
 *      source: // value for 'source'
 *      feature: // value for 'feature'
 *   },
 * });
 */
export function useUploadDocumentMutation(baseOptions?: Apollo.MutationHookOptions<UploadDocumentMutation, UploadDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadDocumentMutation, UploadDocumentMutationVariables>(UploadDocumentDocument, options);
      }
export type UploadDocumentMutationHookResult = ReturnType<typeof useUploadDocumentMutation>;
export type UploadDocumentMutationResult = Apollo.MutationResult<UploadDocumentMutation>;
export type UploadDocumentMutationOptions = Apollo.BaseMutationOptions<UploadDocumentMutation, UploadDocumentMutationVariables>;
export const SmsGuidedPhotoCaptureLinkDocument = gql`
    mutation SMSGuidedPhotoCaptureLink($guidedPhotoSessionToken: String!, $phoneNumber: String!, $sendNow: Boolean!) {
  smsGuidedPhotoCaptureLink(
    guidedPhotoSessionToken: $guidedPhotoSessionToken
    phoneNumber: $phoneNumber
    sendNow: $sendNow
  ) {
    message
  }
}
    `;
export type SmsGuidedPhotoCaptureLinkMutationFn = Apollo.MutationFunction<SmsGuidedPhotoCaptureLinkMutation, SmsGuidedPhotoCaptureLinkMutationVariables>;

/**
 * __useSmsGuidedPhotoCaptureLinkMutation__
 *
 * To run a mutation, you first call `useSmsGuidedPhotoCaptureLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSmsGuidedPhotoCaptureLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [smsGuidedPhotoCaptureLinkMutation, { data, loading, error }] = useSmsGuidedPhotoCaptureLinkMutation({
 *   variables: {
 *      guidedPhotoSessionToken: // value for 'guidedPhotoSessionToken'
 *      phoneNumber: // value for 'phoneNumber'
 *      sendNow: // value for 'sendNow'
 *   },
 * });
 */
export function useSmsGuidedPhotoCaptureLinkMutation(baseOptions?: Apollo.MutationHookOptions<SmsGuidedPhotoCaptureLinkMutation, SmsGuidedPhotoCaptureLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SmsGuidedPhotoCaptureLinkMutation, SmsGuidedPhotoCaptureLinkMutationVariables>(SmsGuidedPhotoCaptureLinkDocument, options);
      }
export type SmsGuidedPhotoCaptureLinkMutationHookResult = ReturnType<typeof useSmsGuidedPhotoCaptureLinkMutation>;
export type SmsGuidedPhotoCaptureLinkMutationResult = Apollo.MutationResult<SmsGuidedPhotoCaptureLinkMutation>;
export type SmsGuidedPhotoCaptureLinkMutationOptions = Apollo.BaseMutationOptions<SmsGuidedPhotoCaptureLinkMutation, SmsGuidedPhotoCaptureLinkMutationVariables>;
export const DeclineGuidedPhotoCaptureSessionDocument = gql`
    mutation DeclineGuidedPhotoCaptureSession($guidedPhotoSessionToken: String!) {
  declineGuidedPhotoCaptureSession(
    guidedPhotoSessionToken: $guidedPhotoSessionToken
  ) {
    sessionId
    declinedAt
  }
}
    `;
export type DeclineGuidedPhotoCaptureSessionMutationFn = Apollo.MutationFunction<DeclineGuidedPhotoCaptureSessionMutation, DeclineGuidedPhotoCaptureSessionMutationVariables>;

/**
 * __useDeclineGuidedPhotoCaptureSessionMutation__
 *
 * To run a mutation, you first call `useDeclineGuidedPhotoCaptureSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeclineGuidedPhotoCaptureSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [declineGuidedPhotoCaptureSessionMutation, { data, loading, error }] = useDeclineGuidedPhotoCaptureSessionMutation({
 *   variables: {
 *      guidedPhotoSessionToken: // value for 'guidedPhotoSessionToken'
 *   },
 * });
 */
export function useDeclineGuidedPhotoCaptureSessionMutation(baseOptions?: Apollo.MutationHookOptions<DeclineGuidedPhotoCaptureSessionMutation, DeclineGuidedPhotoCaptureSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeclineGuidedPhotoCaptureSessionMutation, DeclineGuidedPhotoCaptureSessionMutationVariables>(DeclineGuidedPhotoCaptureSessionDocument, options);
      }
export type DeclineGuidedPhotoCaptureSessionMutationHookResult = ReturnType<typeof useDeclineGuidedPhotoCaptureSessionMutation>;
export type DeclineGuidedPhotoCaptureSessionMutationResult = Apollo.MutationResult<DeclineGuidedPhotoCaptureSessionMutation>;
export type DeclineGuidedPhotoCaptureSessionMutationOptions = Apollo.BaseMutationOptions<DeclineGuidedPhotoCaptureSessionMutation, DeclineGuidedPhotoCaptureSessionMutationVariables>;
export const UpdateClaimFollowupStatusesDocument = gql`
    mutation UpdateClaimFollowupStatuses($updates: [ClaimFollowupStatusUpdateGQL!]!) {
  updateClaimFollowupStatuses(updates: $updates) {
    id
  }
}
    `;
export type UpdateClaimFollowupStatusesMutationFn = Apollo.MutationFunction<UpdateClaimFollowupStatusesMutation, UpdateClaimFollowupStatusesMutationVariables>;

/**
 * __useUpdateClaimFollowupStatusesMutation__
 *
 * To run a mutation, you first call `useUpdateClaimFollowupStatusesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClaimFollowupStatusesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClaimFollowupStatusesMutation, { data, loading, error }] = useUpdateClaimFollowupStatusesMutation({
 *   variables: {
 *      updates: // value for 'updates'
 *   },
 * });
 */
export function useUpdateClaimFollowupStatusesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClaimFollowupStatusesMutation, UpdateClaimFollowupStatusesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateClaimFollowupStatusesMutation, UpdateClaimFollowupStatusesMutationVariables>(UpdateClaimFollowupStatusesDocument, options);
      }
export type UpdateClaimFollowupStatusesMutationHookResult = ReturnType<typeof useUpdateClaimFollowupStatusesMutation>;
export type UpdateClaimFollowupStatusesMutationResult = Apollo.MutationResult<UpdateClaimFollowupStatusesMutation>;
export type UpdateClaimFollowupStatusesMutationOptions = Apollo.BaseMutationOptions<UpdateClaimFollowupStatusesMutation, UpdateClaimFollowupStatusesMutationVariables>;
export const GeocodeCityDocument = gql`
    query GeocodeCity($city: String!, $state: String!) {
  enrichLocation(location: {city: $city, state: $state}) {
    latitude
    longitude
    addressText
    postalCode
    country
  }
}
    `;

/**
 * __useGeocodeCityQuery__
 *
 * To run a query within a React component, call `useGeocodeCityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGeocodeCityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGeocodeCityQuery({
 *   variables: {
 *      city: // value for 'city'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useGeocodeCityQuery(baseOptions: Apollo.QueryHookOptions<GeocodeCityQuery, GeocodeCityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GeocodeCityQuery, GeocodeCityQueryVariables>(GeocodeCityDocument, options);
      }
export function useGeocodeCityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GeocodeCityQuery, GeocodeCityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GeocodeCityQuery, GeocodeCityQueryVariables>(GeocodeCityDocument, options);
        }
export type GeocodeCityQueryHookResult = ReturnType<typeof useGeocodeCityQuery>;
export type GeocodeCityLazyQueryHookResult = ReturnType<typeof useGeocodeCityLazyQuery>;
export type GeocodeCityQueryResult = Apollo.QueryResult<GeocodeCityQuery, GeocodeCityQueryVariables>;
export function refetchGeocodeCityQuery(variables: GeocodeCityQueryVariables) {
      return { query: GeocodeCityDocument, variables: variables }
    }
export const GeocodeIntersectionDocument = gql`
    query GeocodeIntersection($city: String!, $state: String!, $street1: String!, $street2: String!) {
  geocodeIntersection(
    city: $city
    state: $state
    street1: $street1
    street2: $street2
  ) {
    latitude
    longitude
    addressText
    postalCode
  }
}
    `;

/**
 * __useGeocodeIntersectionQuery__
 *
 * To run a query within a React component, call `useGeocodeIntersectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGeocodeIntersectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGeocodeIntersectionQuery({
 *   variables: {
 *      city: // value for 'city'
 *      state: // value for 'state'
 *      street1: // value for 'street1'
 *      street2: // value for 'street2'
 *   },
 * });
 */
export function useGeocodeIntersectionQuery(baseOptions: Apollo.QueryHookOptions<GeocodeIntersectionQuery, GeocodeIntersectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GeocodeIntersectionQuery, GeocodeIntersectionQueryVariables>(GeocodeIntersectionDocument, options);
      }
export function useGeocodeIntersectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GeocodeIntersectionQuery, GeocodeIntersectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GeocodeIntersectionQuery, GeocodeIntersectionQueryVariables>(GeocodeIntersectionDocument, options);
        }
export type GeocodeIntersectionQueryHookResult = ReturnType<typeof useGeocodeIntersectionQuery>;
export type GeocodeIntersectionLazyQueryHookResult = ReturnType<typeof useGeocodeIntersectionLazyQuery>;
export type GeocodeIntersectionQueryResult = Apollo.QueryResult<GeocodeIntersectionQuery, GeocodeIntersectionQueryVariables>;
export function refetchGeocodeIntersectionQuery(variables: GeocodeIntersectionQueryVariables) {
      return { query: GeocodeIntersectionDocument, variables: variables }
    }
export const ShopsNearLocationDocument = gql`
    query ShopsNearLocation($addressText: String!) {
  searchShops(addressText: $addressText) {
    shopResults {
      id
      carrierId
      businessName
      milesAway
      phone
      location {
        businessName
        addressText
        line1
        line2
        city
        state
        postalCode
        country
        latitude
        longitude
      }
      isSWE
      availableDateTimes
      timezone
      drivingDistance {
        value
        text
      }
      drivingDuration {
        value
        text
      }
    }
    searchLocation {
      addressText
      latitude
      longitude
    }
  }
}
    `;

/**
 * __useShopsNearLocationQuery__
 *
 * To run a query within a React component, call `useShopsNearLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopsNearLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopsNearLocationQuery({
 *   variables: {
 *      addressText: // value for 'addressText'
 *   },
 * });
 */
export function useShopsNearLocationQuery(baseOptions: Apollo.QueryHookOptions<ShopsNearLocationQuery, ShopsNearLocationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShopsNearLocationQuery, ShopsNearLocationQueryVariables>(ShopsNearLocationDocument, options);
      }
export function useShopsNearLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShopsNearLocationQuery, ShopsNearLocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShopsNearLocationQuery, ShopsNearLocationQueryVariables>(ShopsNearLocationDocument, options);
        }
export type ShopsNearLocationQueryHookResult = ReturnType<typeof useShopsNearLocationQuery>;
export type ShopsNearLocationLazyQueryHookResult = ReturnType<typeof useShopsNearLocationLazyQuery>;
export type ShopsNearLocationQueryResult = Apollo.QueryResult<ShopsNearLocationQuery, ShopsNearLocationQueryVariables>;
export function refetchShopsNearLocationQuery(variables: ShopsNearLocationQueryVariables) {
      return { query: ShopsNearLocationDocument, variables: variables }
    }
export const SearchVehicleModelsDocument = gql`
    query SearchVehicleModels($make: String!) {
  searchVehicleModels(make: $make) {
    model
  }
}
    `;

/**
 * __useSearchVehicleModelsQuery__
 *
 * To run a query within a React component, call `useSearchVehicleModelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchVehicleModelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchVehicleModelsQuery({
 *   variables: {
 *      make: // value for 'make'
 *   },
 * });
 */
export function useSearchVehicleModelsQuery(baseOptions: Apollo.QueryHookOptions<SearchVehicleModelsQuery, SearchVehicleModelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchVehicleModelsQuery, SearchVehicleModelsQueryVariables>(SearchVehicleModelsDocument, options);
      }
export function useSearchVehicleModelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchVehicleModelsQuery, SearchVehicleModelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchVehicleModelsQuery, SearchVehicleModelsQueryVariables>(SearchVehicleModelsDocument, options);
        }
export type SearchVehicleModelsQueryHookResult = ReturnType<typeof useSearchVehicleModelsQuery>;
export type SearchVehicleModelsLazyQueryHookResult = ReturnType<typeof useSearchVehicleModelsLazyQuery>;
export type SearchVehicleModelsQueryResult = Apollo.QueryResult<SearchVehicleModelsQuery, SearchVehicleModelsQueryVariables>;
export function refetchSearchVehicleModelsQuery(variables: SearchVehicleModelsQueryVariables) {
      return { query: SearchVehicleModelsDocument, variables: variables }
    }
export const SearchStateCitiesDocument = gql`
    query SearchStateCities($state: String!) {
  searchStateCities(state: $state) {
    cities
  }
}
    `;

/**
 * __useSearchStateCitiesQuery__
 *
 * To run a query within a React component, call `useSearchStateCitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchStateCitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchStateCitiesQuery({
 *   variables: {
 *      state: // value for 'state'
 *   },
 * });
 */
export function useSearchStateCitiesQuery(baseOptions: Apollo.QueryHookOptions<SearchStateCitiesQuery, SearchStateCitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchStateCitiesQuery, SearchStateCitiesQueryVariables>(SearchStateCitiesDocument, options);
      }
export function useSearchStateCitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchStateCitiesQuery, SearchStateCitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchStateCitiesQuery, SearchStateCitiesQueryVariables>(SearchStateCitiesDocument, options);
        }
export type SearchStateCitiesQueryHookResult = ReturnType<typeof useSearchStateCitiesQuery>;
export type SearchStateCitiesLazyQueryHookResult = ReturnType<typeof useSearchStateCitiesLazyQuery>;
export type SearchStateCitiesQueryResult = Apollo.QueryResult<SearchStateCitiesQuery, SearchStateCitiesQueryVariables>;
export function refetchSearchStateCitiesQuery(variables: SearchStateCitiesQueryVariables) {
      return { query: SearchStateCitiesDocument, variables: variables }
    }
export const SearchStateHighwaysDocument = gql`
    query SearchStateHighways($state: String!) {
  searchStateHighways(state: $state) {
    highways {
      id
      name
      directions
    }
  }
}
    `;

/**
 * __useSearchStateHighwaysQuery__
 *
 * To run a query within a React component, call `useSearchStateHighwaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchStateHighwaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchStateHighwaysQuery({
 *   variables: {
 *      state: // value for 'state'
 *   },
 * });
 */
export function useSearchStateHighwaysQuery(baseOptions: Apollo.QueryHookOptions<SearchStateHighwaysQuery, SearchStateHighwaysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchStateHighwaysQuery, SearchStateHighwaysQueryVariables>(SearchStateHighwaysDocument, options);
      }
export function useSearchStateHighwaysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchStateHighwaysQuery, SearchStateHighwaysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchStateHighwaysQuery, SearchStateHighwaysQueryVariables>(SearchStateHighwaysDocument, options);
        }
export type SearchStateHighwaysQueryHookResult = ReturnType<typeof useSearchStateHighwaysQuery>;
export type SearchStateHighwaysLazyQueryHookResult = ReturnType<typeof useSearchStateHighwaysLazyQuery>;
export type SearchStateHighwaysQueryResult = Apollo.QueryResult<SearchStateHighwaysQuery, SearchStateHighwaysQueryVariables>;
export function refetchSearchStateHighwaysQuery(variables: SearchStateHighwaysQueryVariables) {
      return { query: SearchStateHighwaysDocument, variables: variables }
    }
export const GetTenantConfigDocument = gql`
    query GetTenantConfig {
  tenantConfig
}
    `;

/**
 * __useGetTenantConfigQuery__
 *
 * To run a query within a React component, call `useGetTenantConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenantConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenantConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTenantConfigQuery(baseOptions?: Apollo.QueryHookOptions<GetTenantConfigQuery, GetTenantConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTenantConfigQuery, GetTenantConfigQueryVariables>(GetTenantConfigDocument, options);
      }
export function useGetTenantConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTenantConfigQuery, GetTenantConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTenantConfigQuery, GetTenantConfigQueryVariables>(GetTenantConfigDocument, options);
        }
export type GetTenantConfigQueryHookResult = ReturnType<typeof useGetTenantConfigQuery>;
export type GetTenantConfigLazyQueryHookResult = ReturnType<typeof useGetTenantConfigLazyQuery>;
export type GetTenantConfigQueryResult = Apollo.QueryResult<GetTenantConfigQuery, GetTenantConfigQueryVariables>;
export function refetchGetTenantConfigQuery(variables?: GetTenantConfigQueryVariables) {
      return { query: GetTenantConfigDocument, variables: variables }
    }
export const SaveWorkflowTelemetryDocument = gql`
    mutation SaveWorkflowTelemetry($workflow: ID!, $payload: WorkflowTelemetryPayload!) {
  saveWorkflowTelemetry(workflow: $workflow, payload: $payload)
}
    `;
export type SaveWorkflowTelemetryMutationFn = Apollo.MutationFunction<SaveWorkflowTelemetryMutation, SaveWorkflowTelemetryMutationVariables>;

/**
 * __useSaveWorkflowTelemetryMutation__
 *
 * To run a mutation, you first call `useSaveWorkflowTelemetryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveWorkflowTelemetryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveWorkflowTelemetryMutation, { data, loading, error }] = useSaveWorkflowTelemetryMutation({
 *   variables: {
 *      workflow: // value for 'workflow'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useSaveWorkflowTelemetryMutation(baseOptions?: Apollo.MutationHookOptions<SaveWorkflowTelemetryMutation, SaveWorkflowTelemetryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveWorkflowTelemetryMutation, SaveWorkflowTelemetryMutationVariables>(SaveWorkflowTelemetryDocument, options);
      }
export type SaveWorkflowTelemetryMutationHookResult = ReturnType<typeof useSaveWorkflowTelemetryMutation>;
export type SaveWorkflowTelemetryMutationResult = Apollo.MutationResult<SaveWorkflowTelemetryMutation>;
export type SaveWorkflowTelemetryMutationOptions = Apollo.BaseMutationOptions<SaveWorkflowTelemetryMutation, SaveWorkflowTelemetryMutationVariables>;
export const LookupDemoDocument = gql`
    query LookupDemo($demoToken: String!) {
  lookupDemo(demoToken: $demoToken) {
    id
    tenant
  }
}
    `;

/**
 * __useLookupDemoQuery__
 *
 * To run a query within a React component, call `useLookupDemoQuery` and pass it any options that fit your needs.
 * When your component renders, `useLookupDemoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLookupDemoQuery({
 *   variables: {
 *      demoToken: // value for 'demoToken'
 *   },
 * });
 */
export function useLookupDemoQuery(baseOptions: Apollo.QueryHookOptions<LookupDemoQuery, LookupDemoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LookupDemoQuery, LookupDemoQueryVariables>(LookupDemoDocument, options);
      }
export function useLookupDemoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LookupDemoQuery, LookupDemoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LookupDemoQuery, LookupDemoQueryVariables>(LookupDemoDocument, options);
        }
export type LookupDemoQueryHookResult = ReturnType<typeof useLookupDemoQuery>;
export type LookupDemoLazyQueryHookResult = ReturnType<typeof useLookupDemoLazyQuery>;
export type LookupDemoQueryResult = Apollo.QueryResult<LookupDemoQuery, LookupDemoQueryVariables>;
export function refetchLookupDemoQuery(variables: LookupDemoQueryVariables) {
      return { query: LookupDemoDocument, variables: variables }
    }
export const ActivateDemoDocument = gql`
    mutation ActivateDemo($demoToken: String!, $name: String!, $phoneNumber: String!) {
  activateDemo(demoToken: $demoToken, name: $name, phoneNumber: $phoneNumber) {
    id
  }
}
    `;
export type ActivateDemoMutationFn = Apollo.MutationFunction<ActivateDemoMutation, ActivateDemoMutationVariables>;

/**
 * __useActivateDemoMutation__
 *
 * To run a mutation, you first call `useActivateDemoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateDemoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateDemoMutation, { data, loading, error }] = useActivateDemoMutation({
 *   variables: {
 *      demoToken: // value for 'demoToken'
 *      name: // value for 'name'
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useActivateDemoMutation(baseOptions?: Apollo.MutationHookOptions<ActivateDemoMutation, ActivateDemoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateDemoMutation, ActivateDemoMutationVariables>(ActivateDemoDocument, options);
      }
export type ActivateDemoMutationHookResult = ReturnType<typeof useActivateDemoMutation>;
export type ActivateDemoMutationResult = Apollo.MutationResult<ActivateDemoMutation>;
export type ActivateDemoMutationOptions = Apollo.BaseMutationOptions<ActivateDemoMutation, ActivateDemoMutationVariables>;
export const LookupAuthenticatedDeepLinkDocument = gql`
    query LookupAuthenticatedDeepLink($token: String!) {
  lookupAuthenticatedDeepLink(token: $token) {
    policies {
      policyNumber
      policyType
      contacts {
        name
        phoneNumber
        dateOfBirth
      }
    }
    authenticatedSessionData {
      carrierUserId
    }
  }
}
    `;

/**
 * __useLookupAuthenticatedDeepLinkQuery__
 *
 * To run a query within a React component, call `useLookupAuthenticatedDeepLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useLookupAuthenticatedDeepLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLookupAuthenticatedDeepLinkQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useLookupAuthenticatedDeepLinkQuery(baseOptions: Apollo.QueryHookOptions<LookupAuthenticatedDeepLinkQuery, LookupAuthenticatedDeepLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LookupAuthenticatedDeepLinkQuery, LookupAuthenticatedDeepLinkQueryVariables>(LookupAuthenticatedDeepLinkDocument, options);
      }
export function useLookupAuthenticatedDeepLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LookupAuthenticatedDeepLinkQuery, LookupAuthenticatedDeepLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LookupAuthenticatedDeepLinkQuery, LookupAuthenticatedDeepLinkQueryVariables>(LookupAuthenticatedDeepLinkDocument, options);
        }
export type LookupAuthenticatedDeepLinkQueryHookResult = ReturnType<typeof useLookupAuthenticatedDeepLinkQuery>;
export type LookupAuthenticatedDeepLinkLazyQueryHookResult = ReturnType<typeof useLookupAuthenticatedDeepLinkLazyQuery>;
export type LookupAuthenticatedDeepLinkQueryResult = Apollo.QueryResult<LookupAuthenticatedDeepLinkQuery, LookupAuthenticatedDeepLinkQueryVariables>;
export function refetchLookupAuthenticatedDeepLinkQuery(variables: LookupAuthenticatedDeepLinkQueryVariables) {
      return { query: LookupAuthenticatedDeepLinkDocument, variables: variables }
    }
export const GetCatastropheAffectedPolicyLocationsDocument = gql`
    query GetCatastropheAffectedPolicyLocations($catastropheId: ID!) {
  catastropheAffectedPolicyLocations(catastropheId: $catastropheId) {
    policyNumber
    isCommercial
    location {
      addressText
      line1
      line2
      city
      state
      postalCode
      country
      latitude
      longitude
    }
  }
}
    `;

/**
 * __useGetCatastropheAffectedPolicyLocationsQuery__
 *
 * To run a query within a React component, call `useGetCatastropheAffectedPolicyLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCatastropheAffectedPolicyLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCatastropheAffectedPolicyLocationsQuery({
 *   variables: {
 *      catastropheId: // value for 'catastropheId'
 *   },
 * });
 */
export function useGetCatastropheAffectedPolicyLocationsQuery(baseOptions: Apollo.QueryHookOptions<GetCatastropheAffectedPolicyLocationsQuery, GetCatastropheAffectedPolicyLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCatastropheAffectedPolicyLocationsQuery, GetCatastropheAffectedPolicyLocationsQueryVariables>(GetCatastropheAffectedPolicyLocationsDocument, options);
      }
export function useGetCatastropheAffectedPolicyLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCatastropheAffectedPolicyLocationsQuery, GetCatastropheAffectedPolicyLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCatastropheAffectedPolicyLocationsQuery, GetCatastropheAffectedPolicyLocationsQueryVariables>(GetCatastropheAffectedPolicyLocationsDocument, options);
        }
export type GetCatastropheAffectedPolicyLocationsQueryHookResult = ReturnType<typeof useGetCatastropheAffectedPolicyLocationsQuery>;
export type GetCatastropheAffectedPolicyLocationsLazyQueryHookResult = ReturnType<typeof useGetCatastropheAffectedPolicyLocationsLazyQuery>;
export type GetCatastropheAffectedPolicyLocationsQueryResult = Apollo.QueryResult<GetCatastropheAffectedPolicyLocationsQuery, GetCatastropheAffectedPolicyLocationsQueryVariables>;
export function refetchGetCatastropheAffectedPolicyLocationsQuery(variables: GetCatastropheAffectedPolicyLocationsQueryVariables) {
      return { query: GetCatastropheAffectedPolicyLocationsDocument, variables: variables }
    }
export const LookupCatastropheIncidentClaimInvitationDocument = gql`
    query LookupCatastropheIncidentClaimInvitation($token: String!) {
  lookupCatastropheIncidentClaimInvitation(token: $token) {
    id
    name
    phoneNumber
    catastropheIncident {
      id
      name
      type
    }
  }
}
    `;

/**
 * __useLookupCatastropheIncidentClaimInvitationQuery__
 *
 * To run a query within a React component, call `useLookupCatastropheIncidentClaimInvitationQuery` and pass it any options that fit your needs.
 * When your component renders, `useLookupCatastropheIncidentClaimInvitationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLookupCatastropheIncidentClaimInvitationQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useLookupCatastropheIncidentClaimInvitationQuery(baseOptions: Apollo.QueryHookOptions<LookupCatastropheIncidentClaimInvitationQuery, LookupCatastropheIncidentClaimInvitationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LookupCatastropheIncidentClaimInvitationQuery, LookupCatastropheIncidentClaimInvitationQueryVariables>(LookupCatastropheIncidentClaimInvitationDocument, options);
      }
export function useLookupCatastropheIncidentClaimInvitationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LookupCatastropheIncidentClaimInvitationQuery, LookupCatastropheIncidentClaimInvitationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LookupCatastropheIncidentClaimInvitationQuery, LookupCatastropheIncidentClaimInvitationQueryVariables>(LookupCatastropheIncidentClaimInvitationDocument, options);
        }
export type LookupCatastropheIncidentClaimInvitationQueryHookResult = ReturnType<typeof useLookupCatastropheIncidentClaimInvitationQuery>;
export type LookupCatastropheIncidentClaimInvitationLazyQueryHookResult = ReturnType<typeof useLookupCatastropheIncidentClaimInvitationLazyQuery>;
export type LookupCatastropheIncidentClaimInvitationQueryResult = Apollo.QueryResult<LookupCatastropheIncidentClaimInvitationQuery, LookupCatastropheIncidentClaimInvitationQueryVariables>;
export function refetchLookupCatastropheIncidentClaimInvitationQuery(variables: LookupCatastropheIncidentClaimInvitationQueryVariables) {
      return { query: LookupCatastropheIncidentClaimInvitationDocument, variables: variables }
    }
export const RetrieveIvrDispatchDocument = gql`
    query RetrieveIvrDispatch($id: ID!) {
  dispatch(id: $id) {
    id
    phoneNumber
  }
}
    `;

/**
 * __useRetrieveIvrDispatchQuery__
 *
 * To run a query within a React component, call `useRetrieveIvrDispatchQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetrieveIvrDispatchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetrieveIvrDispatchQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRetrieveIvrDispatchQuery(baseOptions: Apollo.QueryHookOptions<RetrieveIvrDispatchQuery, RetrieveIvrDispatchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RetrieveIvrDispatchQuery, RetrieveIvrDispatchQueryVariables>(RetrieveIvrDispatchDocument, options);
      }
export function useRetrieveIvrDispatchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RetrieveIvrDispatchQuery, RetrieveIvrDispatchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RetrieveIvrDispatchQuery, RetrieveIvrDispatchQueryVariables>(RetrieveIvrDispatchDocument, options);
        }
export type RetrieveIvrDispatchQueryHookResult = ReturnType<typeof useRetrieveIvrDispatchQuery>;
export type RetrieveIvrDispatchLazyQueryHookResult = ReturnType<typeof useRetrieveIvrDispatchLazyQuery>;
export type RetrieveIvrDispatchQueryResult = Apollo.QueryResult<RetrieveIvrDispatchQuery, RetrieveIvrDispatchQueryVariables>;
export function refetchRetrieveIvrDispatchQuery(variables: RetrieveIvrDispatchQueryVariables) {
      return { query: RetrieveIvrDispatchDocument, variables: variables }
    }
export const StartNewLinkInvestigationResponseDocument = gql`
    mutation StartNewLinkInvestigationResponse($name: String!, $phoneNumber: String!, $investigationToken: String!) {
  authenticateAndStartNewLinkInvestigationResponse(
    name: $name
    phoneNumber: $phoneNumber
    investigationToken: $investigationToken
  ) {
    user {
      magicToken
    }
    workflow {
      id
    }
  }
}
    `;
export type StartNewLinkInvestigationResponseMutationFn = Apollo.MutationFunction<StartNewLinkInvestigationResponseMutation, StartNewLinkInvestigationResponseMutationVariables>;

/**
 * __useStartNewLinkInvestigationResponseMutation__
 *
 * To run a mutation, you first call `useStartNewLinkInvestigationResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartNewLinkInvestigationResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startNewLinkInvestigationResponseMutation, { data, loading, error }] = useStartNewLinkInvestigationResponseMutation({
 *   variables: {
 *      name: // value for 'name'
 *      phoneNumber: // value for 'phoneNumber'
 *      investigationToken: // value for 'investigationToken'
 *   },
 * });
 */
export function useStartNewLinkInvestigationResponseMutation(baseOptions?: Apollo.MutationHookOptions<StartNewLinkInvestigationResponseMutation, StartNewLinkInvestigationResponseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartNewLinkInvestigationResponseMutation, StartNewLinkInvestigationResponseMutationVariables>(StartNewLinkInvestigationResponseDocument, options);
      }
export type StartNewLinkInvestigationResponseMutationHookResult = ReturnType<typeof useStartNewLinkInvestigationResponseMutation>;
export type StartNewLinkInvestigationResponseMutationResult = Apollo.MutationResult<StartNewLinkInvestigationResponseMutation>;
export type StartNewLinkInvestigationResponseMutationOptions = Apollo.BaseMutationOptions<StartNewLinkInvestigationResponseMutation, StartNewLinkInvestigationResponseMutationVariables>;
export const CardsGetMeDocument = gql`
    query CardsGetMe {
  me {
    id
    name
    email
    phoneNumber
    firstName
    tenant
    agent {
      id
      name
      phoneNumber
    }
  }
}
    `;

/**
 * __useCardsGetMeQuery__
 *
 * To run a query within a React component, call `useCardsGetMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useCardsGetMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCardsGetMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useCardsGetMeQuery(baseOptions?: Apollo.QueryHookOptions<CardsGetMeQuery, CardsGetMeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CardsGetMeQuery, CardsGetMeQueryVariables>(CardsGetMeDocument, options);
      }
export function useCardsGetMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CardsGetMeQuery, CardsGetMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CardsGetMeQuery, CardsGetMeQueryVariables>(CardsGetMeDocument, options);
        }
export type CardsGetMeQueryHookResult = ReturnType<typeof useCardsGetMeQuery>;
export type CardsGetMeLazyQueryHookResult = ReturnType<typeof useCardsGetMeLazyQuery>;
export type CardsGetMeQueryResult = Apollo.QueryResult<CardsGetMeQuery, CardsGetMeQueryVariables>;
export function refetchCardsGetMeQuery(variables?: CardsGetMeQueryVariables) {
      return { query: CardsGetMeDocument, variables: variables }
    }
export const CatastropheGetMeDocument = gql`
    query CatastropheGetMe {
  me {
    id
    name
    firstName
    tenant
    tenantDisplayName
  }
}
    `;

/**
 * __useCatastropheGetMeQuery__
 *
 * To run a query within a React component, call `useCatastropheGetMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useCatastropheGetMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCatastropheGetMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useCatastropheGetMeQuery(baseOptions?: Apollo.QueryHookOptions<CatastropheGetMeQuery, CatastropheGetMeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CatastropheGetMeQuery, CatastropheGetMeQueryVariables>(CatastropheGetMeDocument, options);
      }
export function useCatastropheGetMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CatastropheGetMeQuery, CatastropheGetMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CatastropheGetMeQuery, CatastropheGetMeQueryVariables>(CatastropheGetMeDocument, options);
        }
export type CatastropheGetMeQueryHookResult = ReturnType<typeof useCatastropheGetMeQuery>;
export type CatastropheGetMeLazyQueryHookResult = ReturnType<typeof useCatastropheGetMeLazyQuery>;
export type CatastropheGetMeQueryResult = Apollo.QueryResult<CatastropheGetMeQuery, CatastropheGetMeQueryVariables>;
export function refetchCatastropheGetMeQuery(variables?: CatastropheGetMeQueryVariables) {
      return { query: CatastropheGetMeDocument, variables: variables }
    }
export const GetUserExperienceForCatastropheDocument = gql`
    query GetUserExperienceForCatastrophe($id: ID!) {
  userExperienceForCatastrophe(catastropheId: $id) {
    ...CatastrophePayload
  }
}
    ${CatastrophePayloadFragmentDoc}`;

/**
 * __useGetUserExperienceForCatastropheQuery__
 *
 * To run a query within a React component, call `useGetUserExperienceForCatastropheQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserExperienceForCatastropheQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserExperienceForCatastropheQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserExperienceForCatastropheQuery(baseOptions: Apollo.QueryHookOptions<GetUserExperienceForCatastropheQuery, GetUserExperienceForCatastropheQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserExperienceForCatastropheQuery, GetUserExperienceForCatastropheQueryVariables>(GetUserExperienceForCatastropheDocument, options);
      }
export function useGetUserExperienceForCatastropheLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserExperienceForCatastropheQuery, GetUserExperienceForCatastropheQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserExperienceForCatastropheQuery, GetUserExperienceForCatastropheQueryVariables>(GetUserExperienceForCatastropheDocument, options);
        }
export type GetUserExperienceForCatastropheQueryHookResult = ReturnType<typeof useGetUserExperienceForCatastropheQuery>;
export type GetUserExperienceForCatastropheLazyQueryHookResult = ReturnType<typeof useGetUserExperienceForCatastropheLazyQuery>;
export type GetUserExperienceForCatastropheQueryResult = Apollo.QueryResult<GetUserExperienceForCatastropheQuery, GetUserExperienceForCatastropheQueryVariables>;
export function refetchGetUserExperienceForCatastropheQuery(variables: GetUserExperienceForCatastropheQueryVariables) {
      return { query: GetUserExperienceForCatastropheDocument, variables: variables }
    }
export const ContinueUserExperienceForCatastropheDocument = gql`
    mutation ContinueUserExperienceForCatastrophe($catastropheId: ID!, $completedSteps: [String!]!) {
  continueUserExperienceForCatastrophe(
    catastropheId: $catastropheId
    completedSteps: $completedSteps
  ) {
    ...CatastrophePayload
  }
}
    ${CatastrophePayloadFragmentDoc}`;
export type ContinueUserExperienceForCatastropheMutationFn = Apollo.MutationFunction<ContinueUserExperienceForCatastropheMutation, ContinueUserExperienceForCatastropheMutationVariables>;

/**
 * __useContinueUserExperienceForCatastropheMutation__
 *
 * To run a mutation, you first call `useContinueUserExperienceForCatastropheMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContinueUserExperienceForCatastropheMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [continueUserExperienceForCatastropheMutation, { data, loading, error }] = useContinueUserExperienceForCatastropheMutation({
 *   variables: {
 *      catastropheId: // value for 'catastropheId'
 *      completedSteps: // value for 'completedSteps'
 *   },
 * });
 */
export function useContinueUserExperienceForCatastropheMutation(baseOptions?: Apollo.MutationHookOptions<ContinueUserExperienceForCatastropheMutation, ContinueUserExperienceForCatastropheMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ContinueUserExperienceForCatastropheMutation, ContinueUserExperienceForCatastropheMutationVariables>(ContinueUserExperienceForCatastropheDocument, options);
      }
export type ContinueUserExperienceForCatastropheMutationHookResult = ReturnType<typeof useContinueUserExperienceForCatastropheMutation>;
export type ContinueUserExperienceForCatastropheMutationResult = Apollo.MutationResult<ContinueUserExperienceForCatastropheMutation>;
export type ContinueUserExperienceForCatastropheMutationOptions = Apollo.BaseMutationOptions<ContinueUserExperienceForCatastropheMutation, ContinueUserExperienceForCatastropheMutationVariables>;
export const GetTenantDocument = gql`
    query GetTenant {
  me {
    id
    name
    tenant
    demo {
      prefill
    }
  }
}
    `;

/**
 * __useGetTenantQuery__
 *
 * To run a query within a React component, call `useGetTenantQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenantQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTenantQuery(baseOptions?: Apollo.QueryHookOptions<GetTenantQuery, GetTenantQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTenantQuery, GetTenantQueryVariables>(GetTenantDocument, options);
      }
export function useGetTenantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTenantQuery, GetTenantQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTenantQuery, GetTenantQueryVariables>(GetTenantDocument, options);
        }
export type GetTenantQueryHookResult = ReturnType<typeof useGetTenantQuery>;
export type GetTenantLazyQueryHookResult = ReturnType<typeof useGetTenantLazyQuery>;
export type GetTenantQueryResult = Apollo.QueryResult<GetTenantQuery, GetTenantQueryVariables>;
export function refetchGetTenantQuery(variables?: GetTenantQueryVariables) {
      return { query: GetTenantDocument, variables: variables }
    }
export const WorkflowCurrentStepDocument = gql`
    query WorkflowCurrentStep($workflow: ID!, $forceStep: String) {
  workflowCurrentStep(workflow: $workflow, forceStep: $forceStep) {
    ...ClaimWorkflowStep
  }
}
    ${ClaimWorkflowStepFragmentDoc}`;

/**
 * __useWorkflowCurrentStepQuery__
 *
 * To run a query within a React component, call `useWorkflowCurrentStepQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkflowCurrentStepQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkflowCurrentStepQuery({
 *   variables: {
 *      workflow: // value for 'workflow'
 *      forceStep: // value for 'forceStep'
 *   },
 * });
 */
export function useWorkflowCurrentStepQuery(baseOptions: Apollo.QueryHookOptions<WorkflowCurrentStepQuery, WorkflowCurrentStepQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkflowCurrentStepQuery, WorkflowCurrentStepQueryVariables>(WorkflowCurrentStepDocument, options);
      }
export function useWorkflowCurrentStepLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkflowCurrentStepQuery, WorkflowCurrentStepQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkflowCurrentStepQuery, WorkflowCurrentStepQueryVariables>(WorkflowCurrentStepDocument, options);
        }
export type WorkflowCurrentStepQueryHookResult = ReturnType<typeof useWorkflowCurrentStepQuery>;
export type WorkflowCurrentStepLazyQueryHookResult = ReturnType<typeof useWorkflowCurrentStepLazyQuery>;
export type WorkflowCurrentStepQueryResult = Apollo.QueryResult<WorkflowCurrentStepQuery, WorkflowCurrentStepQueryVariables>;
export function refetchWorkflowCurrentStepQuery(variables: WorkflowCurrentStepQueryVariables) {
      return { query: WorkflowCurrentStepDocument, variables: variables }
    }
export const ContinueWorkflowDocument = gql`
    mutation ContinueWorkflow($workflow: ID!, $assumedCurrentStepKey: String, $response: WorkflowStepResponse!) {
  continueWorkflow(
    workflow: $workflow
    assumedCurrentStepKey: $assumedCurrentStepKey
    response: $response
  ) {
    ...ClaimWorkflowStep
  }
}
    ${ClaimWorkflowStepFragmentDoc}`;
export type ContinueWorkflowMutationFn = Apollo.MutationFunction<ContinueWorkflowMutation, ContinueWorkflowMutationVariables>;

/**
 * __useContinueWorkflowMutation__
 *
 * To run a mutation, you first call `useContinueWorkflowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContinueWorkflowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [continueWorkflowMutation, { data, loading, error }] = useContinueWorkflowMutation({
 *   variables: {
 *      workflow: // value for 'workflow'
 *      assumedCurrentStepKey: // value for 'assumedCurrentStepKey'
 *      response: // value for 'response'
 *   },
 * });
 */
export function useContinueWorkflowMutation(baseOptions?: Apollo.MutationHookOptions<ContinueWorkflowMutation, ContinueWorkflowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ContinueWorkflowMutation, ContinueWorkflowMutationVariables>(ContinueWorkflowDocument, options);
      }
export type ContinueWorkflowMutationHookResult = ReturnType<typeof useContinueWorkflowMutation>;
export type ContinueWorkflowMutationResult = Apollo.MutationResult<ContinueWorkflowMutation>;
export type ContinueWorkflowMutationOptions = Apollo.BaseMutationOptions<ContinueWorkflowMutation, ContinueWorkflowMutationVariables>;
export const GoBackWorkflowDocument = gql`
    mutation GoBackWorkflow($workflow: ID!) {
  goBackWorkflow(workflow: $workflow) {
    ...ClaimWorkflowStep
  }
}
    ${ClaimWorkflowStepFragmentDoc}`;
export type GoBackWorkflowMutationFn = Apollo.MutationFunction<GoBackWorkflowMutation, GoBackWorkflowMutationVariables>;

/**
 * __useGoBackWorkflowMutation__
 *
 * To run a mutation, you first call `useGoBackWorkflowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGoBackWorkflowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [goBackWorkflowMutation, { data, loading, error }] = useGoBackWorkflowMutation({
 *   variables: {
 *      workflow: // value for 'workflow'
 *   },
 * });
 */
export function useGoBackWorkflowMutation(baseOptions?: Apollo.MutationHookOptions<GoBackWorkflowMutation, GoBackWorkflowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GoBackWorkflowMutation, GoBackWorkflowMutationVariables>(GoBackWorkflowDocument, options);
      }
export type GoBackWorkflowMutationHookResult = ReturnType<typeof useGoBackWorkflowMutation>;
export type GoBackWorkflowMutationResult = Apollo.MutationResult<GoBackWorkflowMutation>;
export type GoBackWorkflowMutationOptions = Apollo.BaseMutationOptions<GoBackWorkflowMutation, GoBackWorkflowMutationVariables>;
export const EmailUnsubscribeCommunicationPreferenceDocument = gql`
    query EmailUnsubscribeCommunicationPreference($email: String!) {
  emailSubscriptionPreference(email: $email) {
    ... on ConversationEmailPreference {
      isSubscribed
      email
      id
    }
    ... on ConversationEmailPreferenceError {
      message
    }
  }
}
    `;

/**
 * __useEmailUnsubscribeCommunicationPreferenceQuery__
 *
 * To run a query within a React component, call `useEmailUnsubscribeCommunicationPreferenceQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailUnsubscribeCommunicationPreferenceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailUnsubscribeCommunicationPreferenceQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useEmailUnsubscribeCommunicationPreferenceQuery(baseOptions: Apollo.QueryHookOptions<EmailUnsubscribeCommunicationPreferenceQuery, EmailUnsubscribeCommunicationPreferenceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmailUnsubscribeCommunicationPreferenceQuery, EmailUnsubscribeCommunicationPreferenceQueryVariables>(EmailUnsubscribeCommunicationPreferenceDocument, options);
      }
export function useEmailUnsubscribeCommunicationPreferenceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmailUnsubscribeCommunicationPreferenceQuery, EmailUnsubscribeCommunicationPreferenceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmailUnsubscribeCommunicationPreferenceQuery, EmailUnsubscribeCommunicationPreferenceQueryVariables>(EmailUnsubscribeCommunicationPreferenceDocument, options);
        }
export type EmailUnsubscribeCommunicationPreferenceQueryHookResult = ReturnType<typeof useEmailUnsubscribeCommunicationPreferenceQuery>;
export type EmailUnsubscribeCommunicationPreferenceLazyQueryHookResult = ReturnType<typeof useEmailUnsubscribeCommunicationPreferenceLazyQuery>;
export type EmailUnsubscribeCommunicationPreferenceQueryResult = Apollo.QueryResult<EmailUnsubscribeCommunicationPreferenceQuery, EmailUnsubscribeCommunicationPreferenceQueryVariables>;
export function refetchEmailUnsubscribeCommunicationPreferenceQuery(variables: EmailUnsubscribeCommunicationPreferenceQueryVariables) {
      return { query: EmailUnsubscribeCommunicationPreferenceDocument, variables: variables }
    }
export const EmailUnsubscribeUpdateCommunicationPreferenceDocument = gql`
    mutation EmailUnsubscribeUpdateCommunicationPreference($email: String!, $subscribe: Boolean!) {
  updateConversationEmailPreference(email: $email, subscribe: $subscribe) {
    ... on ConversationEmailPreference {
      isSubscribed
      email
      id
    }
    ... on ConversationEmailPreferenceError {
      message
    }
  }
}
    `;
export type EmailUnsubscribeUpdateCommunicationPreferenceMutationFn = Apollo.MutationFunction<EmailUnsubscribeUpdateCommunicationPreferenceMutation, EmailUnsubscribeUpdateCommunicationPreferenceMutationVariables>;

/**
 * __useEmailUnsubscribeUpdateCommunicationPreferenceMutation__
 *
 * To run a mutation, you first call `useEmailUnsubscribeUpdateCommunicationPreferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmailUnsubscribeUpdateCommunicationPreferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emailUnsubscribeUpdateCommunicationPreferenceMutation, { data, loading, error }] = useEmailUnsubscribeUpdateCommunicationPreferenceMutation({
 *   variables: {
 *      email: // value for 'email'
 *      subscribe: // value for 'subscribe'
 *   },
 * });
 */
export function useEmailUnsubscribeUpdateCommunicationPreferenceMutation(baseOptions?: Apollo.MutationHookOptions<EmailUnsubscribeUpdateCommunicationPreferenceMutation, EmailUnsubscribeUpdateCommunicationPreferenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EmailUnsubscribeUpdateCommunicationPreferenceMutation, EmailUnsubscribeUpdateCommunicationPreferenceMutationVariables>(EmailUnsubscribeUpdateCommunicationPreferenceDocument, options);
      }
export type EmailUnsubscribeUpdateCommunicationPreferenceMutationHookResult = ReturnType<typeof useEmailUnsubscribeUpdateCommunicationPreferenceMutation>;
export type EmailUnsubscribeUpdateCommunicationPreferenceMutationResult = Apollo.MutationResult<EmailUnsubscribeUpdateCommunicationPreferenceMutation>;
export type EmailUnsubscribeUpdateCommunicationPreferenceMutationOptions = Apollo.BaseMutationOptions<EmailUnsubscribeUpdateCommunicationPreferenceMutation, EmailUnsubscribeUpdateCommunicationPreferenceMutationVariables>;
export const HomeGetMeDocument = gql`
    query HomeGetMe {
  me {
    id
    name
    email
    phoneNumber
    firstName
    tenant
    demo {
      jumpIntoWorkflow
    }
  }
}
    `;

/**
 * __useHomeGetMeQuery__
 *
 * To run a query within a React component, call `useHomeGetMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeGetMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeGetMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useHomeGetMeQuery(baseOptions?: Apollo.QueryHookOptions<HomeGetMeQuery, HomeGetMeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HomeGetMeQuery, HomeGetMeQueryVariables>(HomeGetMeDocument, options);
      }
export function useHomeGetMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomeGetMeQuery, HomeGetMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HomeGetMeQuery, HomeGetMeQueryVariables>(HomeGetMeDocument, options);
        }
export type HomeGetMeQueryHookResult = ReturnType<typeof useHomeGetMeQuery>;
export type HomeGetMeLazyQueryHookResult = ReturnType<typeof useHomeGetMeLazyQuery>;
export type HomeGetMeQueryResult = Apollo.QueryResult<HomeGetMeQuery, HomeGetMeQueryVariables>;
export function refetchHomeGetMeQuery(variables?: HomeGetMeQueryVariables) {
      return { query: HomeGetMeDocument, variables: variables }
    }
export const StartNewClaimDocument = gql`
    mutation StartNewClaim($type: WorkflowType, $catastropheId: ID) {
  startNewClaimWorkflow(type: $type, catastropheId: $catastropheId) {
    data {
      id
    }
  }
}
    `;
export type StartNewClaimMutationFn = Apollo.MutationFunction<StartNewClaimMutation, StartNewClaimMutationVariables>;

/**
 * __useStartNewClaimMutation__
 *
 * To run a mutation, you first call `useStartNewClaimMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartNewClaimMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startNewClaimMutation, { data, loading, error }] = useStartNewClaimMutation({
 *   variables: {
 *      type: // value for 'type'
 *      catastropheId: // value for 'catastropheId'
 *   },
 * });
 */
export function useStartNewClaimMutation(baseOptions?: Apollo.MutationHookOptions<StartNewClaimMutation, StartNewClaimMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartNewClaimMutation, StartNewClaimMutationVariables>(StartNewClaimDocument, options);
      }
export type StartNewClaimMutationHookResult = ReturnType<typeof useStartNewClaimMutation>;
export type StartNewClaimMutationResult = Apollo.MutationResult<StartNewClaimMutation>;
export type StartNewClaimMutationOptions = Apollo.BaseMutationOptions<StartNewClaimMutation, StartNewClaimMutationVariables>;
export const GetCatastropheDetailsDocument = gql`
    query GetCatastropheDetails($id: ID!) {
  catastropheDetails(id: $id) {
    type
  }
}
    `;

/**
 * __useGetCatastropheDetailsQuery__
 *
 * To run a query within a React component, call `useGetCatastropheDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCatastropheDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCatastropheDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCatastropheDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetCatastropheDetailsQuery, GetCatastropheDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCatastropheDetailsQuery, GetCatastropheDetailsQueryVariables>(GetCatastropheDetailsDocument, options);
      }
export function useGetCatastropheDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCatastropheDetailsQuery, GetCatastropheDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCatastropheDetailsQuery, GetCatastropheDetailsQueryVariables>(GetCatastropheDetailsDocument, options);
        }
export type GetCatastropheDetailsQueryHookResult = ReturnType<typeof useGetCatastropheDetailsQuery>;
export type GetCatastropheDetailsLazyQueryHookResult = ReturnType<typeof useGetCatastropheDetailsLazyQuery>;
export type GetCatastropheDetailsQueryResult = Apollo.QueryResult<GetCatastropheDetailsQuery, GetCatastropheDetailsQueryVariables>;
export function refetchGetCatastropheDetailsQuery(variables: GetCatastropheDetailsQueryVariables) {
      return { query: GetCatastropheDetailsDocument, variables: variables }
    }
export const ManagePolicyGetMeDocument = gql`
    query ManagePolicyGetMe {
  me {
    id
    name
    email
    phoneNumber
    firstName
    tenant
  }
}
    `;

/**
 * __useManagePolicyGetMeQuery__
 *
 * To run a query within a React component, call `useManagePolicyGetMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useManagePolicyGetMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManagePolicyGetMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useManagePolicyGetMeQuery(baseOptions?: Apollo.QueryHookOptions<ManagePolicyGetMeQuery, ManagePolicyGetMeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ManagePolicyGetMeQuery, ManagePolicyGetMeQueryVariables>(ManagePolicyGetMeDocument, options);
      }
export function useManagePolicyGetMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ManagePolicyGetMeQuery, ManagePolicyGetMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ManagePolicyGetMeQuery, ManagePolicyGetMeQueryVariables>(ManagePolicyGetMeDocument, options);
        }
export type ManagePolicyGetMeQueryHookResult = ReturnType<typeof useManagePolicyGetMeQuery>;
export type ManagePolicyGetMeLazyQueryHookResult = ReturnType<typeof useManagePolicyGetMeLazyQuery>;
export type ManagePolicyGetMeQueryResult = Apollo.QueryResult<ManagePolicyGetMeQuery, ManagePolicyGetMeQueryVariables>;
export function refetchManagePolicyGetMeQuery(variables?: ManagePolicyGetMeQueryVariables) {
      return { query: ManagePolicyGetMeDocument, variables: variables }
    }
export const CatastropheSiteDocument = gql`
    query CatastropheSite($id: ID!) {
  me {
    id
    name
    firstName
    tenant
    tenantDisplayName
  }
  catastrophe(ids: [$id]) {
    id
    name
    geometry
    createdAt
    updatedAt
    todos {
      ...todoDetails
    }
  }
  catastropheDetails(id: $id) {
    type
    phase
    severity
  }
}
    ${TodoDetailsFragmentDoc}`;

/**
 * __useCatastropheSiteQuery__
 *
 * To run a query within a React component, call `useCatastropheSiteQuery` and pass it any options that fit your needs.
 * When your component renders, `useCatastropheSiteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCatastropheSiteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCatastropheSiteQuery(baseOptions: Apollo.QueryHookOptions<CatastropheSiteQuery, CatastropheSiteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CatastropheSiteQuery, CatastropheSiteQueryVariables>(CatastropheSiteDocument, options);
      }
export function useCatastropheSiteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CatastropheSiteQuery, CatastropheSiteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CatastropheSiteQuery, CatastropheSiteQueryVariables>(CatastropheSiteDocument, options);
        }
export type CatastropheSiteQueryHookResult = ReturnType<typeof useCatastropheSiteQuery>;
export type CatastropheSiteLazyQueryHookResult = ReturnType<typeof useCatastropheSiteLazyQuery>;
export type CatastropheSiteQueryResult = Apollo.QueryResult<CatastropheSiteQuery, CatastropheSiteQueryVariables>;
export function refetchCatastropheSiteQuery(variables: CatastropheSiteQueryVariables) {
      return { query: CatastropheSiteDocument, variables: variables }
    }
export const StartCatSiteVisitDocument = gql`
    mutation StartCATSiteVisit($catastropheId: ID!, $payload: WorkflowTelemetryPayload!) {
  saveCATTelemetry(catastrophe: $catastropheId, payload: $payload)
}
    `;
export type StartCatSiteVisitMutationFn = Apollo.MutationFunction<StartCatSiteVisitMutation, StartCatSiteVisitMutationVariables>;

/**
 * __useStartCatSiteVisitMutation__
 *
 * To run a mutation, you first call `useStartCatSiteVisitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartCatSiteVisitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startCatSiteVisitMutation, { data, loading, error }] = useStartCatSiteVisitMutation({
 *   variables: {
 *      catastropheId: // value for 'catastropheId'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useStartCatSiteVisitMutation(baseOptions?: Apollo.MutationHookOptions<StartCatSiteVisitMutation, StartCatSiteVisitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartCatSiteVisitMutation, StartCatSiteVisitMutationVariables>(StartCatSiteVisitDocument, options);
      }
export type StartCatSiteVisitMutationHookResult = ReturnType<typeof useStartCatSiteVisitMutation>;
export type StartCatSiteVisitMutationResult = Apollo.MutationResult<StartCatSiteVisitMutation>;
export type StartCatSiteVisitMutationOptions = Apollo.BaseMutationOptions<StartCatSiteVisitMutation, StartCatSiteVisitMutationVariables>;
export const CatastropheSiteTodoCompleteDocument = gql`
    query CatastropheSiteTodoComplete($catastropheId: ID!) {
  catastrophe(ids: [$catastropheId]) {
    id
    todos {
      ...todoDetails
    }
  }
}
    ${TodoDetailsFragmentDoc}`;

/**
 * __useCatastropheSiteTodoCompleteQuery__
 *
 * To run a query within a React component, call `useCatastropheSiteTodoCompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useCatastropheSiteTodoCompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCatastropheSiteTodoCompleteQuery({
 *   variables: {
 *      catastropheId: // value for 'catastropheId'
 *   },
 * });
 */
export function useCatastropheSiteTodoCompleteQuery(baseOptions: Apollo.QueryHookOptions<CatastropheSiteTodoCompleteQuery, CatastropheSiteTodoCompleteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CatastropheSiteTodoCompleteQuery, CatastropheSiteTodoCompleteQueryVariables>(CatastropheSiteTodoCompleteDocument, options);
      }
export function useCatastropheSiteTodoCompleteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CatastropheSiteTodoCompleteQuery, CatastropheSiteTodoCompleteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CatastropheSiteTodoCompleteQuery, CatastropheSiteTodoCompleteQueryVariables>(CatastropheSiteTodoCompleteDocument, options);
        }
export type CatastropheSiteTodoCompleteQueryHookResult = ReturnType<typeof useCatastropheSiteTodoCompleteQuery>;
export type CatastropheSiteTodoCompleteLazyQueryHookResult = ReturnType<typeof useCatastropheSiteTodoCompleteLazyQuery>;
export type CatastropheSiteTodoCompleteQueryResult = Apollo.QueryResult<CatastropheSiteTodoCompleteQuery, CatastropheSiteTodoCompleteQueryVariables>;
export function refetchCatastropheSiteTodoCompleteQuery(variables: CatastropheSiteTodoCompleteQueryVariables) {
      return { query: CatastropheSiteTodoCompleteDocument, variables: variables }
    }
export const AcceptTermsStartCatastropheTodosDocument = gql`
    mutation AcceptTermsStartCatastropheTodos($catastropheId: ID!) {
  startCatastropheTodos(catastropheId: $catastropheId) {
    todos {
      ...todoDetails
    }
  }
}
    ${TodoDetailsFragmentDoc}`;
export type AcceptTermsStartCatastropheTodosMutationFn = Apollo.MutationFunction<AcceptTermsStartCatastropheTodosMutation, AcceptTermsStartCatastropheTodosMutationVariables>;

/**
 * __useAcceptTermsStartCatastropheTodosMutation__
 *
 * To run a mutation, you first call `useAcceptTermsStartCatastropheTodosMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptTermsStartCatastropheTodosMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptTermsStartCatastropheTodosMutation, { data, loading, error }] = useAcceptTermsStartCatastropheTodosMutation({
 *   variables: {
 *      catastropheId: // value for 'catastropheId'
 *   },
 * });
 */
export function useAcceptTermsStartCatastropheTodosMutation(baseOptions?: Apollo.MutationHookOptions<AcceptTermsStartCatastropheTodosMutation, AcceptTermsStartCatastropheTodosMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptTermsStartCatastropheTodosMutation, AcceptTermsStartCatastropheTodosMutationVariables>(AcceptTermsStartCatastropheTodosDocument, options);
      }
export type AcceptTermsStartCatastropheTodosMutationHookResult = ReturnType<typeof useAcceptTermsStartCatastropheTodosMutation>;
export type AcceptTermsStartCatastropheTodosMutationResult = Apollo.MutationResult<AcceptTermsStartCatastropheTodosMutation>;
export type AcceptTermsStartCatastropheTodosMutationOptions = Apollo.BaseMutationOptions<AcceptTermsStartCatastropheTodosMutation, AcceptTermsStartCatastropheTodosMutationVariables>;
export const ChangeCatastropheTodoDocument = gql`
    mutation ChangeCatastropheTodo($catastropheId: ID!, $completedSteps: [String!]!) {
  changeCatastropheTodo(
    catastropheId: $catastropheId
    completedSteps: $completedSteps
  ) {
    id
    todos {
      ...todoDetails
    }
  }
}
    ${TodoDetailsFragmentDoc}`;
export type ChangeCatastropheTodoMutationFn = Apollo.MutationFunction<ChangeCatastropheTodoMutation, ChangeCatastropheTodoMutationVariables>;

/**
 * __useChangeCatastropheTodoMutation__
 *
 * To run a mutation, you first call `useChangeCatastropheTodoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeCatastropheTodoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeCatastropheTodoMutation, { data, loading, error }] = useChangeCatastropheTodoMutation({
 *   variables: {
 *      catastropheId: // value for 'catastropheId'
 *      completedSteps: // value for 'completedSteps'
 *   },
 * });
 */
export function useChangeCatastropheTodoMutation(baseOptions?: Apollo.MutationHookOptions<ChangeCatastropheTodoMutation, ChangeCatastropheTodoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeCatastropheTodoMutation, ChangeCatastropheTodoMutationVariables>(ChangeCatastropheTodoDocument, options);
      }
export type ChangeCatastropheTodoMutationHookResult = ReturnType<typeof useChangeCatastropheTodoMutation>;
export type ChangeCatastropheTodoMutationResult = Apollo.MutationResult<ChangeCatastropheTodoMutation>;
export type ChangeCatastropheTodoMutationOptions = Apollo.BaseMutationOptions<ChangeCatastropheTodoMutation, ChangeCatastropheTodoMutationVariables>;
export const UpdateOAuthSessionDocument = gql`
    mutation UpdateOAuthSession($sessionId: String!, $providerName: String!, $code: String, $encryptChallenge: Boolean!) {
  updateOAuthSession(
    sessionId: $sessionId
    providerName: $providerName
    code: $code
    encryptChallenge: $encryptChallenge
  ) {
    sessionId
    providerName
    challenge
    customerId
  }
}
    `;
export type UpdateOAuthSessionMutationFn = Apollo.MutationFunction<UpdateOAuthSessionMutation, UpdateOAuthSessionMutationVariables>;

/**
 * __useUpdateOAuthSessionMutation__
 *
 * To run a mutation, you first call `useUpdateOAuthSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOAuthSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOAuthSessionMutation, { data, loading, error }] = useUpdateOAuthSessionMutation({
 *   variables: {
 *      sessionId: // value for 'sessionId'
 *      providerName: // value for 'providerName'
 *      code: // value for 'code'
 *      encryptChallenge: // value for 'encryptChallenge'
 *   },
 * });
 */
export function useUpdateOAuthSessionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOAuthSessionMutation, UpdateOAuthSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOAuthSessionMutation, UpdateOAuthSessionMutationVariables>(UpdateOAuthSessionDocument, options);
      }
export type UpdateOAuthSessionMutationHookResult = ReturnType<typeof useUpdateOAuthSessionMutation>;
export type UpdateOAuthSessionMutationResult = Apollo.MutationResult<UpdateOAuthSessionMutation>;
export type UpdateOAuthSessionMutationOptions = Apollo.BaseMutationOptions<UpdateOAuthSessionMutation, UpdateOAuthSessionMutationVariables>;
export const AuthCodeOauthSessionDocument = gql`
    mutation AuthCodeOauthSession($code: String!, $policyNumber: String!) {
  authCodeOauthSession(code: $code, policyNumber: $policyNumber) {
    sessionId
    providerName
    customerId
  }
}
    `;
export type AuthCodeOauthSessionMutationFn = Apollo.MutationFunction<AuthCodeOauthSessionMutation, AuthCodeOauthSessionMutationVariables>;

/**
 * __useAuthCodeOauthSessionMutation__
 *
 * To run a mutation, you first call `useAuthCodeOauthSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthCodeOauthSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authCodeOauthSessionMutation, { data, loading, error }] = useAuthCodeOauthSessionMutation({
 *   variables: {
 *      code: // value for 'code'
 *      policyNumber: // value for 'policyNumber'
 *   },
 * });
 */
export function useAuthCodeOauthSessionMutation(baseOptions?: Apollo.MutationHookOptions<AuthCodeOauthSessionMutation, AuthCodeOauthSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AuthCodeOauthSessionMutation, AuthCodeOauthSessionMutationVariables>(AuthCodeOauthSessionDocument, options);
      }
export type AuthCodeOauthSessionMutationHookResult = ReturnType<typeof useAuthCodeOauthSessionMutation>;
export type AuthCodeOauthSessionMutationResult = Apollo.MutationResult<AuthCodeOauthSessionMutation>;
export type AuthCodeOauthSessionMutationOptions = Apollo.BaseMutationOptions<AuthCodeOauthSessionMutation, AuthCodeOauthSessionMutationVariables>;
export const GuidedPhotoSessionPhoneNumberDocument = gql`
    query GuidedPhotoSessionPhoneNumber($claimId: ID!) {
  guidedPhotoSessionPhoneNumber(claimId: $claimId)
}
    `;

/**
 * __useGuidedPhotoSessionPhoneNumberQuery__
 *
 * To run a query within a React component, call `useGuidedPhotoSessionPhoneNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGuidedPhotoSessionPhoneNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGuidedPhotoSessionPhoneNumberQuery({
 *   variables: {
 *      claimId: // value for 'claimId'
 *   },
 * });
 */
export function useGuidedPhotoSessionPhoneNumberQuery(baseOptions: Apollo.QueryHookOptions<GuidedPhotoSessionPhoneNumberQuery, GuidedPhotoSessionPhoneNumberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GuidedPhotoSessionPhoneNumberQuery, GuidedPhotoSessionPhoneNumberQueryVariables>(GuidedPhotoSessionPhoneNumberDocument, options);
      }
export function useGuidedPhotoSessionPhoneNumberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GuidedPhotoSessionPhoneNumberQuery, GuidedPhotoSessionPhoneNumberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GuidedPhotoSessionPhoneNumberQuery, GuidedPhotoSessionPhoneNumberQueryVariables>(GuidedPhotoSessionPhoneNumberDocument, options);
        }
export type GuidedPhotoSessionPhoneNumberQueryHookResult = ReturnType<typeof useGuidedPhotoSessionPhoneNumberQuery>;
export type GuidedPhotoSessionPhoneNumberLazyQueryHookResult = ReturnType<typeof useGuidedPhotoSessionPhoneNumberLazyQuery>;
export type GuidedPhotoSessionPhoneNumberQueryResult = Apollo.QueryResult<GuidedPhotoSessionPhoneNumberQuery, GuidedPhotoSessionPhoneNumberQueryVariables>;
export function refetchGuidedPhotoSessionPhoneNumberQuery(variables: GuidedPhotoSessionPhoneNumberQueryVariables) {
      return { query: GuidedPhotoSessionPhoneNumberDocument, variables: variables }
    }
export const PdsOutputCopyBadgeDocument = gql`
    query PDSOutputCopyBadge($id: ID!) {
  claim(id: $id) {
    pdsOutput
  }
  isaDispatchTasks: emmaTasksAssignedForClaim(claimId: $id, types: [ISA_DISPATCH]) {
    tasks {
      status
      parameters
    }
  }
}
    `;

/**
 * __usePdsOutputCopyBadgeQuery__
 *
 * To run a query within a React component, call `usePdsOutputCopyBadgeQuery` and pass it any options that fit your needs.
 * When your component renders, `usePdsOutputCopyBadgeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePdsOutputCopyBadgeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePdsOutputCopyBadgeQuery(baseOptions: Apollo.QueryHookOptions<PdsOutputCopyBadgeQuery, PdsOutputCopyBadgeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PdsOutputCopyBadgeQuery, PdsOutputCopyBadgeQueryVariables>(PdsOutputCopyBadgeDocument, options);
      }
export function usePdsOutputCopyBadgeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PdsOutputCopyBadgeQuery, PdsOutputCopyBadgeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PdsOutputCopyBadgeQuery, PdsOutputCopyBadgeQueryVariables>(PdsOutputCopyBadgeDocument, options);
        }
export type PdsOutputCopyBadgeQueryHookResult = ReturnType<typeof usePdsOutputCopyBadgeQuery>;
export type PdsOutputCopyBadgeLazyQueryHookResult = ReturnType<typeof usePdsOutputCopyBadgeLazyQuery>;
export type PdsOutputCopyBadgeQueryResult = Apollo.QueryResult<PdsOutputCopyBadgeQuery, PdsOutputCopyBadgeQueryVariables>;
export function refetchPdsOutputCopyBadgeQuery(variables: PdsOutputCopyBadgeQueryVariables) {
      return { query: PdsOutputCopyBadgeDocument, variables: variables }
    }
export const ShopDocument = gql`
    query Shop($id: ID!) {
  shop(id: $id) {
    id
    carrierId
    businessName
    phone
    carrierRepName
  }
}
    `;

/**
 * __useShopQuery__
 *
 * To run a query within a React component, call `useShopQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useShopQuery(baseOptions: Apollo.QueryHookOptions<ShopQuery, ShopQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShopQuery, ShopQueryVariables>(ShopDocument, options);
      }
export function useShopLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShopQuery, ShopQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShopQuery, ShopQueryVariables>(ShopDocument, options);
        }
export type ShopQueryHookResult = ReturnType<typeof useShopQuery>;
export type ShopLazyQueryHookResult = ReturnType<typeof useShopLazyQuery>;
export type ShopQueryResult = Apollo.QueryResult<ShopQuery, ShopQueryVariables>;
export function refetchShopQuery(variables: ShopQueryVariables) {
      return { query: ShopDocument, variables: variables }
    }
export const namedOperations = {
  Query: {
    Me: 'Me',
    GeocodeCity: 'GeocodeCity',
    GeocodeIntersection: 'GeocodeIntersection',
    ShopsNearLocation: 'ShopsNearLocation',
    SearchVehicleModels: 'SearchVehicleModels',
    SearchStateCities: 'SearchStateCities',
    SearchStateHighways: 'SearchStateHighways',
    GetTenantConfig: 'GetTenantConfig',
    LookupDemo: 'LookupDemo',
    LookupAuthenticatedDeepLink: 'LookupAuthenticatedDeepLink',
    GetCatastropheAffectedPolicyLocations: 'GetCatastropheAffectedPolicyLocations',
    LookupCatastropheIncidentClaimInvitation: 'LookupCatastropheIncidentClaimInvitation',
    RetrieveIvrDispatch: 'RetrieveIvrDispatch',
    CardsGetMe: 'CardsGetMe',
    CatastropheGetMe: 'CatastropheGetMe',
    GetUserExperienceForCatastrophe: 'GetUserExperienceForCatastrophe',
    GetTenant: 'GetTenant',
    WorkflowCurrentStep: 'WorkflowCurrentStep',
    EmailUnsubscribeCommunicationPreference: 'EmailUnsubscribeCommunicationPreference',
    HomeGetMe: 'HomeGetMe',
    GetCatastropheDetails: 'GetCatastropheDetails',
    ManagePolicyGetMe: 'ManagePolicyGetMe',
    CatastropheSite: 'CatastropheSite',
    CatastropheSiteTodoComplete: 'CatastropheSiteTodoComplete',
    GuidedPhotoSessionPhoneNumber: 'GuidedPhotoSessionPhoneNumber',
    PDSOutputCopyBadge: 'PDSOutputCopyBadge',
    Shop: 'Shop'
  },
  Mutation: {
    AuthenticateUser: 'AuthenticateUser',
    Begin2FacAuth: 'Begin2FacAuth',
    findWorkflowDraft: 'findWorkflowDraft',
    ResumeWorkflowDraft: 'ResumeWorkflowDraft',
    StartNewClaimWorkflow: 'StartNewClaimWorkflow',
    UploadDocument: 'UploadDocument',
    SMSGuidedPhotoCaptureLink: 'SMSGuidedPhotoCaptureLink',
    DeclineGuidedPhotoCaptureSession: 'DeclineGuidedPhotoCaptureSession',
    UpdateClaimFollowupStatuses: 'UpdateClaimFollowupStatuses',
    SaveWorkflowTelemetry: 'SaveWorkflowTelemetry',
    ActivateDemo: 'ActivateDemo',
    StartNewLinkInvestigationResponse: 'StartNewLinkInvestigationResponse',
    ContinueUserExperienceForCatastrophe: 'ContinueUserExperienceForCatastrophe',
    ContinueWorkflow: 'ContinueWorkflow',
    GoBackWorkflow: 'GoBackWorkflow',
    EmailUnsubscribeUpdateCommunicationPreference: 'EmailUnsubscribeUpdateCommunicationPreference',
    StartNewClaim: 'StartNewClaim',
    StartCATSiteVisit: 'StartCATSiteVisit',
    AcceptTermsStartCatastropheTodos: 'AcceptTermsStartCatastropheTodos',
    ChangeCatastropheTodo: 'ChangeCatastropheTodo',
    UpdateOAuthSession: 'UpdateOAuthSession',
    AuthCodeOauthSession: 'AuthCodeOauthSession'
  },
  Fragment: {
    CatastrophePayload: 'CatastrophePayload',
    ClaimWorkflowStep: 'ClaimWorkflowStep',
    todoDetails: 'todoDetails'
  }
}